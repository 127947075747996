//@ts-nocheck
import { twJoin } from "tailwind-merge";
import FindingsSource from "./FindingsSource";
import { Tooltip, styled } from "@mui/material";

const ParchaTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`    
    background-color: transparent;    
    padding: 0px;        
`);

export const SourceTagCircle = ({ source, isActive, setActiveSource, handleSourceClick }: any) => {
  if (!source) return null;

  return (
    <ParchaTooltip
      className="border border-slate-200 rounded-xl text-slate-900"
      title={
        <div className="p-0 w-full text-slate-900">
          <FindingsSource source={source} showIndicator={false} showURL={false} />
        </div>
      }
    >
      <div
        role="button"
        onClick={() => handleSourceClick(source.id)}
        className={twJoin(
          "shrink-0 flex items-center justify-center rounded-full w-4 h-4 text-[0.6rem] text-nowrap font-normal cursor-pointer",
          isActive ? "bg-slate-100 text-brand-purple" : "bg-slate-200 text-slate-500",
        )}
        onMouseEnter={() => setActiveSource(source.source_id)}
        onMouseLeave={() => setActiveSource(null)}
      >
        {source?.id}
      </div>
    </ParchaTooltip>
  );
};

export default SourceTagCircle;
