// @ts-nocheck
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { Endpoint, NewJobMetadata } from "@/types";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import ParchaReport from "@/components/TailwindComponents/PublicBDDReport";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import FeedbackContext from "@/contexts/FeedbackContext";
import FullWidthTabs from "@/components/TailwindComponents/FullWidthTabs";
import FinalOutput from "@/components/TailwindComponents/FinalOutput";
import { AutoAwesome, Close, CopyAllRounded, HourglassTopRounded, Info, MoreHoriz } from "@mui/icons-material";
import { formatDistanceToNowStrict } from "date-fns";
import ReviewBadge from "./TailwindComponents/ReviewBadge";
import ApproveBadge from "./TailwindComponents/ApproveBadge";
import DenyBadge from "./TailwindComponents/DenyBadge";
import StatusIndicator from "./TailwindComponents/StatusIndicator";
import ReactMarkdown from "react-markdown";
import { Drawer, Fab } from "@mui/material";
import NewAgentChatSSE from "./TailwindComponents/NewAgentChatSSE";
import {
  BUSINESS_PROFILE_CHECK_ID,
  WEB_PRESENCE_CHECK_ID,
  MCC_CHECK_ID,
  ADDRESS_CHECK_ID,
  POLICY_CHECK_ID,
  HIGH_RISK_COUNTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  ADVERSE_MEDIA_CHECK_ID,
  SANCTIONS_WATCHLIST_CHECK_ID,
  BUSINESS_OWNERS_CHECK_ID,
} from "@/constants/vars";

const recommendationComponents = {
  Review: <ReviewBadge />,
  Approve: <ApproveBadge />,
  Deny: <DenyBadge />,
};

const NewJobView = () => {
  const [jobMetadata, setJobMetadata] = useState<NewJobMetadata | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingFeedbackData, setIsLoadingFeedbackData] = useState(true);

  const [isGenerateFollowUpOpen, setIsGenerateFollowUpOpen] = useState<boolean>(false);
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState<boolean>(false);
  const [isDeletingJob, setIsDeletingJob] = useState(false);
  const [isJobDetailsOpen, setIsJobDetailsOpen] = useState<boolean>(false);
  const [latestFeedbackData, setLatestFeedbackData] = useState(null);
  const [jobFeedbackData, setJobFeedbackData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState("findings");
  const [infoTabValue, setInfoTabValue] = useState("inputs");
  const dialogDivRef = useRef();
  const deleteJobDivRef = useRef();

  const { agentKey, jobId } = useParams();
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const agent = userContext?.agent;
  const endpoints = userContext?.endpoints;
  const endpoint = endpoints?.find((endpoint: Endpoint) => endpoint.agentKey === agentKey);
  const envTier = import.meta.env.VITE_ENV_TIER;
  const endpointUrl = endpoint?.endpointUrl;
  const job = jobMetadata?.job;
  const isFlutterwaveMCC = job?.agent_id === "kyb-mcc-flutterwave";
  const user = userContext?.user;
  const isParchaUser = user?.userTenants?.some((tenant) => tenant.tenantName === "Parcha");
  const findingsEligibleChecks = [
    BUSINESS_PROFILE_CHECK_ID,
    WEB_PRESENCE_CHECK_ID,
    MCC_CHECK_ID,
    ADDRESS_CHECK_ID,
    POLICY_CHECK_ID,
    HIGH_RISK_COUNTRY_CHECK_ID,
    HIGH_RISK_INDUSTRY_CHECK_ID,
    HIGH_RISK_INDUSTRY_V2_CHECK_ID,
    ADVERSE_MEDIA_CHECK_ID,
    SANCTIONS_WATCHLIST_CHECK_ID,
    BUSINESS_OWNERS_CHECK_ID,
  ];
  const kybAgentInstanceId = jobMetadata && "kyb" in jobMetadata ? Object.keys(jobMetadata["kyb"])[0] : null;
  const kybAgentInstance = kybAgentInstanceId && jobMetadata ? jobMetadata["kyb"][kybAgentInstanceId] : null;

  const kycAgentInstanceIds = jobMetadata && "kyc" in jobMetadata ? Object.keys(jobMetadata["kyc"]) : null;
  const entityAgentInstanceIds = jobMetadata && "entity" in jobMetadata ? Object.keys(jobMetadata["entity"]) : null;

  const finalAnswerCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === "finish_plan",
  );
  const finalAnswer = finalAnswerCheckResult?.status_messages.find((message) => message.event === "final_answer");

  const shouldRenderFindingsTab = findingsEligibleChecks.some(
    (checkId) =>
      kybAgentInstance?.check_results.some((checkResult: { command_id: string }) => checkResult.command_id === checkId),
  );

  const fetchFeedbackData = async () => {
    if (!job || !endpoint) return;

    return parchaApi.getFeedbackInputsForJob(endpointUrl, job?.id).then((res) => {
      setJobFeedbackData(res);
      return res;
    });
  };

  useEffect(() => {
    if (!agent) return;

    if (agent.agent_type === "kyc") {
      setSelectedTab("individuals");
    } else if (agent.agent_type === "kyb" && shouldRenderFindingsTab) {
      setSelectedTab("findings");
    } else if (agent.agent_type === "kyb" && !shouldRenderFindingsTab) {
      setSelectedTab("business");
    }
  }, [agent, shouldRenderFindingsTab]);

  // Define tabs
  const tabs = [
    {
      name: "Findings",
      id: "findings",
      count: 0,
    },
    {
      name: "Business",
      id: "business",
      count: 0,
    },
    {
      name: "Individuals",
      id: "individuals",
      count: 0,
    },
    {
      name: "Associated Entities",
      id: "associated_entities",
      count: 0,
    },
  ];

  const sendFeedbackHandler = (commandResult, isThumbsUp, comment) => {
    parchaApi
      .sendFeedback(
        endpointUrl,
        job.id || job.job_id,
        `${commandResult.content.agent_instance_id}${commandResult.content.command_instance_id}`,
        isThumbsUp,
        undefined,
        {
          endpoint: endpointUrl,
          job_id: job.id,
          feedback_key: "command_result",
          agent_instance_id: commandResult.content.agent_instance_id,
          command_id: commandResult.content.command_id,
          command_instance_id: commandResult.content.command_instance_id,
          agent_key: agentKey,
        },
        comment,
      )

      .then(async () => {
        await fetchFeedbackData();
      });
  };

  const hasHighRiskMCCAlert = (finalAnswerCheckResult: any): boolean => {
    // Step 1: Find the status_message with event equals to "final_answer"
    const finalAnswerMessage = finalAnswerCheckResult?.status_messages.find(
      (message) => message.event === "final_answer",
    );

    if (
      !finalAnswerMessage ||
      !finalAnswerMessage.content ||
      !finalAnswerMessage.content.output ||
      !finalAnswerMessage.content.output.evidence
    ) {
      return false; // If not found or missing expected properties
    }

    // Step 2: Get the content.output.evidence array
    const evidenceArray = finalAnswerMessage.content.output.evidence;
    if (!evidenceArray || evidenceArray.length === 0) {
      return false; // No evidence to check
    }

    // Step 3: Check if any evidence.command_result.output.alerts contains the high_risk_mcc_alert key
    for (const evidence of evidenceArray) {
      const alerts = evidence.command_result.output?.alerts;
      if (alerts && "high_risk_mcc_alert" in alerts) {
        return true; // Found high_risk_mcc_alert
      }
    }

    return false; // No high_risk_mcc_alert found
  };

  const getFinalOutputTitle = () => {
    if (!job) return "";

    if (!isFlutterwaveMCC) return job.recommendation;

    const isHighRisk = isFlutterwaveMCC ? hasHighRiskMCCAlert(finalAnswerCheckResult) : false;

    if (isHighRisk) {
      return "High Risk";
    } else if (job.recommendation === "unknown") {
      return "Unknown";
    }
    return job.recommendation;
  };

  const getFinalOutputLevel = () => {
    if (!job?.recommendation) return null;

    const isHighRisk = hasHighRiskMCCAlert(finalAnswerCheckResult);

    if (!isFlutterwaveMCC) {
      if (job?.recommendation === "Approve") return "success";
      if (job?.recommendation === "Deny") return "failure";
      if (job?.recommendation === "Review") return "caution";
      return "normal";
    }

    const inputMccCode = job.input_payload?.self_attested_data.mcc_code;
    const recommendationMccCode = job.recommendation;

    if (isHighRisk || recommendationMccCode === "unknown") {
      return "failure";
    } else if (recommendationMccCode === "unknown") {
      return "failure";
    } else if (inputMccCode && recommendationMccCode && inputMccCode === recommendationMccCode) {
      return "success";
    } else if (
      (inputMccCode && recommendationMccCode && inputMccCode !== recommendationMccCode) ||
      recommendationMccCode === "Review"
    ) {
      return "caution";
    }
    return "normal";
  };

  useEffect(() => {
    if (!jobId || !endpoint) return;

    setIsLoading(true);

    const envTier = import.meta.env.VITE_ENV_TIER;

    parchaApi.getLatestFinalOutputFeedback(endpointUrl, jobId).then((res) => {
      setLatestFeedbackData(res);
      setIsLoadingFeedbackData(false);
    });

    parchaApi.getJobMetadata(endpointUrl, jobId).then((res: any) => {
      setJobMetadata(res);
      setIsLoading(false);
    });

    const interval = setInterval(() => {
      parchaApi.getJobMetadata(endpointUrl, jobId).then((res: any) => {
        setJobMetadata(res);
        const isJobDone = ["complete", "error", "failed"].includes(res.job.status);

        if (isJobDone) {
          clearInterval(interval);
        }
      });
    }, 2500);

    return () => clearInterval(interval);
  }, [jobId, agentKey, endpoints]);

  useEffect(() => {
    if (!jobMetadata?.job.id) return;

    fetchFeedbackData();
  }, [jobMetadata?.job?.id]);

  const renderBusinessTab = () => {
    if (!jobMetadata) return null;

    const kybAgentInstance = jobMetadata?.kyb;
    if (!kybAgentInstance) return null;

    const checkResults = Object.values(kybAgentInstance)
      .map((agentInstance: any) => agentInstance.check_results)
      .flat()
      .filter((cr) => !["run_check_finish_plan", "finish_plan"].includes(cr.command_id));

    return (
      <div className="flex flex-col gap-y-4 p-5">
        {checkResults.map((checkResult) => {
          return (
            <CheckContainer
              key={checkResult.command_id}
              agentRun={job}
              expanded={!checkResult.passed}
              commandResult={checkResult}
              title={checkResult.command_name}
              auditLogMessages={checkResult.status_messages.filter((message: any) =>
                ["api_results", "command_results", "thought"].includes(message.event),
              )}
              messages={checkResult.status_messages}
              sendFeedbackHandler={sendFeedbackHandler}
              feedbackData={jobFeedbackData}
            />
          );
        })}
      </div>
    );
  };

  const renderIndividualsTab = () => {
    if (!jobMetadata) return null;

    const kycAgentInstance = jobMetadata?.kyc;

    if (!kycAgentInstance) return null;

    const agentInstances = Object.entries(kycAgentInstance);

    return (
      <div className="flex flex-col gap-y-6 p-5">
        {agentInstances.map(([agentInstanceId, agentInstance]) => {
          const sectionTitle =
            agentInstance.check_results.length > 0
              ? `${agentInstance.check_results[0].first_name}${
                  agentInstance.check_results[0].middle_name ? ` ${agentInstance.check_results[0].middle_name} ` : " "
                }${agentInstance.check_results[0].last_name}`
              : "";

          return (
            <div key={agentInstanceId} className="flex flex-col gap-y-4">
              {agent.agent_type !== "kyc" && <span className="font-semibold text-base">{sectionTitle}</span>}
              {agentInstance.check_results
                .filter((cr) => !["run_check_finish_plan", "finish_plan"].includes(cr.command_id))
                .map((checkResult) => {
                  return (
                    <CheckContainer
                      key={checkResult.command_id}
                      agentRun={job}
                      expanded={!checkResult.passed}
                      commandResult={checkResult}
                      title={checkResult.command_name}
                      auditLogMessages={checkResult.status_messages.filter((message) =>
                        ["api_results", "command_results", "thought"].includes(message.event),
                      )}
                      messages={checkResult.status_messages}
                      sendFeedbackHandler={sendFeedbackHandler}
                      feedbackData={jobFeedbackData}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderAssociatedEntitiesTab = () => {
    if (!jobMetadata) return null;

    const entityAgentInstance = jobMetadata?.entity;
    if (!entityAgentInstance) return null;

    const agentInstances = Object.entries(entityAgentInstance);

    return (
      <div className="flex flex-col gap-y-4 p-5">
        {agentInstances.map(([agentInstanceId, agentInstance]) => {
          const sectionTitle =
            agentInstance.check_results.length > 0 ? agentInstance.check_results[0].entity_business_name : "";
          return (
            <div key={agentInstanceId} className="flex flex-col gap-y-4">
              <span className="font-semibold text-base">{sectionTitle}</span>
              {agentInstance.check_results
                .filter((cr) => !["run_check_finish_plan", "finish_plan"].includes(cr.command_id))
                .map((checkResult) => {
                  return (
                    <CheckContainer
                      key={checkResult.command_id}
                      agentRun={job}
                      expanded={!checkResult.passed}
                      commandResult={checkResult}
                      title={checkResult.command_name}
                      auditLogMessages={checkResult.status_messages.filter((message) =>
                        ["api_results", "command_results", "thought"].includes(message.event),
                      )}
                      messages={checkResult.status_messages}
                      sendFeedbackHandler={sendFeedbackHandler}
                      feedbackData={jobFeedbackData}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const handleCopyToClipboard = () => {
    if (finalAnswer) {
      navigator.clipboard.writeText(finalAnswer?.content?.output?.follow_up);
    }
  };

  const handleRetryJobClick = () => {
    const input = {
      agent_key: endpoint?.agentKey,
      kyb_schema: agent?.agent_type === "kyb" ? job?.input_payload : undefined,
      kyc_schema: agent?.agent_type === "kyc" ? job?.input_payload : undefined,
      run_in_parallel: true,
    };

    const envTier = import.meta.env.VITE_ENV_TIER;

    parchaApi
      .enqueueAgent(endpoint?.endpointUrl, input, agent.agent_type)
      .then((res) => {
        window.location.href = `/jobs/${endpoint?.agentKey}/${res.job_id}`;
      })
      .catch(handleApiException);
  };

  const handleDeleteJobActionClick = () => {
    setIsDeleteJobDialogOpen(true);
  };

  const deleteJob = async () => {
    if (!job) return;
    setIsDeletingJob(true);
    await parchaApi.deleteJob(endpointUrl, job.id);
    setIsDeletingJob(false);
    window.location.href = `/jobs/${agentKey}`;
  };

  return isLoading ? (
    <ParchaLoadingScreen message="Loading your report..." size="large" />
  ) : jobMetadata && job ? (
    job.status === "queued" ? (
      <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
        <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
        <div className="text-center">
          <p className="font-bold">This job is queued up</p>
          <p>It will start executing once the current in progress jobs are completed.</p>
        </div>
      </div>
    ) : (
      <div className="w-full h-0 min-h-full overflow-auto">
        <div className="flex items-center justify-between bg-slate-50 p-5">
          <div className="flex flex-col grow gap-y-2 ">
            <div className="flex items-center gap-x-5">
              <div className="font-semibold">
                {job.input_payload?.self_attested_data?.name ||
                  job.input_payload?.self_attested_data?.business_name ||
                  job.input_payload?.self_attested_data?.registered_business_name ||
                  job.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "") ||
                  `${job.input_payload?.self_attested_data?.first_name}${
                    job.input_payload?.self_attested_data?.middle_name
                      ? ` ${job.input_payload?.self_attested_data?.middle_name} `
                      : " "
                  }${job.input_payload?.self_attested_data?.last_name}`}
              </div>
              {job.recommendation && recommendationComponents[job.recommendation]}
            </div>
            <div className="flex items-center gap-1 md:gap-2 text-xs md:text-sm">
              <div>
                <StatusIndicator status={job.status} />
              </div>
              <div className="capitalize">{job.status}</div>
              {job.status && job.started_at && <div className="hidden md:block">•</div>}
              <div className="hidden md:block">
                {job.started_at && formatDistanceToNowStrict(new Date(`${job.started_at}Z`), { addSuffix: true })}
              </div>
            </div>
          </div>

          <div className="flex gap-1 md:pr-5">
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs md:text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => setIsGenerateFollowUpOpen(true)}
              disabled={!finalAnswer?.content?.output?.follow_up}
            >
              <AutoAwesome className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Generate Follow-up
            </button>
            <Menu as="div" className="text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1 md:px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <MoreHoriz className="h-3 w-3 md:h-5 md:w-5 text-slate-900" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-3 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex flex-col  divide-y divide-solid">
                    <Menu.Item>
                      <div className="px-4 py-2 text-sm text-slate-900 hover:bg-slate-300">
                        <button
                          disabled={isDeletingJob}
                          className="text-left w-full"
                          onClick={handleDeleteJobActionClick}
                        >
                          Delete Job
                        </button>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="px-4 py-2 text-sm text-slate-900 hover:bg-slate-300">
                        <button className="text-left w-full" onClick={handleRetryJobClick}>
                          Retry Job
                        </button>
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <FeedbackContext.Provider
          value={{
            feedbackData: {
              ...latestFeedbackData,
              endpoint,
              data: { ...finalAnswer?.content },
              job_id: jobMetadata.job.id,
            },
            setLatestFeedbackData,
            isLoading: isLoadingFeedbackData,
          }}
        >
          {finalAnswerCheckResult && finalAnswerCheckResult.answer ? (
            <FinalOutput
              level={getFinalOutputLevel()}
              title={getFinalOutputTitle()}
              message={
                <div className="flex flex-col gap-y-2">
                  <h2 className="font-semibold">{getFinalOutputTitle()}</h2>
                  <div>{finalAnswerCheckResult.answer}</div>
                </div>
              }
              feedbackContent={finalAnswerCheckResult}
            />
          ) : null}
          {!["queued", "failed", "error"].includes(jobMetadata.job.status) && agent?.agent_type === "kyb" && (
            <FullWidthTabs
              tabs={tabs
                .map((tab) => {
                  if (
                    (((tab.id === "findings" && shouldRenderFindingsTab) || tab.id === "business") &&
                      kybAgentInstanceId !== null) ||
                    (tab.id === "individuals" && kycAgentInstanceIds?.length > 0) ||
                    (tab.id === "associated_entities" && entityAgentInstanceIds?.length > 0)
                  ) {
                    return {
                      ...tab,
                      count:
                        tab.id === "individuals"
                          ? kycAgentInstanceIds?.length
                          : tab.id === "associated_entities"
                            ? entityAgentInstanceIds?.length
                            : 0,
                    };
                  }
                  return null;
                })
                .filter((tab) => Boolean(tab))}
              selectedTab={selectedTab}
              onTabChangeHandler={setSelectedTab}
            />
          )}
          {/* {selectedTab === "alerts" && renderAlertsTab()} */}
          {selectedTab === "findings" && <ParchaReport jobMetadata={jobMetadata} />}
          {selectedTab === "business" && renderBusinessTab()}
          {selectedTab === "individuals" && renderIndividualsTab()}
          {selectedTab === "associated_entities" && renderAssociatedEntitiesTab()}
        </FeedbackContext.Provider>

        <Transition.Root show={isGenerateFollowUpOpen} as={Fragment}>
          <Dialog as="div" initialFocus={dialogDivRef} className="relative z-20" onClose={setIsGenerateFollowUpOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="h-[20rem] min-h-[20rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                        onClick={() => setIsGenerateFollowUpOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start h-full">
                      <div className="mt-3 text-center w-full sm:mt-0 sm:text-left" ref={dialogDivRef}>
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                          Follow-up Message
                        </Dialog.Title>
                        <div className="flex flex-col gap-2 py-4 h-full justify-between ">
                          <p className="p-4 w-full leading-4 text-xs text-slate-700 overflow-x-hidden overflow-y-auto h-[12rem] border-solid border border-slate-300 rounded-md">
                            <ReactMarkdown>{finalAnswer?.content.output.follow_up}</ReactMarkdown>
                          </p>
                          <div>
                            <button
                              tabIndex={1}
                              className="w-6 h-6 p-1 text-center border-solid border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                              onClick={handleCopyToClipboard}
                            >
                              <span className="sr-only">Copy to clipboard</span>
                              <CopyAllRounded
                                sx={{ fontSize: "1rem", width: "1rem", height: "1rem" }}
                                className="text-slate-900"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={isDeleteJobDialogOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20" onClose={setIsDeleteJobDialogOpen} initialFocus={deleteJobDivRef}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                      Are you sure you want to delete this job
                    </Dialog.Title>
                    <div className="flex flex-col gap-3 mt-3 text-sm" ref={deleteJobDivRef}>
                      <p>Once you delete the job you will no longer have access to it.</p>
                      <div className="flex justify-end gap-2">
                        <button
                          className="border-solid border border-slate-300 rounded-sm h-6 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                          onClick={() => setIsDeleteJobDialogOpen(false)}
                        >
                          Nevermind
                        </button>
                        <button
                          className="bg-brand-purple rounded-sm px-2 py-1 text-white focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                          onClick={deleteJob}
                        >
                          Yes, delete it
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Fab
          color="primary"
          aria-label="View Job Details"
          style={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            zIndex: 1000,
          }}
          onClick={() => setIsJobDetailsOpen(true)}
        >
          <Info />
        </Fab>

        <Drawer
          anchor="right"
          open={isJobDetailsOpen}
          onClose={() => setIsJobDetailsOpen(false)}
          PaperProps={{
            style: {
              width: "25vw",
              minWidth: "400px",
            },
          }}
        >
          <div className="px-2 h-full overflow-y-auto">
            <NewAgentChatSSE
              endpoint={endpoint}
              isParchaUser={isParchaUser}
              selectedTabValue={infoTabValue}
              setSelectedTabValue={setInfoTabValue}
              jobMetadata={jobMetadata}
              agent={agent ?? null}
            />
          </div>
        </Drawer>
      </div>
    )
  ) : null;
};

export default NewJobView;
