import { toTitleCase } from "@/utils";
import { BusinessRounded, PersonRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";

const OpenCorporatesAssociatedEntitiesCard = ({
  name,
  officers,
  url,
  level,
}: {
  name: string;
  url: string;
  officers: {
    name: string;
    position: string[];
  }[];
  level: "success" | "failure" | "normal";
}) => {
  const borderColor =
    level === "success" ? "border-green-300" : level === "failure" ? "border-red-300" : "border-slate-300";
  const backgroundColor = level === "success" ? "bg-[#DCFCE7]" : level === "failure" ? "bg-red-100" : "bg-slate-100";

  return (
    <div className={twJoin("flex flex-col border rounded", borderColor)}>
      <div className={twJoin("text-sm p-4 text-slate-900 border-b", borderColor, backgroundColor)}>
        <div className="flex justify-between">
          <div className="flex gap-x-1 text-sm items-center">
            <BusinessRounded sx={{ fontSize: "1rem" }} />
            {url && url.length > 0 ? <a href={url}>{name}</a> : <span className="font-medium">{name}</span>}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 px-4 py-5">
        {officers.length > 0 ? (
          officers.map((officer) => <OfficerCard name={officer.name} positions={officer.position} />)
        ) : (
          <OfficerCard name="No officers were found" positions={[]} />
        )}
      </div>
    </div>
  );
};

export default OpenCorporatesAssociatedEntitiesCard;

const OfficerCard = ({
  name,
  positions,
}: {
  name: string;

  positions: string[];
}) => {
  return (
    <div className="border border-slate-300 rounded-lg p-3 text-xs flex flex-col gap-y-3 text-xs">
      <div className="flex items-center gap-x-2 text-sm">
        <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
        <span className="text-slate-500">{name}</span>
      </div>
      {positions && positions.length > 0 && (
        <div className="flex flex-wrap gap-1">
          {positions.map((position) => (
            <span className="bg-slate-50 font-medium text-slate-700 px-2 py-1 rounded-full border border-[#334155]/20">
              {toTitleCase(position)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
