import { useState } from "react";
import { ErrorContext } from "./ErrorContext";

interface ErrorContextType {
  error: Error | null;
  setError: (error: Error | null) => void;
}

interface ErrorProviderProps {
  children: React.ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);

  const value: ErrorContextType = { error, setError };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};
