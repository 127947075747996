import facebookLogo from "../../assets/facebook_logo.png";

type FacebookProfileCardProps = {
  aboutMe: string | null;
  followersCount: string | null;
  industry: string | null;
  facebookUrl: string | null;
  name: string | null;
  url: string | null;
};

const FacebookProfileCard = ({
  aboutMe,
  followersCount,
  industry,
  facebookUrl,
  name,
  url,
}: FacebookProfileCardProps) => {
  return (
    <div className="w-full bg-slate-50 rounded-xl text-slate-900 text-xs p-4 text-slate-900 overflow-hidden">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <img src={facebookLogo} alt="Facebook Logo" className="w-4 h-4" />
              <span className="text-semibold">Facebook</span>
            </div>
            <div className="h-6.5 min-w-[6rem] flex justify-center items-center rounded-full bg-slate-50 border border-[#334155]/20 py-1 px-2 text-slate-700">
              Social Media
            </div>
          </div>
          {facebookUrl && (
            <a
              href={facebookUrl}
              target="_blank"
              rel="noreferrer"
              className="w-fit text-brand-purple hover:underline"
              onError={({ currentTarget }) => (currentTarget.style.display = "none")}
            >
              {facebookUrl}
            </a>
          )}
          <div className="flex flex-col gap-y-0.5">
            <div className="flex flex-wrap items-baseline gap-x-1">
              <span className="text-base text-bold">{name}</span>
              <span className="text-slate-500">{url}</span>
            </div>
            {industry && <div className="text-xs text-slate-700">{industry}</div>}
            {followersCount && <div className="text-slate-500">{followersCount} Followers</div>}
          </div>
        </div>
        {aboutMe && (
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">About Me</span>
            <p className="text-slate-700">{aboutMe}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FacebookProfileCard;
