import LanguangeIcon from "@mui/icons-material/LanguageRounded";

type WebsiteValueProps = {
  url: string | null;
  label?: string;
};

const WebsiteValue = ({ label, url }: WebsiteValueProps) => {
  if (!url) return label ? <p>{label}</p> : null;

  let urlToUse = url;
  if (!url.startsWith("http")) {
    urlToUse = `http://${url}`;
  }

  return (
    <div className="group text-brand-purple truncate text-xs">
      <a href={urlToUse} target="_blank" rel="noopener noreferrer" className="inline-flex gap-x-1 items-center w-full">
        <LanguangeIcon sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
        <p className="truncate group-hover:underline">{label ?? urlToUse}</p>
      </a>
    </div>
  );
};

export default WebsiteValue;
