import CancelRounded from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReactMarkdown from "react-markdown";
import { twJoin } from "tailwind-merge";
import style from "../../markdown-styles.module.css";

type FinalOutputEntryProps = {
  passed: boolean;
  explanation: string;
  stepName: string;
};

const FinalOutputEntry = ({ passed, explanation, stepName }: FinalOutputEntryProps) => {
  if (!stepName && !explanation) return null;

  const iconStyle = { width: "1.25rem", height: "1.25rem" };

  return (
    <div className="flex flex-col pl-[1.25rem] text-sm text-slate-700 mt-3">
      <div className="-ml-[calc(1.25rem/2)] mb-[0.5rem]">
        {passed ? (
          <CheckCircleIcon sx={iconStyle} className="text-green-600" />
        ) : (
          <CancelRounded sx={iconStyle} className="text-red-600" />
        )}
      </div>
      <div className="flex flex-col pl-[2rem] px-3 gap-2">
        <div className={twJoin("-mt-[1.75rem] font-medium text-xs", passed ? "text-green-600" : "text-red-600")}>
          <ReactMarkdown className={style.reactMarkDown}>{explanation}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default FinalOutputEntry;
