import React from "react";

const ApproveBadge = ({ children }: { children?: JSX.Element }) => {
  return (
    <span className="h-6 flex items-center flex-grow-0 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 border border-solid border-green-600/20">
      {children || "Approve"}
    </span>
  );
};

export default ApproveBadge;
