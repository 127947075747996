// @ts-nocheck
import { twJoin } from "tailwind-merge";
import { PublicOffRounded } from "@mui/icons-material";
import Alert from "./Alert";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const HighRiskCountryFindings = ({ checkResult, hideHeader }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PublicOffRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">High-Risk Countries</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <FindingsLoadingIndicator
          message={`Running High-Risk Countries check${
            checkResult.metadata?.business_name ? ` on ${checkResult.metadata.business_name} ` : ""
          }...`}
        />
      </div>
    );
  }

  if (!checkResult.check_result) {
    return ["error", "complete"].includes(checkResult.status) ? (
      <div className="flex flex-col gap-y-3 w-full">
        <div className="flex items-center gap-x-2">
          <PublicOffRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className="text-lg font-semibold">High-Risk Countries</span>
        </div>
        <Alert message="No data found." level="caution" />
      </div>
    ) : null;
  }

  const operatingCountriesMatch = checkResult?.check_result?.operating_countries_match || [];

  const customerCountriesMatch = checkResult?.check_result?.customer_countries_match || [];

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {!hideHeader && Header}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="flex items-start gap-x-2 text-xs">
        <div className="flex flex-1 flex-col">
          <div className="flex gap-x-2">
            <div
              className={twJoin(
                "rounded-lg flex-1 flex flex-col gap-y-2 p-4",
                !checkResult.passed ? "bg-white border border-red-600" : "bg-slate-50",
              )}
            >
              <span className="font-semibold">Operating Countries</span>
              <div className="flex flex-col gap-y-2">
                {operatingCountriesMatch.length > 0 ? (
                  operatingCountriesMatch.map((country, index) => (
                    <span key={index} className={`font-semibold ${!checkResult.passed ? "text-red-600" : ""}`}>
                      {country.country}
                    </span>
                  ))
                ) : (
                  <span className="text-gray-500">No matches found</span>
                )}
              </div>
            </div>
            <div
              className={twJoin(
                "rounded-lg flex-1 flex flex-col gap-y-2 p-4",
                !checkResult.passed ? "bg-white border border-red-600" : "bg-slate-50",
              )}
            >
              <span className="font-semibold">Customer Countries</span>
              <div className="flex flex-col gap-y-2">
                {customerCountriesMatch.length > 0 ? (
                  customerCountriesMatch.map((country, index) => (
                    <span key={index} className={`font-semibold ${!checkResult.passed ? "text-red-600" : ""}`}>
                      {country.country}
                    </span>
                  ))
                ) : (
                  <span className="text-gray-500">No matches found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighRiskCountryFindings;
