const GenericSkeleton = () => (
  <div className="flex flex-col gap-y-2 animate-pulse w-full py-5">
    <div className="w-full flex items-center gap-2">
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
    </div>
    <div className="w-full flex items-center gap-2">
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
      <div className="w-1/4 h-3 rounded-lg bg-slate-200"></div>
    </div>
    <div className="w-full flex items-center gap-2">
      <div className="w-1/4 h-3 rounded-lg bg-slate-200"></div>
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
    </div>
    <div className="w-full flex items-center gap-2">
      <div className="w-1/4 h-3 rounded-lg bg-slate-200"></div>
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
    </div>
    <div className="w-full flex items-center gap-2">
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
      <div className="w-1/3 h-3 rounded-lg bg-slate-200"></div>
    </div>
  </div>
);

export default GenericSkeleton;
