// @ts-nocheck
import { useState } from "react";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const Accordion = ({
  title,
  main,
  content,
  expanded = false,
  preventExpandCollapse = false,
  compact = false,
}: {
  title: React.ReactNode;
  main: React.ReactNode;
  content: React.ReactNode;
  preventExpandCollapse?: boolean;
  expanded?: boolean;
  compact?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(expanded);

  return (
    <div className="flex flex-col gap-2 w-full">
      {!compact && (
        <div
          className={clsx("flex gap-x-2", { "cursor-not-allowed": preventExpandCollapse })}
          role="button"
          onClick={!preventExpandCollapse ? () => setIsOpen((prevIsOpen: boolean) => !prevIsOpen) : undefined}
        >
          {isOpen ? <ExpandLessIcon className=" text-slate-400" /> : <ExpandMoreIcon className="text-slate-400" />}
          {title}
        </div>
      )}
      {main}
      {isOpen ? <div>{content}</div> : null}
    </div>
  );
};

export default Accordion;
