const InfoValue = ({ label, value }: { label: string; value: string }) => {
  if (!value || !value.length) return null;

  return (
    <div className="flex gap-1">
      <div className="font-semibold">{label}:</div>
      <div>{value}</div>
    </div>
  );
};

export default InfoValue;
