export const parchaBusinessData = {
  businessName: "Parcha",
  registeredBusinessName: "Parcha Labs Incorporated",
  website: "https://www.parcha.com",
  businessPurpose: "B2B Software as a Service",
  industry: "Technology",
  tinNumber: "92-3265708",
  addressOfIncorporation: {
    street1: "251 Little Falls Drive",
    street2: "",
    city: "Wilmington",
    state: "DE",
    countryCode: "US",
    postalCode: "19808",
  },
  addressOfOperation: {
    street1: "1160 Battery Street",
    street2: "",
    city: "San Francisco",
    state: "CA",
    countryCode: "US",
    postalCode: "94111",
  },
  sourceOfFunds: ["Investment", "Revenue"],
  businessDescription: "AI agents for ops and compliance workflow automation.",
  incorporationDate: "2023-03-29",
  mccCode: "",
  partners: [],
  customers: [],
  incorporationDocs: [
    {
      url: "https://storage.googleapis.com/parcha-ai-public-assets/Parcha_incorporation_doc.pdf",
      file_name: "Parcha_incorporation_doc.pdf",
      source_type: "file_url",
    },
  ],
  businessOwnershipDocs: [
    {
      url: "https://storage.googleapis.com/parcha-ai-public-assets/All%20Stakeholders%20Ledger%20June%2017%202024.pdf",
      file_name: "Parcha_cap_table.pdf",
      source_type: "file_url",
    },
  ],
  proofOfAddressDocs: [
    {
      url: "https://storage.googleapis.com/parcha-ai-public-assets/Parcha_proof_of_address.pdf",
      file_name: "Parcha_proof_of_address.pdf",
      source_type: "file_url",
    },
  ],
  einDocs: [
    {
      url: "https://storage.googleapis.com/parcha-ai-public-assets/CP575Notice_Parcha.pdf",
      file_name: "Parcha_EIN.pdf",
      source_type: "file_url",
    },
  ],
  sourceOfFundsDocs: [
    {
      url: "https://storage.googleapis.com/parcha-ai-public-assets/Parcha_fundraising_announcement.pdf",
      file_name: "Parcha_funding_round.pdf",
      source_type: "file_url",
    },
  ],
  promoMarketingDocs: [],
  cannabisLicenseDocs: [],
};

export const parchaAssociatedIndividualsData = [
  {
    firstName: "Ajmal",
    middleName: "Arshan",
    lastName: "Asver",
    dateOfBirth: "1985-12-24",
    sex: "Male",
    placeOfBirth: "Colombo, Sri Lanka",
    address: {
      street1: "746 4th Ave",
      street2: "",
      city: "San Francisco",
      state: "CA",
      countryCode: "US",
      postalCode: "94118",
    },
    countryOfNationality: "US",
    countryOfResidence: "US",
    email: "aj@parcha.ai",
    phone: "4152902190",
    title: "CEO",
    isApplicant: true,
    isBusinessOwner: true,
    businessOwnershipPercentage: 0.45,
    proofOfAddressDocs: [
      {
        url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/AJ_proof_of_address.pdf",
        file_name: "AJ_proof_of_address.pdf",
        source_type: "file_url",
      },
    ],
  },
  {
    firstName: "Miguel",
    middleName: "Angel",
    lastName: "Rios Berrios",
    dateOfBirth: "1986-04-25",
    address: {
      street1: "216 Encounter Bay",
      street2: "",
      city: "Alameda",
      state: "CA",
      countryCode: "US",
      postalCode: "94502",
    },
    countryOfNationality: "US",
    countryOfResidence: "US",
    placeOfBirth: "Aibonito, Puerto Rico",
    sex: "Male",
    email: "miguel@miguelrios.org",
    phone: "5103457204",
    title: "Chief Technology Officer",
    isApplicant: true,
    isBusinessOwner: true,
    businessOwnershipPercentage: 0.45,
    proofOfAddressDocs: [
      {
        url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/bill-miguel.pdf",
        file_name: "bill-miguel.pdf",
        source_type: "file_url",
      },
    ],
  },
];

export const parchaAssociatedEntitiesData = [
  {
    businessName: "Kindred Ventures",
    isTrust: false,
    address: {
      street1: "660 4th Street",
      street2: "Suite 656",
      city: "San Francisco",
      state: "CA",
      countryCode: "US",
      postalCode: "94107",
    },
    proofOfAddressDocs: [],
    industry: "Venture Capital",
    tinNumber: null,
    businessOwnershipPercentage: 0.1,
    countryCode: "US",
    website: "www.kindredventures.com",
    businessDescription:
      "Founded in 2014, Kindred Ventures is a venture capital firm based in San Francisco, California. The firm seeks to invest in companies operating in consumer products, consumer services, energy, healthcare, information technology, TMT, e-commerce, blockchain, cryptocurrency, artificial intelligence, machine learning, and technology-based sectors.",
  },
];
