import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Button } from "@mui/material";
import _ from "lodash";
import styles from "./styles.module.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const InlineCollapsibleText = ({ text }: { text: string }) => {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => setClamped(!clamped);

  useEffect(() => {
    const hasClamping = (el: HTMLElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = _.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <div className={clsx("flex flex-col gap-y-1", { "items-end": showButton })}>
      <div ref={containerRef} className={clsx("text-xs", { [styles.clamp]: clamped })}>
        {text}
      </div>
      {showButton && (
        <button onClick={handleClick} className="text-brand-purple text-xs hover:underline">
          {clamped ? "Read more" : "Show less"}
        </button>
      )}
    </div>
  );
};

export default InlineCollapsibleText;
