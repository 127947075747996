import React from "react";
import AddressValue, { AddressValueProps } from "./AddressValue";
import WebsiteValue from "./WebsiteValue";

type BusinessInfoCardProps = {
  name: string;
  description: string;
  address: AddressValueProps;
  industry: string;
  country: string[];
  partners: string[];
  customers: string[];
  customerCountries: string[];
  candidateMCCCodes: string[];
  url: string;
};

export default function BusinessInfoCard({
  name,
  description,
  country,
  address,
  customers,
  industry,
  partners,
  candidateMCCCodes,
  url,
}: BusinessInfoCardProps) {
  if (!name && !description) return null;

  return (
    <div className="my-5 w-full flex justify-center text-sm">
      <div className="w-[24rem] flex flex-col rounded-lg border border-slate-300 text-sm ">
        <div className="flex rounded-t-md space-x-2 p-4 border-b bg-slate-100 border-slate-300">
          {name || industry ? (
            <div className="flex flex-col text-slate-900">
              {name ? <h1 className="leading-[15px] uppercase font-semibold">{name}</h1> : null}
              {industry ? <p>{industry}</p> : null}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-y-2 p-4 text-xs leading-snug">
          {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
            <div>
              <div className="font-semibold mb-1">Business Address: </div>
              <AddressValue {...address} />
            </div>
          )}
          {url && (
            <div>
              <div className="font-semibold mb-1">Website: </div>
              <WebsiteValue url={url} />
            </div>
          )}
          {description && (
            <div>
              <p className="font-medium mb-1">Description:</p>
              <p className="text-slate-700">{description}</p>
            </div>
          )}
          {country && !!country.length && (
            <div>
              <span className="font-semibold">Business Country: </span>
              <span className="text-slate-700">{country.join(", ")}</span>
            </div>
          )}
          {partners && !!partners.length && (
            <div>
              <span className="font-semibold">Partners: </span>
              <span className="text-slate-700">{partners.join(", ")}</span>
            </div>
          )}
          {customers && !!customers.length && (
            <div>
              <span className="font-semibold">Customers: </span>
              <span className="text-slate-700">{customers.join(", ")}</span>
            </div>
          )}
          {candidateMCCCodes && !!candidateMCCCodes.length && (
            <div>
              <span className="font-semibold">Candidate MCC Codes: </span>
              <span className="text-slate-700">{candidateMCCCodes.join(", ")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
