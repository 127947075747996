import { Fragment, ReactNode, useState } from "react";
import { twJoin } from "tailwind-merge";
import { BusinessRounded, CheckCircleRounded, CancelRounded, WarningRounded, PersonRounded } from "@mui/icons-material";
import AddressValue, { AddressValueProps } from "./AddressValue";
import WebsiteValue from "./WebsiteValue";
import WrappedValue from "./WrappedValue";

type Source = {
  id: number;
  url: string;
  title: string;
  snippet: string;
};

type OpenSourceBusinessDueDiligenceCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  operationAddress: AddressValueProps;
  incorporationAddress: AddressValueProps;
  description: string | undefined | null;
  name: string | undefined | null;
  websiteUrl?: string | undefined;
  businessNameMatch: "exact" | "soft" | "none";
  businessNameMatchExplanation: string;
  businessWebsiteMatchExplanation: string;
  businessDescriptionMatchExplanation: string;
  businessOperatingAddressMatchExplanation: string;
  businessIncorporationAddressMatchExplanation: string;
  businessWebsiteMatch: "exact" | "soft" | "none";
  businessDescriptionMatch: "exact" | "soft" | "none";
  addressOfIncorporationMatch: "exact" | "soft" | "none";
  addressOfOperationMatch: "exact" | "soft" | "none";
  businessOwnerMatches: {
    name: string;
    match: "exact" | "soft" | "none";
  }[];
  sources: Source[];
};

const OpenSourceBusinessDueDiligenceCard = ({
  level,
  name,
  websiteUrl,
  description,
  incorporationAddress,
  operationAddress,
  businessNameMatch,
  businessNameMatchExplanation,
  businessWebsiteMatchExplanation,
  businessDescriptionMatchExplanation,
  businessOperatingAddressMatchExplanation,
  businessIncorporationAddressMatchExplanation,
  businessWebsiteMatch,
  businessDescriptionMatch,
  addressOfIncorporationMatch,
  addressOfOperationMatch,
  businessOwnerMatches,
  sources,
}: OpenSourceBusinessDueDiligenceCardProps) => {
  const [hoveredSourceId, setHoveredSourceId] = useState<number | null>(null);

  return (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "flex flex-col justify-center p-4 h-17 rounded-t-lg border-b",
          level === "success"
            ? "bg-green-100 text-green-600 border-green-600"
            : level === "failure"
              ? "bg-red-100 text-red-600 border-red-600"
              : "bg-slate-100 text-slate-900 border-slate-300",
        )}
      >
        <div className="font-semibold uppercase text-md">{name}</div>
      </div>
      <div className="grid grid-cols-2 gap-2 px-4 pb-4 pt-5 text-xs">
        <h2 className="text-sm font-medium">Self-Attested Inputs</h2>
        <h2 className="text-sm font-medium">Findings</h2>

        <span className="col-span-2 font-semibold mt-2">Business Name:</span>
        <div className="flex gap-x-1">
          <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
          <span className="text-slate-700">{name}</span>
        </div>

        <div>
          {renderMatch(businessNameMatch, businessNameMatchExplanation, sources, hoveredSourceId, setHoveredSourceId)}
        </div>

        {websiteUrl && (
          <>
            <span className="col-span-2 font-semibold mt-2">Website:</span>
            <WebsiteValue url={websiteUrl} />

            <div className="flex items-center gap-x-1">
              <div>
                {renderMatch(
                  businessWebsiteMatch,
                  businessWebsiteMatchExplanation,
                  sources,
                  hoveredSourceId,
                  setHoveredSourceId,
                )}
              </div>
            </div>
          </>
        )}

        {incorporationAddress &&
          !Object.entries(incorporationAddress).every(
            ([k, v]) => k === "type" || v === null || v === "unverified" || v === "",
          ) && (
            <>
              <span className="col-span-2 font-semibold mt-2">Incorporation Address</span>
              <AddressValue {...incorporationAddress} />
              <div>
                {renderMatch(
                  addressOfIncorporationMatch,
                  businessIncorporationAddressMatchExplanation,
                  sources,
                  hoveredSourceId,
                  setHoveredSourceId,
                )}
              </div>
            </>
          )}

        {operationAddress &&
          !Object.entries(operationAddress).every(
            ([k, v]) => k === "type" || v === null || v === "unverified" || v === "",
          ) && (
            <>
              <span className="col-span-2 font-semibold mt-2">Address of Operation</span>
              <AddressValue {...operationAddress} />
              <div>
                {renderMatch(
                  addressOfOperationMatch,
                  businessOperatingAddressMatchExplanation,
                  sources,
                  hoveredSourceId,
                  setHoveredSourceId,
                )}
              </div>
            </>
          )}

        {description && (
          <>
            <span className="col-span-2 font-semibold mt-2">Description:</span>
            <p className="line-clamp-5 text-xs text-slate-700">{description}</p>

            <div>
              {renderMatch(
                businessDescriptionMatch,
                businessDescriptionMatchExplanation,
                sources,
                hoveredSourceId,
                setHoveredSourceId,
              )}
            </div>
          </>
        )}

        {businessOwnerMatches.length > 0 && (
          <div className="col-span-full">
            <div className="flex flex-col gap-y-2">
              <span className="font-semibold">UBOs:</span>
              <div className="flex flex-col gap-y-4">
                {businessOwnerMatches.map((owner) => (
                  <div className="flex justify-between items-center border border-slate-300 rounded-lg p-3">
                    <div className="flex gap-x-2">
                      <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                      <span>{owner.name}</span>
                    </div>
                    <span>
                      {renderUBOMatch(
                        owner.match,
                        owner.match === "exact" ? "Match" : owner.match === "soft" ? "Partial Match" : "Not Found",
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {sources?.length > 0 && (
          <div className="col-span-full">
            <div className="flex flex-col gap-y-2">
              <span className="font-semibold">Sources:</span>
              <div className="flex flex-col gap-y-4">
                {sources.map((source) => (
                  <div>
                    <div className="flex flex-col border border-slate-300 rounded-lg p-3 gap-2">
                      <div
                        className="flex items-center gap-2 leading-normal group"
                        onMouseEnter={() => setHoveredSourceId(source.id)}
                        onMouseLeave={() => setHoveredSourceId(null)}
                      >
                        <span>
                          <a
                            href={source.url}
                            target="__blank"
                            rel="noreferrer"
                            className={twJoin(
                              "first:ml-0 ml-1 inline-flex h-[1rem] min-w-[1rem] items-center justify-center rounded-full text-[0.625rem]",
                              hoveredSourceId === source.id
                                ? "bg-brand-purple text-white"
                                : "bg-slate-50 text-slate-500",
                            )}
                          >
                            {source.id}
                          </a>
                        </span>
                        <a
                          href={source.url}
                          target="_blank"
                          rel="noreferrer"
                          className={twJoin("text-brand-purple", source.id === hoveredSourceId ? "underline" : "")}
                        >
                          {source.title}
                        </a>
                      </div>
                      {source.snippet && <span className="text-slate-500">{source.snippet}</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const renderMatch = (
  match: "exact" | "soft" | "none",
  text: string,
  sources: Source[] = [],
  hoveredSourceId: number | null = null,
  setHoveredSourceId: (id: number | null) => void = () => {},
) => {
  const getIcon = () => {
    if (match === "exact") {
      return <CheckCircleRounded sx={{ fontSize: "1rem" }} />;
    } else if (match === "soft") {
      return <WarningRounded sx={{ fontSize: "1rem" }} />;
    } else if (match === "none") {
      return <CancelRounded sx={{ fontSize: "1rem" }} />;
    }
  };

  const getDefaultText = () => {
    if (match === "exact") {
      return "Exact match found.";
    } else if (match === "soft") {
      return "Partial match found.";
    } else if (match === "none") {
      return "No match found.";
    }
  };

  const handleMouseEnter = (sourceId: number) => {
    setHoveredSourceId(sourceId);
  };

  const handleMouseLeave = () => {
    setHoveredSourceId(null);
  };

  const replaceWithLinks = (inputText: string) => {
    const regex = /\[\d+(?:,\s*\d+)*\]/g; // Correct regex to match [1] or [1, 2, 3]
    const parts = inputText.split(regex);
    const matches = inputText.match(regex);

    return parts.reduce((acc: ReactNode[], part: ReactNode, index: number) => {
      acc.push(part);

      if (matches && index < matches.length) {
        const match = matches[index];
        const ids = match
          .slice(1, -1)
          .split(",")
          .map((id) => parseInt(id.trim()));

        ids.forEach((id) => {
          const source = sources.find((s) => s.id === id);
          if (source) {
            acc.push(
              <Fragment key={id}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={twJoin(
                    "inline-flex h-[1rem] min-w-[1rem] items-center justify-center rounded-full text-[0.625rem]",
                    hoveredSourceId == source.id ? "bg-brand-purple text-white" : "bg-slate-50 text-slate-500",
                  )}
                  href={source.url}
                  onMouseEnter={() => handleMouseEnter(source.id)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {source.id}
                </a>
              </Fragment>,
            );
          } else {
            acc.push(`[${id}]`); // fallback to plain text if no source found
          }
        });
      }

      return acc;
    }, []);
  };

  return (
    <div
      className={twJoin(
        "flex gap-x-2 w-full",
        match === "exact" ? "text-green-600" : match === "soft" ? "text-yellow-600" : "text-red-600",
      )}
    >
      {getIcon()}
      <div className="prose inline leading-normal break-words min-w-0 [word-break:break-word]">
        {text ? replaceWithLinks(text) : getDefaultText()}
      </div>
    </div>
  );
};

const renderUBOMatch = (match: "exact" | "soft" | "none", text: string) => {
  if (match === "exact") {
    return (
      <div className="flex items-center gap-x-3 text-green-600">
        <CheckCircleRounded sx={{ fontSize: "1rem" }} />
        <WrappedValue text={text} level="success" />
      </div>
    );
  } else if (match === "soft") {
    return (
      <div className="flex items-center gap-x-3 text-yellow-600">
        <WarningRounded sx={{ fontSize: "1rem" }} />
        <WrappedValue text={text} level="caution" />
      </div>
    );
  } else if (match === "none") {
    return (
      <div className="flex items-center gap-x-3 text-red-600">
        <CancelRounded sx={{ fontSize: "1rem" }} />
        <WrappedValue text={text} level="failure" />
      </div>
    );
  }
};

export default OpenSourceBusinessDueDiligenceCard;
