import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    text: {
      primary: "#6C727F",
    },
  },
});
