import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface AnimatedParagraphProps {
  text: string;
  className?: string;
}

const AnimatedParagraph: React.FC<AnimatedParagraphProps> = ({ text, className = "" }) => {
  const [currentText, setCurrentText] = useState(text);

  useEffect(() => {
    if (text !== currentText) {
      setCurrentText(text);
    }
  }, [text]);

  return (
    <span className={className}>
      <AnimatePresence mode="wait">
        <motion.span
          key={currentText}
          initial={{ opacity: 0, filter: "blur(4px)" }}
          animate={{ opacity: 1, filter: "blur(0px)" }}
          exit={{ opacity: 0, filter: "blur(4px)" }}
          transition={{ duration: 0.5 }}
          className="text-sm"
        >
          {currentText}
        </motion.span>
      </AnimatePresence>
    </span>
  );
};

export default AnimatedParagraph;
