import { twJoin } from "tailwind-merge";

const MiniMatchLevelPill = ({ matchRating }: { matchRating: string }) => {
  return (
    <div
      className={twJoin(
        "w-fit text-[0.6875rem] px-1 py-0.5 flex rounded-md truncate items-center font-light",
        matchRating === "strong_match"
          ? "text-red-700 bg-red-100"
          : matchRating === "partial_match"
            ? "text-orange-700 bg-orange-100"
            : matchRating === "mismatch"
              ? "text-yellow-700 bg-yellow-100"
              : "text-slate-700 bg-slate-200",
      )}
    >
      {matchRating === "strong_match"
        ? "Exact"
        : matchRating === "partial_match"
          ? "Partial"
          : matchRating === "mismatch"
            ? "Mismatch"
            : matchRating === "missing"
              ? "Missing"
              : matchRating}
    </div>
  );
};

export default MiniMatchLevelPill;
