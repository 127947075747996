// @ts-nocheck
import { ChangeEvent, useRef, useState } from "react";
import { DeleteForeverRounded, PercentRounded, UploadFileRounded } from "@mui/icons-material";
import AddressInput from "../TailwindComponents/AddressInput";
import { useParchaApi } from "@/hooks/useParchaApi";
import DocumentValue from "../TailwindComponents/DocumentValue";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";

const AssociatedEntityForm = ({ selectedEndpoint, entity, formFields, deleteEntity, updateEntity, index }) => {
  const proofOfAddressDocsInputRef = useRef(null);
  const parchaApi = useParchaApi();
  const [isUploadingProofOfAddressDocs, setIsUploadingProofOfAddressDocs] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      updateEntity(index, { ...entity, [name]: e.target.checked });
      return;
    }
    updateEntity(index, { ...entity, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    updateEntity(index, {
      ...entity,
      address: {
        ...entity.address,
        [name]: value,
      },
    });
  };

  const handlePlaceSelectionChange = (address) => {
    updateEntity(index, {
      ...entity,
      address,
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files) return;

    const key = e.currentTarget.querySelector("input")?.name;
    await uploadFiles(key, files);
  };

  const handleProofOfAddressDocumentsClick = (e) => {
    const currentRef = proofOfAddressDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const uploadFiles = async (key, files) => {
    setIsUploadingProofOfAddressDocs(true);
    [...files].forEach((file) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (loadEvent: Event) => {
          const base64String = loadEvent?.target?.result?.split(",")[1];
          const result = await parchaApi.uploadBase64Document(
            selectedEndpoint.endpointUrl,
            base64String,
            "Document Name",
          );
          const newFile = { ...result, file_name: file.name, source_type: "file_url" };
          delete newFile.expiration;
          updateEntity(index, { ...entity, [key]: [...entity[key], newFile] });
          setIsUploadingProofOfAddressDocs(false);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    await uploadFiles(event.target.name, files);
  };

  return (
    <div className="space-y-8 px-12">
      <div>
        <div className="mt-4 grid gap-x-3 gap-y-4 sm:grid-cols-6">
          <div className="col-span-full flex justify-between items-center">
            <h4 className="col-span-full text-base font-semibold text-slate-800">{`Entity #${index + 1}`}</h4>
            {index > 0 && (
              <button
                type="button"
                className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
                onClick={() => deleteEntity(index)}
              >
                <DeleteForeverRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1">Delete This Entity</span>
              </button>
            )}
          </div>
          <div className="sm:col-span-full relative flex gap-x-3 items-center">
            <div className="flex grow h-6 justify-start gap-x-2 items-center">
              <input
                id="isTrust"
                name="isTrust"
                type="checkbox"
                onChange={handleChange}
                checked={entity.isTrust}
                value={entity.isTrust}
                className="h-3 w-3 rounded border border-solid border-slate-300 text-brand-purple focus:ring-brand-purple"
              />
              <label htmlFor="isTrust" className="text-xs font-medium text-slate-900">
                This is a trust
              </label>
            </div>
          </div>
          <div className="flex col-span-full gap-x-3">
            {!formFields || formFields?.fields?.includes("business_name") ? (
              <InputWithOverlappingLabel
                label="Business Name"
                fieldName="businessName"
                value={entity.businessName}
                onChangeHandler={handleChange}
                className="grow"
              />
            ) : null}
            {!formFields || formFields?.fields?.includes("registered_business_name") ? (
              <InputWithOverlappingLabel
                label="Registered Business Name"
                fieldName="registeredBusinessName"
                value={entity.registeredBusinessName}
                onChangeHandler={handleChange}
                className="grow"
              />
            ) : null}
          </div>
          {!formFields ||
          formFields?.fields?.some((field) => ["business_name", "registered_business_name"].includes(field)) ? (
            <div className="col-span-full">
              <hr />
            </div>
          ) : null}
          {!formFields || formFields?.fields?.includes("address") ? (
            <div className="col-span-full">
              <h4 className="text-base font-semibold text-slate-800 mb-4">Address</h4>
              <AddressInput
                address={entity.address}
                onAddressChange={handleAddressChange}
                onPlaceChange={handlePlaceSelectionChange}
              />
            </div>
          ) : null}
          {!formFields || formFields?.fields?.includes("address") ? (
            <div className="col-span-full">
              <h4 className="text-md font-semibold text-slate-800 mb-4">Proof of Address Documents</h4>
              <div className="flex flex-col gap-y-2 text-xs">
                {entity.proofOfAddressDocs.map((doc: { file_name?: string; url?: string }) => (
                  <div className="flex items-center gap-x-1" key={doc.url}>
                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                    <button
                      onClick={() => {
                        updateEntity(index, {
                          ...entity,
                          proofOfAddressDocs: entity.proofOfAddressDocs.filter((d) => d.url !== doc.url),
                        });
                      }}
                    >
                      <DeleteForeverRounded
                        sx={{ fontSize: "1.5rem", height: "1.5rem", width: "1.5rem" }}
                        className="text-red-600"
                      />
                    </button>
                  </div>
                ))}
              </div>
              <div
                className="border-dashed border-2 border-slate-300 p-4"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {isUploadingProofOfAddressDocs ? (
                  <ParchaLoadingScreen message="Uploading Proof of Address documents..." size="small" />
                ) : (
                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs">
                    <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                    <p className="text-slate-700">
                      Drag & Drop or{" "}
                      <span
                        className="underline text-brand-purple"
                        role="button"
                        onClick={handleProofOfAddressDocumentsClick}
                      >
                        Choose company documents
                      </span>{" "}
                      to upload
                    </p>
                    <input
                      type="file"
                      name="proofOfAddressDocs"
                      multiple
                      accept="application/pdf"
                      onChange={handleFileSelect}
                      ref={proofOfAddressDocsInputRef}
                      className="hidden"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {!formFields ||
          formFields?.fields.some((field) =>
            [
              "tin_number",
              "industry",
              "website",
              "business_description",
              "is_business_owner",
              "business_ownership_percentage",
            ].includes(field),
          ) ? (
            <>
              <div className="col-span-full">
                <h3 className="font-semibold text-base">Entity Details</h3>
              </div>
              <div className="col-span-full">
                <div className="grid grid-cols-6 gap-x-3 gap-y-6">
                  <div className="col-span-full flex gap-x-3">
                    {!formFields || formFields?.fields?.includes("tin_number") ? (
                      <InputWithOverlappingLabel
                        label="TIN"
                        fieldName="tinNumber"
                        value={entity.tinNumber}
                        onChangeHandler={handleChange}
                        className="grow"
                      />
                    ) : null}

                    {!formFields || formFields?.fields?.includes("industry") ? (
                      <InputWithOverlappingLabel
                        label="Industry"
                        fieldName="industry"
                        value={entity.industry}
                        onChangeHandler={handleChange}
                        className="grow"
                      />
                    ) : null}
                  </div>
                  {!formFields || formFields?.fields?.includes("website") ? (
                    <div className="col-span-full">
                      <InputWithOverlappingLabel
                        label="Website"
                        fieldName="website"
                        value={entity.website}
                        onChangeHandler={handleChange}
                      />
                    </div>
                  ) : null}
                  {!formFields || formFields?.fields?.includes("business_description") ? (
                    <div className="col-span-full">
                      <label
                        htmlFor="businessDescription"
                        className="block text-xs font-medium leading-6 text-slate-900"
                      >
                        Business Description
                      </label>
                      <textarea
                        rows={4}
                        name="businessDescription"
                        placeholder="Business Description"
                        id="businessDescription"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-xs sm:leading-6"
                        value={entity.businessDescription}
                      />
                    </div>
                  ) : null}

                  {!formFields || formFields?.fields?.includes("is_business_owner") ? (
                    <div className="col-span-2 relative flex gap-x-2 items-center">
                      <div className="flex items-center">
                        <input
                          id="isBusinessOwner"
                          name="isBusinessOwner"
                          type="checkbox"
                          onChange={handleChange}
                          checked={entity.isBusinessOwner}
                          value={entity.isBusinessOwner}
                          className="h-3 w-3 rounded border border-solid border-slate-300 text-brand-purple focus:ring-brand-purple"
                        />
                      </div>
                      <label htmlFor="isBusinessOwner" className="text-xs font-medium text-slate-900 w-full">
                        Business Owner
                      </label>
                    </div>
                  ) : null}

                  {!formFields || formFields?.fields?.includes("business_ownership_percentage") ? (
                    <div className="col-start-3 col-span-full relative">
                      <div className="flex gap-x-2 items-center">
                        <label htmlFor="businessOwnershipPercentage" className="font-medium text-xs w-full text-right">
                          Ownership Percentage:
                        </label>
                        <div className="relative rounded-md shadow-sm flex justify-between w-[100px] items-center">
                          <input
                            type="text"
                            name="businessOwnershipPercentage"
                            id="businessOwnershipPercentage"
                            className="block w-full rounded-md border-0 py-1.5 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-xs sm:leading-6"
                            placeholder="0.0-1.0"
                            aria-describedby="businessOwnershipPercentage"
                            value={entity.businessOwnershipPercentage}
                            onChange={(event) => {
                              const newValue = event.target.value;

                              // Allow empty input to clear the field
                              if (newValue === "") {
                                handleChange({
                                  ...event,
                                  target: { ...event.target, name: "businessOwnershipPercentage", value: "" },
                                });
                                return;
                              }

                              // Check if the input is a number and within the 0-100 range
                              if (
                                newValue === "" ||
                                newValue === "." ||
                                newValue === "1." ||
                                newValue === "0." ||
                                (isFinite(newValue) && newValue >= 0 && newValue <= 1)
                              ) {
                                handleChange({
                                  ...event,
                                  target: { ...event.target, name: "businessOwnershipPercentage", value: newValue },
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AssociatedEntityForm;
