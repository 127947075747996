import { getRefreshToken, getSessionToken } from "@descope/react-sdk";
import { Agent, AgentConfig, AgentJobInput, AgentRow, AgentRun, Endpoints, MonthlyStats, QueueItem } from "../types";
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { EndpointsAndTenant } from "@parcha/types";
import { map } from "lodash";

// Support for descope api
// We use this function in the extension to verify
// if a customer is signed in to our web service.
// the token is stored in the localstorage of the page
// so we can generate a session token securely.
class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedError";
  }
}

async function getHeaders() {
  const sessionToken = getSessionToken();
  const authHeader = sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {};
  const headers = new Headers({
    ...(authHeader.Authorization ? { Authorization: authHeader.Authorization } : {}),
  });
  return headers;
}

function getAPIHeaders(apiKey: string) {
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  });
  return headers;
}

class ParchaApi {
  private maxRetries: number = 0; // Maximum number of retries
  private retryDelay: number = 1000;
  private setError: (error: Error | null) => void;

  constructor(setError: (error: Error | null) => void) {
    // this.setError = setError;
  }

  async postWithRetry(apiDomain: string, endpoint: string, data: any, retryCount: number = 0): Promise<any> {
    const headers = await getHeaders();
    if (!(data instanceof FormData)) {
      headers.append("Content-Type", "application/json");
    }
    const protocol = apiDomain.includes("localhost") ? "http" : "https";
    const url = `${protocol}://${apiDomain}/${endpoint}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data instanceof FormData ? data : JSON.stringify(data),
      });

      if (response.status === 401 && retryCount < this.maxRetries) {
        // Retry the request
        await new Promise((resolve) => setTimeout(resolve, this.retryDelay));
        return this.postWithRetry(apiDomain, endpoint, data, retryCount + 1);
      } else if (response.status === 401 && retryCount >= this.maxRetries) {
        throw new UnauthorizedError(
          "API is returning 401 after maximum retries. Likely due to a login issue. Try logging out and in again.",
        );
      } else if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      return response.json();
    } catch (error) {
      // this.setError(error);
    }
  }

  private buildSearchParams = (data: any) => {
    const params = new URLSearchParams();

    map(data, (value, key) => {
      if (Array.isArray(data[key])) {
        map(value, (item) => params.append(key, item));
      } else {
        params.append(key, value);
      }
    });
    return params;
  };

  async getWithRetry(apiDomain: string, endpoint: string, params: any, retryCount: number = 0): Promise<any> {
    const urlParams = this.buildSearchParams(params);

    const headers = await getHeaders();
    const protocol = apiDomain.includes("localhost") ? "http" : "https";
    const url = `${protocol}://${apiDomain}/${endpoint}`;
    try {
      const response = await fetch(`${url}?${urlParams}`, {
        method: "GET",
        headers,
      });

      if (response.status === 401 && retryCount < this.maxRetries) {
        // Retry the request
        await new Promise((resolve) => setTimeout(resolve, this.retryDelay));
        return this.getWithRetry(apiDomain, endpoint, params, retryCount + 1);
      } else if (response.status === 401 && retryCount >= this.maxRetries) {
        throw new UnauthorizedError(
          "API is returning 401 after maximum retries. Likely due to a login issue. Try logging out and in again.",
        );
      } else if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      return response.json();
    } catch (error) {
      // this.setError(error);
    }
  }

  async getWithRetryFromAPI(
    apiDomain: string,
    endpoint: string,
    apiKey: string,
    params: any,
    retryCount: number = 0,
  ): Promise<any> {
    const urlParams = new URLSearchParams(params).toString();
    const headers = await getAPIHeaders(apiKey);
    const protocol = apiDomain.includes("localhost") ? "http" : "https";
    const url = `${protocol}://${apiDomain}/${endpoint}`;
    const response = await fetch(`${url}?${urlParams}`, {
      method: "GET",
      headers,
    });

    if (response.status === 401 && retryCount < this.maxRetries) {
      // Retry the request
      await new Promise((resolve) => setTimeout(resolve, this.retryDelay));
      return this.getWithRetry(apiDomain, endpoint, params, retryCount + 1);
    } else if (response.status === 401 && retryCount >= this.maxRetries) {
      throw new UnauthorizedError(
        "API is returning 401 after maximum retries. Likely due to a login issue. Try logging out and in again.",
      );
    } else if (response.status !== 200) {
      throw new Error(`Request failed with status code ${response.status}`);
    }

    return response.json();
  }

  async post(apiDomain: string, endpoint: string, data: any): Promise<any> {
    return this.postWithRetry(apiDomain, endpoint, data);
  }

  async get(apiDomain: string, endpoint: string, params: any): Promise<any> {
    return this.getWithRetry(apiDomain, endpoint, params);
  }

  async getFromAPI(apiDomain: string, endpoint: string, apiKey: string, params: any): Promise<any> {
    return this.getWithRetryFromAPI(apiDomain, endpoint, apiKey, params);
  }

  async checkCaseExists(agentKey: string, caseId: string): Promise<boolean> {
    return this.get("api", `cases/${agentKey}/${caseId}/exists`, {});
  }

  async getKybSchema(agentKey: string, caseId: string): Promise<any> {
    return this.get("api", `kyb-schema/${agentKey}/${caseId}`, {});
  }

  async getCustomerAgents(): Promise<any> {
    let env: string;
    if (import.meta.env.VITE_OVERRIDE_ENV) {
      env = import.meta.env.VITE_OVERRIDE_ENV;
    } else {
      env = import.meta.env.DEV ? "dev" : "prod";
    }
    let parchaDomain: string;
    switch (env) {
      case "selfserve":
        parchaDomain = "selfserve.parcha.ai";
        break;
      case "us1":
        parchaDomain = "us1.parcha.ai";
        break;
      case "dev":
        parchaDomain = "localhost:8001";
        break;
      case "staging":
        parchaDomain = "staging.parcha.ai";
        break;
      case "prod":
        parchaDomain = "demo.parcha.ai";
        break;
      case "demo":
        parchaDomain = "demo.parcha.ai";
        break;
      case "preview":
        parchaDomain = "us1.parcha.ai";
      default:
        console.error(`Unknown environment: ${env}`);
        parchaDomain = "localhost:8001";
        break;
    }
    const endpointsAndTenant: EndpointsAndTenant = await this.get(parchaDomain, "auth/getCustomerAgents", {});
    // change endpoint depending on environment
    const endpoints = endpointsAndTenant.endpoints;
    const tenantName = endpointsAndTenant.tenantName;
    // change endpoint depending on environment
    const typedEndpoints: Endpoints = endpoints.map((endpoint: any) => {
      const typedEndpoint = {
        agentKey: endpoint.agent_key,
        agentName: endpoint.agent_name,
        endpointUrl: endpoint.endpoint_url,
        default: endpoint.default,
        isPublic: endpoint.is_public,
        showInPreview: endpoint.show_in_preview,
      };
      return typedEndpoint;
    });
    switch (env) {
      case "selfserve":
        typedEndpoints.forEach((endpoint) => {
          endpoint.endpointUrl = "selfserve.parcha.ai";
        });
        break;
      // case "us1":
      //   typedEndpoints.forEach((endpoint) => {
      //     endpoint.endpointUrl = "us1.parcha.ai";
      //   });
      //   break;
      case "dev":
        typedEndpoints.forEach((endpoint) => {
          endpoint.endpointUrl = "localhost:8001";
        });
        break;
      case "staging":
        typedEndpoints.forEach((endpoint) => {
          endpoint.endpointUrl = "staging.parcha.ai";
        });
        break;
      case "demo":
        typedEndpoints.forEach((endpoint) => {
          endpoint.endpointUrl = "demo.parcha.ai";
        });
        break;
      case "prod":
        break;
      default:
        console.error(`Unknown environment: ${env}`);
        break;
    }
    if (env === "us1") {
      const filteredEndpoints = typedEndpoints.filter((endpoint) => endpoint.showInPreview);
      return { endpoints: filteredEndpoints, tenantName: tenantName };
    } else {
      return { endpoints: typedEndpoints, tenantName: tenantName };
    }
  }

  async getSimpleAgent(apiDomain: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getSimpleAgent", { agent_key: agentKey });
  }

  // function to send html fragments and return human readable text from the html
  async analyzeWebsite(
    apiDomain: string,
    agentKey: string,
    data: { url: string; content: string } | null,
  ): Promise<any> {
    return this.post(apiDomain, "auth/analyzeWebsite", { html: data, agent_key: agentKey });
  }

  async getApiKeys(apiDomain: string): Promise<any> {
    return this.get(apiDomain, "auth/getApiKeys", {});
  }

  async revokeApiKey(apiDomain: string, apiKey: string): Promise<any> {
    return this.post(apiDomain, "auth/revokeApiKey", { keyId: apiKey });
  }

  async createApiKey(apiDomain: string, keyName: string): Promise<any> {
    return this.post(apiDomain, "auth/createApiKey", { keyName: keyName });
  }

  // function to update the SOP in our web application
  async updateSOP(
    apiDomain: string,
    agentKey: string,
    workerKey?: string,
    workerName?: string,
    sop?: string,
    input_sop?: string,
    output_sop?: string,
    objective?: string,
    sop_react_object?: string,
  ): Promise<any> {
    return this.post(apiDomain, "auth/updateSOP", {
      agent_key: agentKey,
      worker_key: workerKey,
      worker_name: workerName,
      sop,
      input_sop,
      output_sop,
      objective,
      sop_react_object,
    });
  }

  // function to get the latest SOP fot an agent.
  async getAgentLatestSOP(apiDomain: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getAgentLatestSOP", { agent_key: agentKey }).then((data) => {
      return data.sop;
    });
  }

  // function to enqueue an agent to run a command
  async enqueueAgent(apiDomain: string, input_payload: AgentJobInput, agent_type: string = "kyb"): Promise<any> {
    let endpoint = "startKYBAgentJob";
    let payload: any = input_payload;
    if (agent_type === "kyc") {
      endpoint = "startKYCAgentJob";
    }
    if (input_payload.agent_key == "public-bdd") {
      endpoint = "startPublicJob";
    }
    return this.post(apiDomain, "auth/" + endpoint, payload);
  }

  async enqueueParallelAgent(
    apiDomain: string,
    input_payload: AgentJobInput,
    agent_type: string = "kyb",
  ): Promise<any> {
    let endpoint = "runParallelKYBAgentJob";
    let payload: any = input_payload;
    if (agent_type === "kyc") {
      endpoint = "runParallelKYCAgentJob";
      //#TODO: prooperly fix this
      payload = { agent_key: input_payload.agent_key, kyc_schema: input_payload.kyb_schema };
    }
    return this.post(apiDomain, "auth/" + endpoint, payload);
  }

  // function to understand if an agent is running or not.
  async getAgentRunStatus(apiDomain: string, jobId: string): Promise<any> {
    return this.post(apiDomain, "auth/getAgentRunStatus", { jobId: jobId });
  }

  // function to get a welcome message from an agent
  async getWelcomeMessage(apiDomain: string, agent: Agent, localTime: string): Promise<any> {
    return this.post(apiDomain, "auth/getWelcomeMessage", { agent: agent, localTime: localTime });
  }

  async getAgentConfig(apiDomain: string, agentId: string | undefined): Promise<AgentConfig> {
    return this.get(apiDomain, "auth/getAgent", { agent_key: agentId, n: 3 });
  }

  async getAgentJobHistory(
    apiDomain: string,
    agentKey: string,
    limit: number,
    offset: number,
  ): Promise<{ items: AgentRun[]; total: number; offset: number }> {
    // need to make this better, but for now this and BE logic protects from loading all jjobs for public agent as we do with premium ones.

    const endpoint = agentKey === "public-bdd" ? "auth/getPublicAgentJobHistory" : "auth/getAgentJobHistory";
    return this.get(apiDomain, endpoint, {
      agent_key: agentKey,
      limit,
      offset,
    });
  }

  async getWorkers(apiDomain: string): Promise<AgentRow[]> {
    return this.get(apiDomain, "admin/getWorkers", {});
  }

  async getKybSchemaFromPersona(apiDomain: string, caseId: string): Promise<any> {
    return this.get(apiDomain, "auth/kyb-schema-from-persona", { case_id: caseId });
  }

  async killAllWorkers(apiDomain: string): Promise<any> {
    return this.post(apiDomain, "admin/killAllWorkers", {});
  }

  async keepOnlyCompleteJobs(apiDomain: string, agent_key: string): Promise<any> {
    return this.post(apiDomain, "admin/deleteAllNotSuccesfulJobs", { agent_key });
  }

  async getAllJobs(apiDomain: string): Promise<any[]> {
    return this.get(apiDomain, "admin/getAllJobs", {});
  }

  async getTokenCounts(apiDomain: string): Promise<Record<string, MonthlyStats>> {
    return this.get(apiDomain, "auth/getTokenCounts", {});
  }

  async deleteJob(apiDomain: string, jobId: string): Promise<any> {
    return this.post(apiDomain, "auth/deleteJob", { job_id: jobId });
  }

  async getJobWithStatusMessages(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getJobById", {
      job_id: jobId,
      include_status_messages: true,
      include_check_result_ids: true,
    });
  }

  async getJobWithoutStatusMessages(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getJobById", {
      job_id: jobId,
      include_status_messages: false,
      include_check_result_ids: false,
    });
  }

  async enqueueFromCSV(apiDomain: string, file: File, agentId: string): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("agent_id", agentId);
    return this.post(apiDomain, "auth/enqueueFromCSV", formData);
  }

  async sendFeedback(
    apiDomain: string,
    jobId: string,
    key: string,
    score: boolean | number | undefined,
    value: string | boolean | number | undefined,
    data: any | undefined,
    comment: string | undefined,
  ): Promise<any> {
    return this.post(apiDomain, "auth/sendFeedback", {
      jobId, // backward compatibility
      job_id: jobId,
      key,
      score,
      value,
      data,
      comment,
    });
  }

  // function to fetch server sent events from the agent to the customer.
  async fetchAgentEvents(
    apiDomain: string,
    jobIds: string[],
    onopen: (res: Response) => Promise<void>,
    onmessage: (event: EventSourceMessage) => Promise<void>,
    onclose: () => void,
    onerror: (err: any) => number,
  ): Promise<any> {
    //const headers = await getHeaders();
    const protocol = apiDomain.includes("localhost") ? "http" : "https";
    const fetchData = async () => {
      await fetchEventSource(`${protocol}://${apiDomain}/auth/streamJobs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Authrorization": headers.get("Authorization") || "",
          Accept: "text/event-stream",
        },
        body: JSON.stringify({ jobIds: jobIds, refreshToken: getRefreshToken() }),
        onopen: onopen,
        onmessage: onmessage,
        onclose: onclose,
        onerror: onerror,
      });
    };
    return fetchData();
  }

  async getLatestFinalOutputFeedback(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getFinalOutputFeedback", { job_id: jobId, key: "final_output" });
  }

  async getFinalAnswerForJobs(apiDomain: string, agentKey: string, jobIds: string[]): Promise<any> {
    const joinedJobIds = jobIds.join(",");
    return this.get(apiDomain, "auth/getFinalAnswerForJobs", { agent_key: agentKey, job_ids: joinedJobIds });
  }

  async getWorkingURLs(apiDomain: string, selfAttestedData: any): Promise<any> {
    return this.post(apiDomain, "admin/getValidURLsForCase", selfAttestedData);
  }

  async getFeedbackInputsForJob(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getFeedbackInputsForJob", { jobId });
  }

  async getFinalPlanChecksOverviewForAgent(apiDomain: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getFinalPlanCheckOverviewData", { agent_key: agentKey });
  }

  async getChecksOverviewForAgent(apiDomain: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getChecksOverviewData", { agent_key: agentKey });
  }

  async getCheckFeedbackData(apiDomain: string, commandAgentId: string, commandId: string): Promise<any> {
    return this.get(apiDomain, "auth/getCheckFeedbackData", {
      command_id: commandId,
      command_agent_id: commandAgentId,
    });
  }

  async getAvailableChecks(apiDomain: string): Promise<any> {
    return this.get(apiDomain, "auth/getAvailableChecks", {});
  }

  async getCheckInfo(apiDomain: string, checkId: string): Promise<any> {
    return this.get(apiDomain, "auth/getCheckInfo", { check_id: checkId });
  }

  async runCheck(apiDomain: string, checkId: string, data: { [key: string]: any }): Promise<any> {
    return this.post(apiDomain, "auth/runCheck", { check_id: checkId, ...data });
  }

  async getCheckResults(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getCheckResults", { job_id: jobId });
  }

  async getLatestApplicationState(apiDomain: string, applicationId: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getLatestApplicationState", {
      application_id: applicationId,
      agent_key: agentKey,
    });
  }

  async getApplicants(apiDomain: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getApplicants", { agent_key: agentKey });
  }

  async getCheckResultFromJob(
    apiDomain: string,
    jobId: string,
    checkId: string,
    agentInstanceId: string,
    caseType: "kyb" | "kyc" | "entity",
  ): Promise<any> {
    return this.get(apiDomain, "auth/getCheckResultFromJob", {
      job_id: jobId,
      check_id: checkId,
      agent_instance_id: agentInstanceId,
      case_type: caseType,
    });
  }

  //logout from the extension and the service.
  async logout(apiDomain: string, refreshToken: string): Promise<any> {
    return this.post(apiDomain, "auth/logout", { refresh_token: refreshToken });
  }

  async uploadBase64Document(apiDomain: string, base64: string, documentName: string): Promise<any> {
    return this.post(apiDomain, "auth/uploadB64Document", { b64_document: base64, document_name: documentName });
  }

  async searchJobs(apiDomain: string, agent_key: string, query: string): Promise<any> {
    return this.get(apiDomain, "auth/searchJobs", { agent_key, query });
  }

  async updateApplicationStatus(apiDomain: string, applicationId: string, status: string): Promise<any> {
    return this.post(
      apiDomain,
      "auth/updateApplicationStatus?application_id=" + applicationId + "&status=" + status,
      {},
    );
  }

  async getSourceContents(apiDomain: string, sourceIds: string[], agentInstanceId: string): Promise<any> {
    if (sourceIds?.length > 0 && agentInstanceId) {
      return this.get(apiDomain, "auth/getSourceContents", {
        source_ids: sourceIds,
        agent_instance_id: agentInstanceId,
      });
    }
    return Promise.resolve(null);
  }

  async getJobsByCaseId(apiDomain: string, caseId: string, agentKey: string): Promise<any> {
    return this.get(apiDomain, "auth/getJobsByCaseId", {
      case_id: caseId,
      agent_key: agentKey,
    });
  }

  async getJobByIdFromAPI(apiDomain: string, jobId: string, apiKey: string): Promise<any> {
    return this.getFromAPI(apiDomain, "api/v1/getJobById", apiKey, {
      job_id: jobId,
      include_check_results: true,
      include_status_messages: true,
    });
  }

  async getJobsByCaseIdFromAPI(apiDomain: string, caseId: string, agentKey: string, apiKey: string): Promise<any> {
    return this.getFromAPI(apiDomain, "api/v1/getJobsByCaseId", apiKey, {
      case_id: caseId,
      agent_key: agentKey,
      include_check_results: true,
      include_status_messages: false,
    });
  }

  async getSourceContentsFromAPI(
    apiDomain: string,
    apiKey: string,
    sourceId: string,
    agentInstanceId: string,
  ): Promise<any> {
    return this.getFromAPI(apiDomain, "api/v1/getSourceContents", apiKey, {
      source_id: sourceId,
      agent_instance_id: agentInstanceId,
    });
  }

  async getJobMetadata(apiDomain: string, jobId: string): Promise<any> {
    return this.get(apiDomain, "auth/getJobMetadata", { job_id: jobId });
  }
}

export default ParchaApi;
