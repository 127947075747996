import { ImageSearchRounded, WarningRounded } from "@mui/icons-material";
import DocumentValue from "./DocumentValue";
import PDFViewer from "../V2_Home_Page/PDFViewer";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twJoin } from "tailwind-merge";

export type HighlightArea = {
  pageIndex: number;
  height: number;
  width: number;
  top: number;
  left: number;
  triggeredKeyword: string;
  reason: string;
  contextText: string;
};

export type MatchingEntry = {
  contextText: string;
  triggeredKeyword: string;
  reason: string;
  pageNumber: number;
  highlightArea: HighlightArea;
};

type UDAPDocumentCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  documentTitle: string;
  documentUrl: string | null;
  matchingEntries: MatchingEntry[];
  pageCount: number;
};

const UDAPDocumentCard: React.FC<UDAPDocumentCardProps> = ({
  level,
  documentTitle,
  documentUrl,
  matchingEntries,
  pageCount,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedHighlightArea, setSelectedHighlightArea] = useState<HighlightArea | null>(null);

  const makeBold = (keyword: string, text: string) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    return `&quot;${text.replace(regex, "<b>$1</b>")}&quot;`;
  };

  const highlights = matchingEntries.map((entry) => entry.highlightArea);

  const handleModalOpen = (highlightArea: HighlightArea) => {
    setSelectedHighlightArea(highlightArea);
  };

  useEffect(() => {
    if (selectedHighlightArea) {
      setShowModal(true);
    }
  }, [selectedHighlightArea]);

  return (
    <div
      className={twJoin(
        "h-auto text-xs",
        level === "failure" ? "bg-white rounded-xl border border-solid border-slate-300 p-4" : "",
      )}
    >
      {level === "failure" ? (
        <div className="flex flex-col lg:flex-row items-center justify-between gap-2">
          <div className="flex flex-wrap gap-x-2">
            <DocumentValue fileName={documentTitle} url={documentUrl || ""} />
            <span className="text-slate-500">{`${pageCount} pages`}</span>
          </div>
          <div className="flex lg:items-center gap-x-1 px-2 py-1 border border-[#DC2626]/10 text-red-700 bg-red-50 rounded-lg">
            <WarningRounded sx={{ fontSize: "1rem" }} />
            <span>{`${matchingEntries.length} terms to resolve`}</span>
          </div>
        </div>
      ) : null}

      <div className="w-full flex flex-col gap-y-2 mt-3 flex-nowrap">
        {matchingEntries.map((entry, index) => (
          <div
            key={index}
            className={twJoin(
              "flex flex-col lg:flex-row lg:justify-between p-2 lg:p-4 border border-solid rounded-2xl gap-x-2 gap-y-2",
              level === "success" ? "border-green-500" : level === "failure" ? "border-red-600" : "border-slate-300",
            )}
          >
            <div className="flex flex-col gap-y-3 w-[80%]">
              <div className="w-fit max-w-[12rem] px-2 py-1 text-slate-700 rounded-full bg-slate-50 border border-solid border-[#334155]/20">
                <p className="font-medium truncate">{entry.triggeredKeyword}</p>
              </div>
              <p
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: makeBold(entry.triggeredKeyword, entry.contextText),
                }}
              />
              <p>{entry.reason}</p>
            </div>
            {entry.highlightArea ? (
              <div
                role="button"
                onClick={() => {
                  handleModalOpen(entry.highlightArea);
                }}
                className="flex flex-col items-center bg-slate-100 rounded-lg pt-0.5 px-0.5 gap-y-1 h-fit pb-1.5"
              >
                <div className="py-2 px-4 border-2 border-solid flex items-center justify-center border-white rounded-md">
                  <ImageSearchRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
                </div>
                {entry.pageNumber ? <span className="text-brand-purple">page {entry.pageNumber}</span> : null}
              </div>
            ) : null}
          </div>
        ))}
      </div>

      {selectedHighlightArea && (
        <PreviewModal
          fileUrl={documentUrl || ""}
          highlightedAreas={highlights}
          setShowModal={setShowModal}
          showModal={showModal}
          selectedArea={selectedHighlightArea}
        />
      )}
    </div>
  );
};

const PreviewModal = ({
  showModal,
  setShowModal,
  fileUrl,
  highlightedAreas,
  selectedArea,
}: {
  showModal: boolean;
  fileUrl: string;
  highlightedAreas: HighlightArea[];
  setShowModal: (value: boolean) => void;
  selectedArea: HighlightArea;
}) => {
  return (
    <div className="w-full h-full z-20">
      <Transition.Root show={showModal} as={Fragment} appear>
        <Dialog as="div" className="relative z-20" onClose={setShowModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white bg-opacity-95 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 inset-x-0 z-20 w-screen h-screen overflow-y-auto p-2 sm:p-4 md:p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto h-[calc(100vh-4rem)] w-full transform overflow-hidden border border-solid border-slate-300 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="h-full">
                  <PDFViewer fileUrl={fileUrl} highlightedAreas={highlightedAreas} selectedArea={selectedArea} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default UDAPDocumentCard;
