import { TrackChangesRounded } from "@mui/icons-material";

const CheckSectionStatusMessage = ({ message }: { message: string }) => {
  return (
    <div className="text-xs bg-gradient-to-r from-[#E0E7FF] via-[#E0E7FF]/100 to-[#E0E7FF]/0 p-3 rounded-md w-full left-0 border border-gray-100">
      <div className="flex gap-x-2 h-fit items-start">
        <TrackChangesRounded sx={{ fontSize: "1rem" }} className="text-brand-purple z-20" />
        <span className="text-slate-500">{message}</span>
      </div>
    </div>
  );
};

export default CheckSectionStatusMessage;
