import { twJoin } from "tailwind-merge";
import WrappedValue from "./WrappedValue";

type TINCheckCardProps = {
  level: "normal" | "success" | "failure";
  businessName: string;
  tin: string;
};

export default function TINCheckCard({ level, businessName, tin }: TINCheckCardProps) {
  return businessName && businessName.length && tin && tin.length ? (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm text-slate-900 mt-2",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "rounded-t-md p-4 border-b border-slate-300",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-600"
            : level === "failure"
              ? "bg-red-100 border-b-red-600"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="font-medium uppercase">{businessName}</div>
      </div>
      <div className="flex flex-col gap-4 py-5 px-4 text-xs">
        <div className="flex items-center gap-2">
          <span className="font-medium">TIN: </span>
          <WrappedValue text={tin} level={level} />
        </div>
      </div>
    </div>
  ) : null;
}
