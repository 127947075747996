import React from "react";

const DenyBadge = ({ children }: { children?: JSX.Element }) => {
  return (
    <span className="h-6 inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
      {children || "Deny"}
    </span>
  );
};

export default DenyBadge;
