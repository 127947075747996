import { LocalFlorist, WarningRounded } from "@mui/icons-material";
import { UIDocument, CannabisLicenseDocument } from "@/types";
import DocumentValue from "./DocumentValue";
import { tryToParseDate } from "@/utils";
import { twJoin } from "tailwind-merge";
import InlineCollapsibleText from "./InlineCollapsibleText";
import WrappedValue from "./WrappedValue";

type CannabisLicenseCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  verifiedBusinessName: string;
  verifiedLicenseNumber: string;
  documentDate: string;
  validDocuments: (UIDocument & CannabisLicenseDocument)[];
  invalidDocuments?: (UIDocument & CannabisLicenseDocument)[];
};

const CannabisLicenseCard = ({
  level,
  verifiedBusinessName,
  verifiedLicenseNumber,
  documentDate,
  validDocuments,
  invalidDocuments,
}: CannabisLicenseCardProps) => {
  if (!(validDocuments?.length || invalidDocuments?.length)) return null;

  return (
    <div
      className={twJoin(
        "relative border w-[272px] left-3.5",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
      )}
    >
      <div
        className={twJoin(
          "absolute border-l -rotate-45 w-20 h-20 z-10 top-[-40.5px] left-[230px] bg-white",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      ></div>
      <div className="flex flex-col gap-y-4 z-0 p-5 text-xs">
        <div className="flex items-center gap-1 text-[#CECECE]">
          <LocalFlorist sx={{ fontSize: "1rem" }} />
          <h2 className="uppercase">Cannabis License</h2>
        </div>
        <h3 className="uppercase">{verifiedBusinessName}</h3>
        <div>
          <div className="flex items-center gap-2">
            <span className="font-medium">License Number: </span>
            {validDocuments?.length || level !== "failure" ? (
              <WrappedValue text={verifiedLicenseNumber || validDocuments[0].licenseNumber} level={level} />
            ) : (
              invalidDocuments && invalidDocuments.length > 0 && (
                <WrappedValue text={invalidDocuments[0].licenseNumber || ''} level={level} />
              )
            )}
          </div>
          {documentDate && (
            <div>
              <span className="font-semibold">Document Date: </span>
              <span>{tryToParseDate(documentDate)}</span>
            </div>
          )}
        </div>
        {validDocuments?.length ? (
          <div>
            <span className="font-semibold">License Documents:</span>
            <div className="w-full flex flex-col mt-1">
              {validDocuments.map((document) => (
                <div key={`${document.fileName}${document.url}`} className="flex flex-col gap-y-2">
                  <DocumentValue fileName={document.fileName} url={document.url} maxWidth={150} />
                  {document.expirationDate && (
                    <div>
                      <span className="font-semibold">Expiration Date: </span>
                      <span>{tryToParseDate(document.expirationDate)}</span>
                    </div>
                  )}
                  {document.fraudVerificationData &&
                    document.fraudVerificationData.verification_data
                      .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
                      .map((verificationDataEntry) => (
                        <div key={verificationDataEntry.description} className="flex flex-col gap-y-3">
                          <div className="flex gap-x-2 text-yellow-700">
                            <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                            <p className="text-xs">
                              <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                            </p>
                          </div>
                        </div>
                      ))}
                  {document.summary && (
                    <div className="flex flex-col gap-y-1 mb-4">
                      <p className="font-semibold">Summary: </p>
                      <InlineCollapsibleText text={document.summary} />
                    </div>
                  )}
                  {document.visualInspection && (
                    <div className="flex flex-col gap-y-1 mb-4">
                      <p className="font-semibold">Visual Inspection: </p>
                      <InlineCollapsibleText text={document.visualInspection} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {invalidDocuments?.length ? (
          <div>
            <span className="font-semibold">Invalid License Documents:</span>
            <div className="w-full flex flex-col mt-1">
              {invalidDocuments.map((document) => (
                <div key={`${document.fileName}${document.url}`} className="flex flex-col gap-y-2">
                  <DocumentValue fileName={document.fileName} url={document.url} maxWidth={150} />
                  {document.expirationDate && (
                    <div>
                      <span className="font-semibold">Expiration Date: </span>
                      <span>{tryToParseDate(document.expirationDate)}</span>
                    </div>
                  )}
                  {document.fraudVerificationData &&
                    document.fraudVerificationData.verification_data
                      .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
                      .map((verificationDataEntry) => (
                        <div key={verificationDataEntry.description} className="flex flex-col gap-y-3">
                          <div className="flex gap-x-2 text-yellow-700">
                            <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                            <p className="text-xs">
                              <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                            </p>
                          </div>
                        </div>
                      ))}
                  {document.summary && (
                    <div className="flex flex-col gap-y-1 mb-4">
                      <p className="font-semibold">Summary</p>
                      <InlineCollapsibleText text={document.summary} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CannabisLicenseCard;