import React, { ReactNode } from "react";
import clsx from "clsx";

const Alert: React.FC<{ level: "success" | "failure" | "caution" | "normal" | "partial"; message: ReactNode }> = ({
  level,
  message,
}) => {
  if (!message) return null;

  return (
    <div
      className={clsx("relative z-20 rounded-lg p-4 w-full break-words", {
        "bg-red-50": level === "failure",
        "bg-green-50": level === "success",
        "bg-yellow-50": level === "caution",
        "bg-orange-50": level === "partial",
      })}
    >
      <div
        className={clsx("text-xs", {
          "text-red-700": level === "failure",
          "text-green-700": level === "success",
          "text-yellow-700": level === "caution",
          "text-orange-700": level === "partial",
        })}
      >
        {message}
      </div>
    </div>
  );
};

export default Alert;
