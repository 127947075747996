import { AddressValueProps } from "./AddressValue";
import DocumentValue from "./DocumentValue";
import { snakeCaseToProperCase, tryToParseDate } from "@/utils";
import { twJoin } from "tailwind-merge";
import { Pin } from "@mui/icons-material";
import WrappedValue from "./WrappedValue";
import InlineCollapsibleText from "./InlineCollapsibleText";

type EINCardProps = {
  addressOfOperation: AddressValueProps;
  businessName: string;
  documents: {
    document: {
      file_name: string;
      url: string;
    };
    date: string;
    document_type: string;
    summary: string;
  }[];
  einNumber: string;
  level: "success" | "failure" | "normal" | undefined;
};

const EINCard = ({
  addressOfOperation = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    countryCode: null,
    postalCode: null,
  },
  businessName,
  documents,
  einNumber,
  level,
}: EINCardProps) => {
  if (!documents?.length) return null;

  const { street1, street2, city, state, countryCode, postalCode } = addressOfOperation;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  return (
    <div
      className={twJoin(
        "relative border w-[272px] left-3.5 text-xs",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
      )}
    >
      <div
        className={twJoin(
          "absolute border-l -rotate-45 w-20 h-20 z-10 top-[-40.5px] left-[230px] bg-white",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      ></div>
      <div className="flex flex-col gap-y-4 z-0 p-5">
        <div className="flex items-center gap-1 text-[#CECECE]">
          <Pin sx={{ fontSize: "1rem" }} />
          <h2 className="uppercase">EIN</h2>
        </div>
        <h3 className="text-sm font-semibold">{businessName}</h3>

        {einNumber && (
          <div className="flex gap-x-2 items-center">
            <span className="font-semibold">EIN: </span>
            <WrappedValue text={einNumber} level="normal" />
          </div>
        )}

        {addressOfOperation &&
          !Object.entries(addressOfOperation).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Address of Operation</span>
              <p>{addressLine1}</p>
              <p>{addressLine2}</p>
            </div>
          )}

        {documents?.length ? (
          <div>
            <span className="font-semibold">Documents:</span>
            <div className="w-full flex flex-col gap-y-3 mt-1">
              {documents.map((entry) => (
                <div className="flex flex-col gap-y-2">
                  <DocumentValue fileName={entry.document.file_name} url={entry.document.url} maxWidth={250} />
                  <span>
                    <span className="font-semibold">Document Date:</span> {tryToParseDate(entry.date)}
                  </span>
                  <span>
                    <span className="font-semibold">Document Type:</span> {entry.document_type}
                  </span>
                  <span>
                    <span className="font-semibold">Document Summary:</span>{" "}
                    <InlineCollapsibleText text={entry.summary} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EINCard;
