import { toTitleCase } from "@/utils";
import { BusinessRounded, PersonRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";

const OpenCorporatesOfficersCard = ({
  name,
  companies,
  level,
  tag,
  tagLevel,
}: {
  name: string;
  companies: {
    name: string;
    url?: string;
    position: string[];
  }[];
  level: "success" | "failure" | "normal";
  tag: string;
  tagLevel: "success" | "failure" | "normal";
}) => {
  const borderColor =
    level === "success" ? "border-green-300" : level === "failure" ? "border-red-300" : "border-slate-300";
  const backgroundColor = level === "success" ? "bg-[#DCFCE7]" : level === "failure" ? "bg-red-100" : "bg-slate-100";

  return (
    <div className={twJoin("flex flex-col border rounded", borderColor)}>
      <div className={twJoin("text-sm p-4 text-slate-900 border-b", borderColor, backgroundColor)}>
        <div className="flex justify-between">
          <div className="flex gap-x-1 text-sm items-center">
            <PersonRounded sx={{ fontSize: "1rem" }} />
            <span className="font-medium">{name}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 px-4 py-5">
        {companies.length > 0 ? (
          companies.map((company) => (
            <CompanyCard
              name={company.name}
              url={company.url || undefined}
              positions={company.position}
              tag={tag}
              tagLevel={tagLevel}
            />
          ))
        ) : (
          <CompanyCard
            name="No business associations were found"
            positions={[]}
            tag={tag}
            tagLevel={tagLevel}
            url={null}
          />
        )}
      </div>
    </div>
  );
};

export default OpenCorporatesOfficersCard;

const CompanyCard = ({
  name,
  url,
  positions,
  tag,
  tagLevel,
}: {
  name: string;
  url: string | null | undefined;
  positions: string[];
  tag?: string | null;
  tagLevel?: "success" | "failure" | "normal";
}) => {
  return (
    <div className="border border-slate-300 rounded-lg p-3 text-xs flex flex-col gap-y-3 text-xs">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-x-2 text-sm">
          <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
          {url && url.length > 0 ? (
            <a href={url} target="_blank" rel="noreferrer noopener" className="text-brand-purple hover:underline">
              {name}
            </a>
          ) : (
            <span className="text-slate-500">{name}</span>
          )}
        </div>
        {tag && tag.length > 0 && (
          <div
            className={twJoin(
              "px-2 py-1 border rounded-md",
              tagLevel === "success"
                ? "text-green-600 bg-green-50 border-[#16A34A]/20"
                : tagLevel === "failure"
                  ? "bg-red-50 text-red-600 border-red-300"
                  : "bg-slate-50 text-slate-700 border-slate-300",
            )}
          >
            {tag}
          </div>
        )}
      </div>
      {positions && positions.length > 0 && (
        <div className="flex flex-wrap gap-1">
          {positions.map((position) => (
            <span className="bg-slate-50 font-medium text-slate-700 px-2 py-1 rounded-full border border-[#334155]/20">
              {toTitleCase(position)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
