import { HouseRounded, Inventory2Rounded, BusinessRounded, Mail, NotListedLocationRounded } from "@mui/icons-material";
import AddressValue, { AddressValueProps } from "./AddressValue";
import { twJoin } from "tailwind-merge";

type AddressVerificationProps = {
  level: "success" | "failure" | "normal" | undefined;
  address: AddressValueProps;
  addressType: "PO Box" | "Residential" | "Business" | "Address not valid";
};

const AddressVerificationCard = ({ address, addressType, level }: AddressVerificationProps) => {
  const renderAddressTypeValue = () => {
    const className = twJoin("flex gap-x-1 items-center", level === "failure" ? "text-red-700" : "text-slate-700");
    const iconClassName = twJoin(level === "failure" ? "text-red-600" : "text-slate-600");

    switch (addressType) {
      case "PO Box":
        return (
          <div className={className}>
            <Inventory2Rounded sx={{ fontSize: "1rem" }} className={iconClassName} />
            <span>PO Box</span>
          </div>
        );
      case "Business":
        return (
          <div className={className}>
            <BusinessRounded sx={{ fontSize: "1rem" }} className={iconClassName} />
            <span>Commercial Address</span>
          </div>
        );
      case "Residential":
        return (
          <div className={className}>
            <HouseRounded sx={{ fontSize: "1rem" }} className={iconClassName} />
            <span>Residential Address</span>
          </div>
        );
      default:
        return (
          <div className={className}>
            <NotListedLocationRounded sx={{ fontSize: "1rem" }} className={iconClassName} />
            <span>Address not valid</span>
          </div>
        );
    }
  };

  let gMapsAddress;
  const isAddressValid = !Object.entries(address).every(
    ([k, v]) => k === "type" || v === null || v === "unverified" || v === "",
  );

  if (isAddressValid) {
    gMapsAddress = encodeURIComponent(
      [address.street1, address.street2, address.city, address.state, address.countryCode, address.postalCode]
        .filter((part) => part && part !== null && part.trim() !== "")
        .join(" ")
        .replaceAll(" ", "+"),
    );
  }

  return (
    <div className="overflow-auto w-full h-full p-5 text-xs border border-slate-200 rounded-md">
      <div className="w-full h-full flex flex-col gap-y-4">
        <div className="w-full max-h-full max-w-[400px]">
          {isAddressValid ? (
            <iframe
              height="320"
              className="w-full"
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes&q=${gMapsAddress}`}
              referrerPolicy="no-referrer-when-downgrade"
            />
          ) : (
            <div className="w-full h-[20rem] bg-slate-100 flex items-center justify-center rounded-lg">
              <div className="flex flex-col gap-y-2 items-center text-slate-500">
                <NotListedLocationRounded sx={{ fontSize: "2.5rem" }} />
                <span className="font-semibold">{!isAddressValid ? "Address not found..." : addressType}</span>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-x-6">
          {isAddressValid ? <AddressValue {...address} /> : <span className="font-medium">No Address Available</span>}
          <div className="col-start-2 flex flex-col">
            <span className="font-medium">Address Type:</span>
            <span>{renderAddressTypeValue()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddressVerificationCard;
