import { twJoin } from "tailwind-merge";
import { UIDocument, ProofOfAddressDocument } from "@/types";
import AddressValue, { AddressValueProps } from "./AddressValue";
import DocumentValue from "./DocumentValue";
import { snakeCaseToProperCase, tryToParseDate } from "@/utils";
import { WarningRounded } from "@mui/icons-material";

type KYBProofOfAddressCardProps = {
  level: "normal" | "success" | "failure";
  businessName: string;
  industry?: string;
  address: AddressValueProps;
  proofOfAddressDocuments: (UIDocument & ProofOfAddressDocument)[];
};

const KYBProofOfAddressCard = ({
  level,
  address,
  businessName,
  industry,
  proofOfAddressDocuments,
}: KYBProofOfAddressCardProps) => {
  if (!businessName || !businessName.length) {
    return null;
  }

  const { street1, street2, city, state, countryCode, postalCode } = address;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? `, ${countryCode}` : ""}${
    postalCode ? `, ${postalCode}` : ""
  }`;
  const gMapsAddress = encodeURIComponent(addressLine1 + " " + addressLine2).replaceAll(" ", "+");

  return (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm text-slate-900 mt-2",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "flex flex-col rounded-t-md p-4 border-b border-slate-300",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-600"
            : level === "failure"
              ? "bg-red-100 border-b-red-600"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="font-medium text-base uppercase">{businessName}</div>
        {industry && <span>{industry}</span>}
      </div>
      <div className="flex flex-col gap-4 py-5 px-4">
        {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
          <>
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${gMapsAddress}&zoom=13&size=1200x600&maptype=roadmap&markers=color:red%7C${gMapsAddress}&key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes`}
              className="place-self-center object-cover w-full max-w-[400px] h-[7.5rem] rounded-md "
            />
            <AddressValue {...address} />
          </>
        )}
        {proofOfAddressDocuments?.length > 0 && (
          <div className="flex flex-col text-xs">
            <span className="font-medium">Proof of Address Documents: </span>
            <div>
              {proofOfAddressDocuments.map((document) => (
                <div key={document.fileName} className="flex flex-col gap-2">
                  <DocumentValue
                    key={`${document.fileName}${document.url}`}
                    fileName={document.fileName}
                    url={document.url}
                    maxWidth={250}
                  />
                  {document.fraudVerificationData &&
                    document.fraudVerificationData.verification_data
                      .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
                      .map((verificationDataEntry) => (
                        <div className="flex flex-col gap-y-3">
                          <div className="flex gap-x-2 text-yellow-700">
                            <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                            <p className="text-xs">
                              <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                            </p>
                          </div>
                        </div>
                      ))}
                  {document.documentType && (
                    <div className="flex flex-col text-xs">
                      <div className="font-medium">Document Type:</div>
                      <span>{snakeCaseToProperCase(document.documentType)}</span>
                    </div>
                  )}
                  {document.documentDate && (
                    <div className="flex flex-col text-xs">
                      <div className="font-medium">Document Date:</div>
                      <span>{tryToParseDate(document.documentDate.replaceAll("-", "/"))}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KYBProofOfAddressCard;
