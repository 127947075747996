import { snakeCaseToProperCase } from "@/utils";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CancelRounded from "@mui/icons-material/CancelRounded";
import WebsiteCard from "./WebsiteCard";
import WrappedValue from "./WrappedValue";

type WebsiteResearchCardProps = {
  domain: string;
  isRelatedUrl: boolean;
  isValidUrl: boolean;
  screenshotUrl: string | null;
  title: string;
  url: string;
  websiteType: string;
  websiteSummary: string;
};

const WebsiResearchCard = ({
  domain,
  isRelatedUrl,
  isValidUrl,
  screenshotUrl,
  title,
  url,
  websiteType,
  websiteSummary,
}: WebsiteResearchCardProps) => {
  return (
    <div className="flex flex-col p-4 gap-y-5 w-full text-xs border border-slate-200 rounded-lg overflow-hidden">
      {isValidUrl ? (
        <>
          <div className="flex items-center justify-between gap-x-5">
            <a href={url} className="text-brand-purple font-semibold truncate">
              {domain}
            </a>
            {websiteType && (
              <span>
                <WrappedValue text={snakeCaseToProperCase(websiteType)} />
              </span>
            )}
          </div>

          <WebsiteCard websiteLabel={title} websiteUrl={url} screenshotUrl={screenshotUrl} />
          <div>
            <div className="flex gap-x-1">
              <CheckCircle sx={{ fontSize: "1rem" }} className="text-green-600" />
              <span className="text-green-700">We were able to successfully scrape the website</span>
            </div>
            <div className="flex gap-x-1">
              {isRelatedUrl ? (
                <>
                  <CheckCircle sx={{ fontSize: "1rem" }} className="text-green-600" />
                  <span className="text-green-700">The website is directly related to the business.</span>
                </>
              ) : (
                <>
                  <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
                  <span className="text-red-700">The website is not directly related to the business.</span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Website Summary</span>
            <p className="text-slate-700">{websiteSummary}</p>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-x-5 items-center justify-between">
            <span className="text-slate-700 truncate w-3/4">{url}</span>
            <span>
              <WrappedValue text="Website Not Found" level="failure" />
            </span>
          </div>
          <div className="flex gap-x-2">
            <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
            <span className="text-red-700">The website was unreachable at the time of the analysis.</span>
          </div>
        </>
      )}
    </div>
  );
};

export default WebsiResearchCard;
