import { Alert, Snackbar } from "@mui/material";
import { ErrorContext } from "@parcha/contexts/ErrorContext";
import React from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

interface ErrorContextType {
  error: Error | null;
  setError: (error: Error | null) => void;
}

class ErrorBoundary extends React.Component<Props, State> {
  static contextType = ErrorContext;
  declare context: ErrorContextType; // Add this line
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    this.context.setError(error);
  }

  handleClose = () => {
    this.setState({ hasError: false });
    this.context.setError(null);
  };

  render() {
    this.context.error && console.error(this.context.error);
    return (
      <>
        {this.props.children}

        {this.context.error && (
          <Snackbar
            open={!!this.context.error}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            className="w-full md:w-[33%]"
          >
            <Alert onClose={this.handleClose} severity="error" sx={{ width: "100%" }}>
              Something went wrong. Refresh this page and report to our team if this issue persists.
              <br />
              {this.context.error && this.context.error.toString()}
              <br />
              <details style={{ whiteSpace: "pre-wrap" }}>{this.context.error.stack}</details>
              <a
                target="_blank"
                href={`mailto:support@parcha.ai?subject=Error Report&body=${this.context.error?.toString()}\n\n${this
                  .context.error?.stack}`}
                rel="noreferrer"
              >
                Report this to our team at support@parcha.ai
              </a>
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

export default ErrorBoundary;
