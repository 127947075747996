import { twJoin } from "tailwind-merge";
import Alert from "./Alert";

type HighRiskIndustryCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  industry: string;
  businessActivity: string;
  potentialHits?: string[];
  hits?: string[];
  evidenceMessage?: string;
};

const HighRiskIndustryCard = ({
  level,
  industry,
  businessActivity,
  hits,
  potentialHits,
  evidenceMessage,
}: HighRiskIndustryCardProps) => {
  if (!industry || !industry?.length) return null;

  return (
    <div className="w-full text-xs">
      <div
        className={twJoin(
          "flex flex-col gap-2 border text-slate-900 p-4 rounded-lg",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      >
        <div className="flex gap-1 flex-wrap">
          <span className="font-medium">Industry:</span>
          <span>{industry}</span>
        </div>
        {businessActivity && (
          <div className="flex gap-1 flex-wrap">
            <span className="font-medium">Business Activity:</span>
            <span>{businessActivity}</span>
          </div>
        )}
        {potentialHits && potentialHits.length > 0 && (
          <div className="flex gap-1 flex-wrap">
            <span className="font-medium">Potential hits:</span>
            <span className="text-slate-500">{potentialHits.join(" / ")}</span>
          </div>
        )}
        {hits && hits.length > 0 && (
          <div
            className={twJoin(
              "flex gap-1 flex-wrap",
              level === "success" ? "text-green-600" : level === "failure" ? "text-red-700" : "",
            )}
          >
            <span className="font-medium">Confirmed hit:</span>
            <span>{hits.join(" / ")}</span>
          </div>
        )}
        {evidenceMessage && level ? (
          <div className="mt-2">
            <Alert level={level} message={evidenceMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HighRiskIndustryCard;
