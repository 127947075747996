export const COLORS = {
  parchaBrandPurple: "#5D5FEF",
  parchaUtilError: "#F56565",
  parchaBrandYellow: "#FEFB92",
  parchaBlack: "#2A303C",
  parchaLight1: "#F5F6F8",
  parchaLight3: "#E9EEF4",
  parchaLight5: "#6C727F",
  parchaError: "#F56565",
  parchaPositive: "#38A169",
  parchaLightPurple: "#E0E7FF",
};
