import Alert from "./Alert";
import { alpha3ToAlpha2Dictionary } from "@/data/alpha2ToAlpha3Dictionary";

type PEPCardProps = {
  countryCode: string | null;
  matchedNames: string[] | null | undefined;
  sanctionListName: string;
  url: string;
  level: "success" | "failure" | "normal" | undefined;
  description: string | null | undefined;
};

const PEPCard = ({ countryCode, matchedNames, sanctionListName, url, level, description }: PEPCardProps) => {
  if ((!matchedNames || !matchedNames.length) && (!sanctionListName || !sanctionListName.length)) {
    return null;
  }

  const iconCode =
    countryCode && countryCode.length === 3
      ? alpha3ToAlpha2Dictionary[countryCode.toUpperCase()]
      : countryCode && countryCode.length === 2
        ? countryCode
        : null;

  return (
    <div className="w-full flex flex-col border text-slate-900 border-slate-200 p-4 rounded-lg">
      {iconCode && (
        <div className="flex gap-2">
          <div className="flex items-center justify-center">
            <span className={`fi fi-${iconCode.toLowerCase()} drop-shadow rounded-full !bg-cover !w-4 !h-4`}></span>
          </div>
          <div className="text-sm">{iconCode.toUpperCase()}</div>
        </div>
      )}
      {sanctionListName ? (
        url ? (
          <a
            className="text-brand-purple hover:underline uppercase"
            href={url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {sanctionListName.replaceAll("_", " ")}
          </a>
        ) : (
          <span className="uppercase">{sanctionListName.replaceAll("_", " ")}</span>
        )
      ) : null}
      {matchedNames?.length && (
        <div className="flex flex-wrap gap-1 mt-4 text-xs">
          <span className="font-semibold">Potential Hits:</span>
          <span>{matchedNames.join(", ")}</span>
        </div>
      )}
      {level && description ? (
        <div className="mt-2">
          <Alert level={level} message={description} />
        </div>
      ) : null}
    </div>
  );
};

export default PEPCard;
