import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

interface BorderLinearProgressProps {
  c?: string;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(({ theme, c }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: c || theme.palette.primary.main,
  },
}));

export default BorderLinearProgress;
