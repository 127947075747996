// @ts-nocheck
import { BusinessRounded, PeopleRounded, SchoolRounded } from "@mui/icons-material";
import linkedInLogoIcon from "../../assets/linked_in_logo.png";
import Alert from "./Alert";
import { useEffect, useState } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const BusinessOwnersFindings = ({ apiKey, checkResult, hideHeader = false, endpointUrl }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PeopleRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">Business Owners</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <div className="place-self-center">
          <FindingsLoadingIndicator
            message={`Running Business Owners check${
              checkResult.metadata?.business_name ? ` on ${checkResult.metadata.business_name} ` : ""
            }...`}
          />
        </div>
      </div>
    );
  }

  if (!checkResult.check_result) {
    return null;
  }

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {!hideHeader && Header}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
        {checkResult.check_result.business_owner_matches?.map((owner, index) => (
          <BusinessOwnerEntry
            key={index}
            apiKey={apiKey}
            data={owner}
            endpointUrl={endpointUrl}
            agentInstanceId={checkResult.agentInstanceId}
          />
        ))}
      </div>
    </div>
  );
};

const BusinessOwnerEntry = ({ apiKey, agentInstanceId, hideHeader = false, data, endpointUrl }) => {
  const parchaApi = useParchaApi();
  const [sourceData, setSourceData] = useState(null);

  const fetchSourceData = async () => {
    if (apiKey) {
      return parchaApi.getSourceContentsFromAPI(endpointUrl, apiKey, data.source_ids, agentInstanceId);
    }

    return parchaApi.getSourceContents(endpointUrl, data.source_ids, agentInstanceId);
  };

  useEffect(() => {
    if (data?.source_ids?.length > 0) {
      fetchSourceData().then((res) => {
        setSourceData(res);
      });
    }
  }, [data.source_ids]);

  let parsedData = null;

  const doc = sourceData?.docs.length > 0 ? sourceData?.docs[0] : null;

  if (doc?.document_metadata?.document_type === "li_profiles") {
    try {
      parsedData = JSON.parse(doc.text);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="p-5 truncate text-slate-700 flex flex-col gap-y-5 bg-slate-50 rounded-lg">
      <div className="flex flex-col">
        <span className="text-base font-semibold">{data.name}</span>
        {(data.title || data.business_name) && (
          <span className="text-sm">
            {data.title}
            {data.business_name ? ` at ${data.business_name}` : null}
          </span>
        )}
      </div>
      {sourceData && (
        <div className="flex items-center gap-x-2">
          {doc?.document_metadata?.document_type === "li_profiles" && (
            <>
              <img src={linkedInLogoIcon} alt="LinkedIn Logo" className="w-4 h-4" />
              <a
                href={doc.document_metadata.url}
                target="_blank"
                rel="noreferrer"
                className="truncate text-brand-purple"
              >
                {doc.document_metadata.url}
              </a>
            </>
          )}
          {doc?.document_metadata?.url && (
            <a href={doc.document_metadata.url} target="_blank" rel="noreferrer" className="truncate text-brand-purple">
              {doc.document_metadata.url}
            </a>
          )}
        </div>
      )}
      {parsedData && (
        <>
          {parsedData.EXPERIENCE.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <span className="text-xs font-semibold">Recent Experience</span>
              <div className="flex flex-col gap-y-2">
                {parsedData.EXPERIENCE.map((exp, index) => (
                  <div key={index} className="flex flex-col gap-y-1">
                    <span className="font-semibold flex gap-x-2 items-center text-pretty">
                      <BusinessRounded sx={{ fontSize: "1rem" }} />
                      <span>{exp.subtitle}</span>
                    </span>
                    <span className="ml-6 text-pretty">{exp.title}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {parsedData.EDUCATION.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <span className="text-xs font-semibold">Education</span>
              <div className="flex flex-col gap-y-2">
                {parsedData.EDUCATION.map((exp, index) => (
                  <div key={index} className="flex flex-col gap-y- text-pretty">
                    <span className="font-semibold flex gap-x-2 items-center overflow-hidden text-ellipsis whitespace-wrap">
                      <SchoolRounded sx={{ fontSize: "1rem" }} />
                      {exp.title}
                    </span>
                    <span className="ml-6">{exp.subtitle}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessOwnersFindings;
