import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentRun } from "@/types";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AgentRunItem from "./V2_Home_Page/AgentRunItem";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";

const AGENT_JOBS_LIMIT = 100;

const NewJobsList = () => {
  const { agentKey, jobId } = useParams();
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints;
  const [isLoadingJobs, setIsLoadingJobs] = useState<boolean>(true);
  const [jobs, setJobs] = useState<AgentRun[]>([]);
  const navigate = useNavigate();

  const endpoint = endpoints?.find((endpoint) => endpoint.agentKey === agentKey);
  const isFlutterwaveMCC = agentKey === "kyb-mcc-flutterwave";

  useEffect(() => {
    if (!agentKey || !endpoint) return;

    const envTier = import.meta.env.VITE_ENV_TIER;

    parchaApi
      .getAgentJobHistory(endpoint.endpointUrl, endpoint.agentKey, AGENT_JOBS_LIMIT, 0)
      .then((res: { items: AgentRun[] }) => {
        const newJobs = res.items;

        if (isFlutterwaveMCC) {
          const jobIds =
            res.items
              ?.filter(
                (item): item is AgentRun & { id: string } => item.recommendation === "Review" && item.id !== undefined,
              )
              .map((value) => value.id) || [];

          parchaApi.getFinalAnswerForJobs(endpoint.endpointUrl, agentKey, jobIds).then((res) => {
            if (res) {
              res.forEach((far: { job_id: string }) => {
                const jobIndex = newJobs.findIndex((job) => job.id === far.job_id);
                if (jobIndex !== -1) {
                  newJobs[jobIndex] = { ...newJobs[jobIndex], isHighRisk: true };
                }
              });
            }
            setJobs(newJobs);
            setIsLoadingJobs(false);
          });
        } else {
          setJobs(newJobs);
          setIsLoadingJobs(false);
        }

        if (!jobId) {
          navigate(`/jobs/${agentKey}/${res.items[0].id}`);
        } else {
          const isJobInList = newJobs.some((job) => job.id === jobId);
          if (!isJobInList) {
            parchaApi.getJobWithoutStatusMessages(endpoint.endpointUrl, jobId).then((res) => {
              if (res) {
                setJobs([...newJobs, res]);
              }
            });
          }
        }
      });

    const interval = setInterval(() => {
      parchaApi
        .getAgentJobHistory(endpoint.endpointUrl, endpoint.agentKey, AGENT_JOBS_LIMIT, 0)
        .then((res: { items: AgentRun[] }) => {
          const newJobs = res.items;

          if (isFlutterwaveMCC) {
            const jobIds =
              res.items
                ?.filter(
                  (item): item is AgentRun & { id: string } =>
                    item.recommendation === "Review" && item.id !== undefined,
                )
                .map((value) => value.id) || [];

            parchaApi.getFinalAnswerForJobs(endpoint.endpointUrl, agentKey, jobIds).then((res) => {
              if (res) {
                res.forEach((far: { job_id: string }) => {
                  const jobIndex = newJobs.findIndex((job) => job.id === far.job_id);
                  if (jobIndex !== -1) {
                    newJobs[jobIndex] = { ...newJobs[jobIndex], isHighRisk: true };
                  }
                });
              }
              setJobs(newJobs);
              setIsLoadingJobs(false);
            });
          } else {
            setJobs(newJobs);
            setIsLoadingJobs(false);
          }
        });
    }, 10000);

    return () => clearInterval(interval);
  }, [agentKey, endpoints]);

  return (
    <div className="h-[calc(100vh-3.75rem)]">
      <div className="grid grid-cols-[350px_1fr] h-full w-full">
        {isLoadingJobs ? (
          <div className="col-span-2 w-full h-full">
            <ParchaLoadingScreen message="Loading agent jobs..." size="large" />
          </div>
        ) : (
          <div className="h-full overflow-auto border border-r-slate-200">
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <AgentRunItem
                  id={job.id || job.job_id}
                  key={job.id || job.job_id}
                  isSelected={job.id?.replaceAll("-", "") === jobId || job.job_id?.replaceAll("-", "") === jobId}
                  agentRun={job}
                  setSelectedAgentJobId={() => navigate(`/jobs/${agentKey}/${job.id ?? job.job_id}`)}
                />
              ))
            ) : (
              <div className="text-brand-purple py-3 px-6 italic opacity-75">No jobs for this agent.</div>
            )}
          </div>
        )}
        {!isLoadingJobs && (
          <div className="h-full overflow-auto">
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewJobsList;
