import { Chip, Tooltip, Typography } from "@mui/material";
import React from "react";
import { uniqueId } from "lodash";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { orange } from "@mui/material/colors";
import AdverseMediaHit from "@/components/TailwindComponents/AdverseMediaHit";
import CollapsibleText from "../CollapsibleText";
import AddressValue from "../TailwindComponents/AddressValue";
import AuditLogDocument from "../TailwindComponents/AuditLogDocument";
import { AddressType, DocumentType, PayloadType } from "@/types";
import SanctionsWatchlistCard from "../TailwindComponents/SanctionsWatchlistCard";
import WebsiteValue from "../TailwindComponents/WebsiteValue";

// TODO: @sriverag Add show more adverse media hit tracking.

interface PayloadRendererProps {
  name: string;
  value: string | { type?: PayloadType; [key: string]: any } | Array<any>;
}

const PayloadRenderer: React.FC<PayloadRendererProps> = ({ value }) => {
  // for backwards compatibility, need to try to convert values from string to json object or array
  let parsedValue = value;
  if (typeof value === "string") {
    try {
      parsedValue = JSON.parse(value);
    } catch (error) {
      // do nothing
    }
  }
  const friendlyName = (id: string) => {
    return id
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const render_obj = (item: any, key: string) => {
    if (typeof item === "object" && !Array.isArray(item)) {
      switch (item.type) {
        case "Address": {
          const {
            street_1: street1,
            street_2: street2,
            city,
            country_code: countryCode,
            postal_code: postalCode,
            state,
          } = item as AddressType;
          return (
            <AddressValue
              street1={street1 || ""}
              street2={street2 || ""}
              city={city || ""}
              countryCode={countryCode || ""}
              postalCode={postalCode || ""}
              state={state || ""}
            />
          );
        }
        case "Document": {
          const documentItem = item as DocumentType;
          return (
            <AuditLogDocument
              documentName={documentItem.file_name}
              documentURL={documentItem.url}
              pageCount={documentItem.num_pages ?? 0}
            />
          );
        }
        case "SanctionsHit": {
          return (
            <div key={key} className="py-2">
              <SanctionsWatchlistCard
                level="failure"
                countryCode={item.matched_lists[0].split("_")[0]}
                matchedNames={item.matched_names}
                url={item.source_url}
                description={item.description}
                sanctionListName={item.matched_lists[0]}
              />
            </div>
          );
        }
        case "document-with-url": {
          return (
            <>
              <a href={item.url} target="_blank" rel="noreferrer">
                <Tooltip title={item.name}>
                  <div style={{ display: "flex", alignItems: "center", paddingTop: "4px" }}>
                    <PictureAsPdfIcon />
                    <Typography variant="body1" sx={{ fontWeight: "bold", paddingLeft: "4px" }}>
                      {friendlyName(item.description)}
                    </Typography>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={item.label}
                      sx={{ marginLeft: "4px", color: orange[500] }}
                    />
                  </div>
                </Tooltip>
              </a>
            </>
          );
        }
        default:
          return Object.entries(item).map(([key, value], entriesIndex) => {
            if (!value || key === "type") return null;

            return (
              <div key={key}>
                <Typography className="text-slate-700" sx={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}>
                  {`${friendlyName(key)}: `}{" "}
                  {typeof value === "string" ? value : render_obj(value, `${key}-${entriesIndex}`)}
                </Typography>
              </div>
            );
          });
      }
    } else if (item && Array.isArray(item["verified_adverse_media_hits"])) {
      return (
        <div className="flex flex-col gap-4">
          {item["verified_adverse_media_hits"].map((mediaHit) => (
            <AdverseMediaHit adverseMediaHit={mediaHit} key={mediaHit.source_url} level="failure" />
          ))}
        </div>
      );
    } else if (item && Array.isArray(item)) {
      return (
        <div className="flex flex-col gap-4">{item.map((itemValue, index) => render_obj(itemValue, `${index}`))}</div>
      );
    }
    //TODO: Implement other complex types.
    // String
    else {
      return (
        <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
          {item}
        </Typography>
      );
    }
  };
  //if it's a string, just display it
  //if it's an object, check if there's a type field, if so, display according to the type.
  // if it's a list, display each item in the list using the logic above.
  // if it's a news article, render an AdverseMediaHit
  return (
    <>
      {typeof parsedValue === "string" || typeof parsedValue === "boolean" ? (
        String(parsedValue).startsWith("http") ? (
          <WebsiteValue url={parsedValue} />
        ) : (
          <CollapsibleText text={String(parsedValue)} />
        )
      ) : Array.isArray(parsedValue) ? (
        parsedValue.length > 0 ? (
          // We don't have a type for News Article, so checking for source_url and title
          parsedValue[0].source_url && parsedValue[0].title ? (
            <div className="flex flex-col gap-4">
              {/* {parsedValue.slice(0, 3).map((item) => (
                <AdverseMediaHit adverseMediaHit={item} key={item.source_url} />
              ))}
              {parsedValue.length > 3 ? <button>{`Show all ${parsedValue.length} results.`}</button> : null} */}
              {parsedValue.map((item) => (
                <AdverseMediaHit adverseMediaHit={item} key={item.source_url} level="failure" />
              ))}
            </div>
          ) : parsedValue.every((value) => typeof value === "string") ? (
            <div className="text-sm">{parsedValue.join(", ")}</div>
          ) : (
            parsedValue.map((item, index) => {
              if (item.type && item.type === "Document") {
                <AuditLogDocument
                  documentName={item.file_name}
                  documentURL={item.url}
                  pageCount={item.num_pages ?? 0}
                />;
              }
              return <div key={index}>{render_obj(item, `${item.type}${index}`)}</div>;
            })
          )
        ) : (
          <span>&nbsp;</span>
        )
      ) : (
        parsedValue && render_obj(parsedValue, uniqueId("val"))
      )}
    </>
  );
};

export default PayloadRenderer;
