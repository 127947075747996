import { useState } from "react";
import { ThumbUpOutlined, ThumbUp, ThumbDownOutlined, ThumbDown } from "@mui/icons-material";

type ChecksFeedbackProps = {
  score: boolean;
  onThumbsUpClick: () => void;
  onThumbsDownClick: () => void;
};

export const ChecksFeedback = ({ score, onThumbsUpClick, onThumbsDownClick }: ChecksFeedbackProps) => {
  const [isHoveringThumbUp, setIsHoveringThumbUp] = useState(false);
  const [isHoveringThumbDown, setIsHoveringThumbDown] = useState(false);

  const iconStyle = { fontSize: "1rem" };
  const buttonClassNames =
    "flex justify-center items-center border border-solid border-slate-300 rounded-md p-2.5 w-4 h-4";

  return (
    <div className="flex gap-x-2">
      <button
        className={buttonClassNames}
        onMouseEnter={() => setIsHoveringThumbUp(true)}
        onMouseLeave={() => setIsHoveringThumbUp(false)}
        onClick={onThumbsUpClick}
      >
        {isHoveringThumbUp || (typeof score === "boolean" && score) ? (
          <ThumbUp sx={iconStyle} height="16" width="16" />
        ) : (
          <ThumbUpOutlined sx={iconStyle} height="16" width="16" />
        )}
      </button>
      <button
        className={buttonClassNames}
        onMouseEnter={() => setIsHoveringThumbDown(true)}
        onMouseLeave={() => setIsHoveringThumbDown(false)}
        onClick={onThumbsDownClick}
      >
        {isHoveringThumbDown || (typeof score === "boolean" && !score) ? (
          <ThumbDown sx={iconStyle} />
        ) : (
          <ThumbDownOutlined sx={iconStyle} />
        )}
      </button>
    </div>
  );
};
