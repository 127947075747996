import { ReactNode } from "react";
import { Box, ThemeProvider, SxProps, Theme } from "@mui/system";
import { theme } from "../../../theme";
import { Typography, colors } from "@mui/material";

interface BaseMessageProps {
  id: string;
  icon?: ReactNode;
  message?: string;
  customComponent?: ReactNode;
  sx?: SxProps<Theme>;
  mode?: "error" | "success" | "warning";
}

const BaseMessage = ({ id, icon, message, customComponent, sx, mode }: BaseMessageProps) => {
  const getBaseMessage = () => {
    return (
      <Box
        id={id}
        sx={{
          padding: "0",
          margin: "0",
          position: "relative",
          backgroundColor: mode === "error" ? colors.red[50] : "transparent",
          ...sx,
        }}
      >
        {message && (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "top",
              justifyContent: "flex-start",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
          >
            {icon ? (
              <Box
                component="div"
                sx={{
                  backgroundColor: mode === "error" ? colors.red[800] : "#5D5FEF",
                  width: "2rem",
                  height: "2rem",
                  minWidth: "2rem",
                  padding: "0.25rem",
                  marginLeft: "0.25rem",
                  borderRadius: mode === "error" ? "50%" : "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {icon}
              </Box>
            ) : (
              <Box
                component="div"
                sx={{
                  width: "20px",
                  minWidth: "20px",
                  borderLeft: "0.125rem solid #5D5FEF",
                  marginLeft: "19px",
                }}
              />
            )}
            <p
              className="text-slate-700 text-[0.75rem]"
              style={{
                marginLeft: "1rem",
                fontSize: "0.75rem",
              }}
            >
              {message}
            </p>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          {customComponent}
        </Box>
      </Box>
    );
  };

  return <ThemeProvider theme={theme}>{getBaseMessage()}</ThemeProvider>;
};

export default BaseMessage;
