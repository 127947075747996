import Box from "@mui/material/Box";
import styles from "../V2_Home_Page/ParchaLoadingScreen/style.module.css";

const FindingsLoadingIndicator = ({ message }: { message: string }) => {
  return (
    <div className="flex flex-col gap-y-3 justify-center items-center text-xs bg-slate-50 rounded-lg p-10 w-[24rem]">
      <Box
        className={styles.loader}
        component="img"
        src={"/working_loader.svg"}
        alt="logo"
        width={32}
        sx={{
          padding: "0",
          animationName: "spin",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationDuration: "3s",
          animationDelay: "0",
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" },
          },
        }}
      />
      <div className="flex flex-col gap-y-1 items-center">
        <span className="font-semibold">{message}</span>
        <span>Results will appear here shortly.</span>
      </div>
    </div>
  );
};

export default FindingsLoadingIndicator;
