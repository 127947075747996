import { useState } from "react";

import { Agent, Endpoint, User } from "@/types";
import React from "react";

interface UserContextType {
  agent: Agent | null;
  user: User | null;
  endpoints: Endpoint[] | null;
  login: (user: User) => void;
  logout: () => void;
  saveEndpoints: (agents: Endpoint[]) => void;
  saveAgent: (agent: Agent) => void;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [endpoints, setEndpoints] = useState<Endpoint[] | null>(null);
  const [agent, setAgent] = useState<Agent | null>(null);

  const login = (user: User) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    setEndpoints(null);
  };

  const saveEndpoints = (endpoints: Endpoint[]) => {
    setEndpoints(endpoints);
  };

  const saveAgent = (agent: Agent) => {
    setAgent(agent);
  };

  return (
    <UserContext.Provider value={{ agent, saveAgent, login, logout, user, endpoints, saveEndpoints }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
