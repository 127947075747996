import { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";

export default function SignOut() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { logout } = useDescope();

  useEffect(() => {
    const onLogout = async () => {
      const path = searchParams.has("authorized") ? `/signin?${searchParams.toString()}` : "/signin";

      await logout();
      navigate(path);
    };

    onLogout();
  }, [logout]);

  return (
    <Grid container justifyContent="center" alignItems="center" width="100%" height="100vh">
      <CircularProgress />
    </Grid>
  );
}
