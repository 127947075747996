import { snakeCaseToProperCase } from "@/utils";

type ResultAlertsType = {
  alerts: ArrayLike<{ [key: string]: string }>;
};

const ResultAlerts: React.FC<ResultAlertsType> = ({ alerts }) => {
  return (
    <div className="w-full flex flex-col gap-3 flex-wrap rounded-lg p-4 bg-yellow-100 text-yellow-700 text-xs z-20">
      {Array.isArray(alerts) &&
        alerts.map(([name, value]) =>
          typeof value === "string" ? (
            <div key={name} className="flex gap-x-1 flex-wrap w-full">
              <span className="font-semibold">{snakeCaseToProperCase(name)}:</span>
              <p className="break-words overflow-hidden">{value}</p>
            </div>
          ) : value["reason"] ? (
            <div key={name} className="flex gap-x-1 flex-wrap w-full">
              <span className="font-semibold">{snakeCaseToProperCase(name)}:</span>
              <p className="break-words overflow-hidden">{value["reason"]}</p>
            </div>
          ) : null,
        )}
    </div>
  );
};

export default ResultAlerts;
