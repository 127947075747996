import React from "react";

interface ErrorContextType {
  error: Error | null;
  setError: (error: Error | null) => void;
}
export const ErrorContext = React.createContext<ErrorContextType>({
  error: null,
  setError: () => {},
});
