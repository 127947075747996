import { twJoin } from "tailwind-merge";
import Alert from "./Alert";
import { alpha3ToAlpha2Dictionary } from "@/data/alpha2ToAlpha3Dictionary";

type CountryMatch = {
  name: string;
  countryCode: string | null;
};

type ProhibitedHighRiskCountryCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  countries: CountryMatch[];
  evidenceMessage: string;
};

const ProhibitedHighRiskCountryCard = ({ level, countries, evidenceMessage }: ProhibitedHighRiskCountryCardProps) => {
  if (!countries || !countries.length) {
    return null;
  }

  const getIconCode = (countryCode: string | null) => {
    return countryCode && countryCode.length === 3
      ? alpha3ToAlpha2Dictionary[countryCode.toUpperCase()]
      : countryCode && countryCode.length === 2
        ? countryCode
        : null;
  };

  return (
    <div className="text-xs">
      <div
        className={twJoin(
          "flex flex-col border text-slate-900 p-4 rounded-lg",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      >
        <div className="flex flex-wrap">
          {countries &&
            countries.map((country, index) => {
              const iconCode = getIconCode(country.countryCode);

              return (
                <div key={country.name} className="flex items-center gap-1">
                  {index > 0 ? <span>,</span> : null}
                  {iconCode && (
                    <span
                      className={`fi fi-${iconCode.toLowerCase()} drop-shadow rounded-full !bg-cover !w-3 !h-3`}
                    ></span>
                  )}
                  <span>{country.name}</span>
                </div>
              );
            })}
        </div>
        {evidenceMessage && level ? (
          <div className="mt-2">
            <Alert level={level} message={evidenceMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProhibitedHighRiskCountryCard;
