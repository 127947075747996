import { twJoin } from "tailwind-merge";
import WrappedValue from "./WrappedValue";
import WebsiteValue from "./WebsiteValue";
import { CheckRounded, CancelRounded } from "@mui/icons-material";

type ProductInfo = {
  product: string;
  description: string;
};

type MCCCardProps = {
  level: "normal" | "success" | "failure";
  businessName: string;
  businessDescription: string;
  mccCode: string;
  productsSold: ProductInfo[] | null | undefined;
  website: string | null;
};

export default function MCCCard({
  level,
  businessName,
  businessDescription,
  mccCode,
  productsSold,
  website,
}: MCCCardProps) {
  if (!businessName || !businessName.length) return null;

  return (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm text-slate-900 mt-2",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "items-center rounded-t-md p-4 border-b border-slate-300",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-600"
            : level === "failure"
              ? "bg-red-100 border-b-red-600"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="font-medium uppercase">{businessName}</div>
      </div>
      <div className="flex flex-col gap-4 py-5 px-4">
        {mccCode && (
          <div className="flex flex-col gap-2">
            <div className="font-medium">MCC:</div>
            <div className="flex flex-wrap items-center gap-2">
              <WrappedValue
                text={
                  <div className="flex gap-x-1 items-center">
                    {level === "success" ? (
                      <CheckRounded sx={{ fontSize: "1rem" }} />
                    ) : level === "failure" ? (
                      <CancelRounded sx={{ fontSize: "1rem" }} />
                    ) : null}
                    <span>{mccCode}</span>
                  </div>
                }
                level={level}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col">
          <div className="font-medium">Description:</div>
          <div>{businessDescription}</div>
        </div>

        {productsSold && productsSold.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="font-medium">Products Sold:</div>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left">Product</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(
                  productsSold.reduce<Record<string, string>>((acc, product) => {
                    if (!acc[product.product]) {
                      acc[product.product] = product.description;
                    }
                    return acc;
                  }, {}),
                ).map(([product, description], index) => (
                  <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                    <td className="border border-gray-300 px-4 py-2">{product}</td>
                    <td className="border border-gray-300 px-4 py-2">{description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {website && (
          <div className="flex flex-col gap-2">
            <div className="font-medium">Website:</div>
            <WebsiteValue url={website} />
          </div>
        )}
      </div>
    </div>
  );
}
