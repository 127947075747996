import { twMerge } from "tailwind-merge";

type WrappedValueProps = {
  text: React.ReactNode | string | null;
  level?: "success" | "failure" | "normal" | "caution";
  className?: string;
};
const WrappedValue = ({ className, text, level = "normal" }: WrappedValueProps) => {
  if (!text || (typeof text === "string" && !text.length)) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "rounded-md border py-1 px-2 font-medium text-xs h-6 flex w-fit items-center",
        !level || level === "normal"
          ? "border-[#334155] border-opacity-20 text-slate-700 bg-slate-50"
          : level === "success"
            ? "border-[#16A34A] border-opacity-20 text-green-700 bg-green-100"
            : level === "failure"
              ? "border-[#DC2626] border-opacity-10 text-red-700 bg-red-50"
              : level === "caution"
                ? "border-[#F59E0B] border-opacity-20 text-yellow-700 bg-yellow-50"
                : "",
        className || "",
      )}
    >
      <span className="truncate">{text}</span>
    </div>
  );
};

export default WrappedValue;
