import crunchbaseLogo from "../../assets/crunchbase_icon.png";

type CrunchbaseCompanyCardProps = {
  logoUrl: string | null;
  title: string | null;
  url: string | null;
  short_description: string | null;
  funding_rounds_summary: {
    funding_total: {
      value: number;
      currency: string;
    } | null;
    num_funding_rounds: number | null;
    last_funding_type: string | null;
    last_funding_at: string | null;
  } | null;
  social_fields: {
    linkedin: { value: string } | null;
    twitter: { value: string } | null;
  } | null;
  investors_list: Array<{
    is_lead_investor: boolean | null;
    investor_identifier: { value: string };
    partner_identifiers: Array<{ value: string }>;
  }> | null;
};

const CrunchbaseCompanyCard = ({
  logoUrl,
  title,
  short_description,
  url,
  funding_rounds_summary,
  social_fields,
  investors_list,
}: CrunchbaseCompanyCardProps) => {
  if (!title && !url) return null;

  const leadInvestors = investors_list?.filter((investor) => investor.is_lead_investor === true);

  return (
    <div className="w-full rounded-xl text-slate-900 text-xs p-4 bg-slate-50 overflow-hidden h-fit mb-4">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <img src={crunchbaseLogo} alt="Crunchbase Logo" className="w-4 h-4" />
              <span className="font-semibold">Crunchbase</span>
            </div>
            <div className="h-6.5 min-w-[6rem] flex justify-center items-center rounded-full bg-slate-50 border border-[#334155]/20 py-1 px-2 text-slate-700 truncate">
              Business Intelligence
            </div>
          </div>
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="max-w-fit text-brand-purple truncate hover:underline"
            >
              {url}
            </a>
          )}
          <div className="flex gap-x-3">
            {logoUrl && (
              <img
                src={logoUrl}
                alt="Company Logo"
                className="w-12 h-12 rounded-lg object-cover"
                onError={({ currentTarget }) => (currentTarget.style.display = "none")}
              />
            )}
            <div className="flex flex-col gap-y-0.5 overflow-hidden">
              <div className="flex flex-wrap items-baseline gap-x-1">
                <span className="text-base text-bold">{title}</span>
              </div>
              {short_description && <div className="text-xs text-slate-700">{short_description}</div>}
            </div>
          </div>
        </div>
        {funding_rounds_summary && (
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold">Funding Information</span>
            {funding_rounds_summary.funding_total && (
              <div className="text-slate-700">
                Total Funding: ${funding_rounds_summary.funding_total.value.toLocaleString()}{" "}
                {funding_rounds_summary.funding_total.currency}
              </div>
            )}
            {funding_rounds_summary.last_funding_type && (
              <div className="text-slate-700">
                Last Funding Type:{" "}
                {funding_rounds_summary.last_funding_type.charAt(0).toUpperCase() +
                  funding_rounds_summary.last_funding_type.slice(1).toLowerCase()}
              </div>
            )}
            {funding_rounds_summary.last_funding_at && (
              <div className="text-slate-700">
                Last Funding Date: {new Date(funding_rounds_summary.last_funding_at).toLocaleDateString()}
              </div>
            )}
            {funding_rounds_summary.num_funding_rounds && (
              <div className="text-slate-700">
                Number of Funding Rounds: {funding_rounds_summary.num_funding_rounds}
              </div>
            )}
          </div>
        )}
        {leadInvestors && leadInvestors.length > 0 && (
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold">Lead Investors</span>
            {leadInvestors.map((investor, index) => (
              <div key={index} className="text-slate-700">
                {investor.investor_identifier.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CrunchbaseCompanyCard;
