// @ts-nocheck
import StatusIndicator from "@/components/TailwindComponents/StatusIndicator";
import useAuth from "@/hooks/useAuth";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentRun, Endpoint } from "@/types";
import { useDescope } from "@descope/react-sdk";
import { ArrowDownwardRounded, AutoAwesome, LockRounded, Menu as MenuIcon } from "@mui/icons-material";
import clsx from "clsx";
import { formatDistanceToNowStrict } from "date-fns";
import { useState, useEffect, useContext } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import ParchaLoadingScreen from "../ParchaLoadingScreen";
import UserContext from "@/contexts/UserContext";
import { AgentsDropdownMenu } from "../Queue";

const JOB_HISTORY_PAGINATION_LIMIT = 25;

const ReportList = () => {
  const [jobs, setJobs] = useState([]);
  const { reportJobId } = useParams();
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  // const [isLoadingMoreJobs, setIsLoadingMoreJobs] = useState(false);
  // const [totalJobs, setTotalJobs] = useState(0);
  // const [hasMoreJobs, setHasMoreJobs] = useState(true);
  // const [offset, setOffset] = useState(0);
  const { isLoading, user } = useAuth();
  const parchaApi = useParchaApi();
  const descope = useDescope();
  const navigate = useNavigate(); // get the navigate function
  const [showMenu, setShowMenu] = useState(false); // Add state for showMenu
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | null>(null);

  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints;

  // const fetchMoreJobs = async () => {
  //   const envTier = import.meta.env.VITE_ENV_TIER;
  //   const endpointUrl =
  //     envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "us1.parcha.ai";

  //   const res = await parchaApi.getAgentJobHistory(endpointUrl, "public-bdd", JOB_HISTORY_PAGINATION_LIMIT, offset);

  //   setTotalJobs(res.total ?? 0);
  //   setOffset(offset + JOB_HISTORY_PAGINATION_LIMIT);
  //   setJobs((prevJobs) => [...prevJobs, ...(res.items ?? [])]);

  //   if (jobs.length + (res.items?.length ?? 0) >= (res.total ?? 0)) {
  //     setHasMoreJobs(false);
  //   }
  // };

  useEffect(() => {
    if (!isLoading) {
      descope.me();
    }
  }, [isLoading]);

  // useEffect(() => {
  //   fetchMoreJobs().then(() => setIsLoadingJobs(false));
  // }, []);

  useEffect(() => {
    if (!userContext.endpoints) return;

    const publicBDD = endpoints.find((endpoint) => endpoint.isPublic);

    if (publicBDD) {
      setSelectedEndpoint(publicBDD);
    } else {
      navigate("/jobs");
    }
  }, [userContext.endpoints]);

  useEffect(() => {
    if (isLoading || !selectedEndpoint) return;

    parchaApi
      .getAgentJobHistory(selectedEndpoint.endpointUrl, selectedEndpoint.agentKey, JOB_HISTORY_PAGINATION_LIMIT, 0)
      .then((res: { items: AgentRun[] }) => {
        setJobs(res.items);

        if (!reportJobId) {
          res.items.length > 0 ? navigate(`/reports/${res.items[0].id}`) : navigate(`/create-report`);
        }

        setIsLoadingJobs(false);
      });

    const interval = setInterval(() => {
      parchaApi.getAgentJobHistory(selectedEndpoint.endpointUrl, selectedEndpoint.agentKey, 50, 0).then((res: any) => {
        setJobs(res.items);
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [selectedEndpoint, isLoading]);

  useEffect(() => {
    if (!reportJobId && jobs.length > 0) {
      navigate(`/reports/${jobs[0].id}`);
    }
  }, [jobs]);

  const publicJobsRemaining = user?.customAttributes?.publicBddQuota - user?.customAttributes?.publicBddJobsRun ?? 0;

  // const loadRemainingJobsCount =
  //   totalJobs - jobs.length > JOB_HISTORY_PAGINATION_LIMIT ? JOB_HISTORY_PAGINATION_LIMIT : totalJobs - jobs.length;

  return (
    <div className="h-full w-full">
      <div className="px-5 py-[0.8rem] flex flex-col md:flex-row items-center justify-between md:gap-3 border-b border-slate-200">
        <div className="flex items-center justify-between w-full md:w-auto">
          <div className="flex items-center gap-x-10">
            <span className="font-semibold">Business Due Diligence Report</span>
            {endpoints && endpoints.length > 1 && (
              <AgentsDropdownMenu
                items={endpoints?.map((agent) => ({ label: agent.agentName, url: `/jobs/${agent.agentKey}` }))}
                selectedItem={{
                  label: selectedEndpoint?.agentName ?? "Select an agent",
                  url: selectedEndpoint ? `/jobs/${selectedEndpoint.agentKey}` : "",
                }}
              />
            )}
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => setShowMenu(!showMenu)}
            >
              <MenuIcon className="h-4 w-4" sx={{ fontSize: "1rem" }} aria-hidden="true" aria-label="Menu" />
            </button>
            {showMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-30">
                <button
                  type="button"
                  className="block w-full text-left p-4 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => navigate("/create-report")}
                >
                  <AutoAwesome className="-ml-0.5 h-4 w-4 mr-1" sx={{ fontSize: "1rem" }} aria-hidden="true" />
                  Generate New Report
                </button>
                <a
                  href="https://calendly.com/ajasver/request-a-demo"
                  className="block w-full text-left p-4 text-sm text-gray-700 hover:bg-gray-100"
                  target="_blank"
                  rel="norefferrer noopener"
                >
                  <LockRounded
                    className="-ml-0.5 h-4 w-4 text-slate-900 mr-1"
                    sx={{ fontSize: "1rem" }}
                    aria-hidden="true"
                  />
                  Get Full Access
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end md:flex-row md:items-center gap-3">
          <div className="hidden md:block">
            {!Number.isNaN(publicJobsRemaining) && (
              <span
                className={twJoin(
                  "text-xs px-2 py-1 font-semibold rounded truncate",
                  publicJobsRemaining > 0 ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100",
                )}
              >
                {` You have ${publicJobsRemaining} free report${publicJobsRemaining !== 1 ? "s" : ""} left`}
              </span>
            )}
          </div>

          <div className="hidden md:flex md:flex-row md:items-center gap-3">
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => navigate("/create-report")}
            >
              <AutoAwesome className="-ml-0.5 h-4 w-4" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Generate New Report
            </button>
            <a
              href="https://calendly.com/ajasver/request-a-demo"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-white text-slate-900 px-3 py-2 text-xs font-semibold border border-slate-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              target="_blank"
              rel="norefferrer noopener"
            >
              <LockRounded className="-ml-0.5 h-4 w-4 text-slate-900" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Get Full Access
            </a>
          </div>
        </div>
      </div>
      <div className="h-[calc(100vh-4.85rem)] md:h-[calc(100vh-4rem)] overflow-y-auto grid grid-cols-1 md:grid-cols-[25vw,minmax(0,1fr)]">
        {/* <div className="w-full h0 min-h-full grid grid-cols-1 md:grid-cols-[25vw,minmax(0,1fr)]"> */}
        {isLoadingJobs ? (
          <div className="col-span-2">
            <ParchaLoadingScreen message="Loading your reports..." size="large" />
          </div>
        ) : (
          <>
            <div className="relative hidden md:block h-0 min-h-full overflow-y-auto border border-r-slate-200">
              {jobs.map((agentRun: AgentRun) => (
                <Link
                  key={agentRun.id}
                  to={`/reports/${agentRun.id}`}
                  className={clsx("flex flex-col p-2.5 md:p-5 border-b border-b-slate-200 gap-3", {
                    "bg-slate-100": reportJobId === agentRun.id,
                  })}
                  id={agentRun.id}
                >
                  <div className="flex justify-between gap-x-2">
                    <div className="line-clamp-2 md:line-clamp-4 font-inter font-semibold text-sm md:text-md leading-normal">
                      {agentRun.input_payload?.self_attested_data?.name ||
                        agentRun.input_payload?.self_attested_data?.business_name ||
                        agentRun.input_payload?.self_attested_data?.registered_business_name ||
                        agentRun.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "")}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center gap-1 md:gap-2 text-xs md:text-sm">
                    <div>
                      <StatusIndicator status={agentRun.status} />
                    </div>
                    <div className="capitalize">{agentRun.status}</div>
                    <div className="hidden md:block">•</div>
                    <div className="hidden md:block">
                      {formatDistanceToNowStrict(new Date(`${agentRun.created_at}Z`), { addSuffix: true })}
                    </div>
                  </div>
                </Link>
              ))}
              {/* {hasMoreJobs ? (
                <div className="sticky bottom-5 w-full flex justify-center pb-5">
                  <button
                    className="shadow inline-flex items-center py-2 px-4 border border-solid border-slate-200 rounded-md text-sm font-medium bg-white hover:bg-slate-50"
                    onClick={async () => {
                      if (!isLoadingMoreJobs) {
                        setIsLoadingMoreJobs(true);
                        await fetchMoreJobs();
                        setIsLoadingMoreJobs(false);
                      }
                    }}
                  >
                    {isLoadingMoreJobs ? (
                      <div className="w-[10rem] h-[1.25rem]">
                        <ParchaLoadingScreen size="small" message="" />
                      </div>
                    ) : (
                      <>
                        <span>{`Load ${loadRemainingJobsCount} more jobs...`}</span>
                        <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="ml-1 h-4 w-4" aria-hidden="true" />
                      </>
                    )}
                  </button>
                </div>
              ) : null} */}
            </div>
            <Outlet />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportList;
