type UpsellSectionWrapperProps = {
  title: string;
  subtitle: string;
  description: string;
  imgSrc: string;
  learnMoreURL: string;
};

const UpsellSectionWrapper: React.FC<UpsellSectionWrapperProps> = ({
  title,
  subtitle,
  description,
  imgSrc,
  learnMoreURL,
}) => {
  return (
    <div className="p-5 lg:p-10 border border-indigo-100 rounded-xl flex flex-col-reverse lg:flex-row gap-5 overflow-hidden">
      <div className="flex flex-col gap-y-8 text-slate-700">
        <div className="flex flex-col gap-y-5 text-slate-700">
          <div className="flex flex-col font-semibold">
            <h2 className="text-2xl">{title}</h2>
            <h3 className="text-lg">{subtitle}</h3>
          </div>
          <p className="text-sm">{description}</p>
        </div>
        <a
          href={learnMoreURL}
          target="_blank"
          className="rounded-full bg-brand-purple text-white px-3 py-[0.38rem] w-fit text-xs font-medium hover:bg-opacity-10 border hover:text-brand-purple/100 hover:border hover:border-brand-purple place-self-center lg:place-self-start"
        >
          Learn more
        </a>
      </div>
      <div className="w-auto max-w-[400px] h-full mx-auto">
        <img src={imgSrc} className="w-full object-cover" />
      </div>
    </div>
  );
};

export default UpsellSectionWrapper;
