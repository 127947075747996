import React from "react";

type MapViewProps = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
};

const MapView = ({
  street1 = "",
  street2 = "",
  city = "",
  state = "",
  countryCode = "",
  postalCode = "",
}: MapViewProps) => {
  return (
    <div className="w-full flex items-center">
      {/* <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${gMapsAddress}&zoom=13&size=148x148&maptype=roadmap&markers=color:red%7C${gMapsAddress}&key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes`}
        className="object-cover w-16 h-16 rounded-md border border-slate-200  p-[0.125rem]"
      /> */}
      <div className="flex flex-col text-sm">
        <div>{`${street1}${street2 ? ` ${street2}` : ""}`}</div>
        <div>{`${city}${state ? `, ${state}` : ""}${countryCode ? `, ${countryCode}` : ""} ${postalCode}`}</div>
      </div>
    </div>
  );
};

export default MapView;
