import React from "react";

const ReviewBadge = ({ children }: { children?: JSX.Element }) => {
  return (
    <span className="h-6 flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
      {children || "Review"}
    </span>
  );
};

export default ReviewBadge;
