import { twJoin } from "tailwind-merge";
import { alpha3ToAlpha2Dictionary } from "@/data/alpha2ToAlpha3Dictionary";

type KYCSanctionsWatchlistCardProps = {
  countryCode: string | null;
  countryOfNationality: string | null;
  dateOfBirth: string | null;
  matchedNames: string[] | null | undefined;
  placeOfBirth: string | null;
  sanctionsDetails: string | null;
  sanctionListName: string;
  url: string;
  level: "success" | "failure" | "normal" | undefined;
};

const KYCSanctionsWatchlistCard = ({
  countryCode,
  countryOfNationality,
  dateOfBirth,
  matchedNames,
  placeOfBirth,
  sanctionsDetails,
  sanctionListName,
  url,
  level,
}: KYCSanctionsWatchlistCardProps) => {
  const iconCode =
    countryCode && countryCode.length === 3
      ? alpha3ToAlpha2Dictionary[countryCode.toUpperCase()]
      : countryCode && countryCode.length === 2
        ? countryCode
        : null;

  return (
    <div className="w-full">
      <div
        className={twJoin(
          "flex flex-col border text-slate-900 p-4 rounded-lg",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
        )}
      >
        {iconCode && (
          <div className="flex gap-2">
            <div className="flex items-center justify-center">
              <span className={`fi fi-${iconCode.toLowerCase()} drop-shadow rounded-full !bg-cover !w-4 !h-4`}></span>
            </div>
            <div className="text-sm">{iconCode.toUpperCase()}</div>
          </div>
        )}
        {sanctionListName ? (
          url ? (
            <a
              className="text-brand-purple hover:underline uppercase"
              href={url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {sanctionListName.replaceAll("_", " ")}
            </a>
          ) : (
            <span className="uppercase">{sanctionListName.replaceAll("_", " ")}</span>
          )
        ) : null}
        {matchedNames?.length && (
          <div className={twJoin("flex flex-wrap gap-1 mt-4 text-xs", level === "failure" ? "text-red-700" : "")}>
            <span className="font-semibold">{level === "failure" ? "Confirmed Hit:" : "Potential Hits:"}</span>
            <span>{matchedNames.join(", ")}</span>
          </div>
        )}
        {dateOfBirth && (
          <div className="flex flex-wrap gap-1 mt-4 text-xs">
            <span className="font-semibold">Date of Birth:</span>
            <span>{dateOfBirth}</span>
          </div>
        )}
        {placeOfBirth && (
          <div className="flex flex-wrap gap-1 text-xs">
            <span className="font-semibold">Place of Birth:</span>
            <span>{placeOfBirth}</span>
          </div>
        )}
        {countryOfNationality && (
          <div className="flex flex-wrap gap-1 text-xs">
            <span className="font-semibold">Country of Nationality:</span>
            <span
              className={`fi fi-${countryOfNationality.toLowerCase()} drop-shadow rounded-full !bg-cover !w-4 !h-4`}
            ></span>
            <span>{countryOfNationality}</span>
          </div>
        )}
        {sanctionsDetails && (
          <div className="flex flex-col gap-1 mt-4 text-xs">
            <span className="font-semibold">Sanctions Details:</span>
            <span>{sanctionsDetails}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default KYCSanctionsWatchlistCard;
