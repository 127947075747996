import Box from "@mui/material/Box";
import styles from "./style.module.css";
import { twJoin } from "tailwind-merge";

type ParchaLoadingScreenProps = {
  message?: string;
  size?: "small" | "medium" | "large";
};

const ParchaLoadingScreen = ({ message, size }: ParchaLoadingScreenProps) => (
  <div
    className={twJoin(
      "h-full flex flex-col justify-center items-center text-brand-purple",
      size === "small" ? "text-xs" : size === "medium" ? "text-sm" : "text-xl",
    )}
  >
    <Box
      className={styles.loader}
      component="img"
      src={"/working_loader.svg"}
      alt="logo"
      width={size === "small" ? 24 : size === "medium" ? 32 : 48}
      sx={{
        padding: "0",
        animationName: "spin",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        animationDuration: "3s",
        animationDelay: "0",
        "@keyframes spin": {
          "0%": { transform: "rotate(0deg)" },
          "100%": { transform: "rotate(360deg)" },
        },
      }}
    />
    <span>{message ?? "Loading..."}</span>
  </div>
);

export default ParchaLoadingScreen;
