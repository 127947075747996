import clsx from "clsx";

const StatusIndicator = ({
  status,
}: {
  status: "complete" | "ready for review" | "failed" | "in progress" | "queued" | "error";
}) => {
  if (!status) return null;

  return (
    <div
      className={clsx("flex-none rounded-full p-1", {
        "bg-violet-500/20": status === "queued",
        "bg-green-500/20": status === "complete",
        "bg-yellow-500/20": status === "in progress",
        "bg-red-500/20": status === "failed" || status === "error",
      })}
    >
      <div
        className={clsx("h-1.5 w-1.5 rounded-full", {
          "bg-violet-500": status === "queued",
          "bg-green-500": status === "complete",
          "bg-yellow-500": status === "in progress",
          "bg-red-500": status === "failed" || status === "error",
        })}
      />
    </div>
  );
};

export default StatusIndicator;
