const OneRowInfoValue = ({ label, value }: { label: string; value: JSX.Element | string }) => {
  if (!value || (typeof value === "string" && !value.length)) return null;

  return (
    <div className="flex flex-wrap items-center gap-1 text-xs">
      <div className="font-medium">{label}:</div>
      <div className="font-light">{value}</div>
    </div>
  );
};

export default OneRowInfoValue;
