// @ts-nocheck
import { useEffect, useState } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import { PolicyRounded } from "@mui/icons-material";
import Alert from "./Alert";
import WebsitePolicyCard from "./WebsitePolicyCard";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const PoliciesFindings = ({ apiKey, hideHeader = false, checkResult, endpointUrl }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PolicyRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">Policies</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <div className="place-self-center">
          <FindingsLoadingIndicator
            message={`Running Policies check${
              checkResult.metadata?.business_name ? ` on ${checkResult.metadata.business_name} ` : ""
            }...`}
          />
        </div>
      </div>
    );
  }

  if (!checkResult.check_result) {
    return null;
  }

  const parchaApi = useParchaApi();
  const [sourcesData, setSourcesData] = useState(null);

  const fetchSourceData = () => {
    if (apiKey) {
      return Promise.all(
        checkResult.check_result.policies_found.map((policy) => {
          return parchaApi.getSourceContentsFromAPI(
            endpointUrl,
            apiKey,
            policy.source_ids[0],
            checkResult.agentInstanceId,
          );
        }),
      );
    }

    return Promise.all(
      checkResult.check_result.policies_found.map((policy) => {
        return parchaApi.getSourceContents(endpointUrl, policy.source_ids, checkResult.agentInstanceId);
      }),
    );
  };

  useEffect(() => {
    if (checkResult.check_result.policies_found?.length > 0) {
      const sources = {};
      fetchSourceData().then((res) => {
        res.forEach((source) => {
          sources[source.doc.source_id] = source;
        });
        setSourcesData(sources);
      });
    }
  }, []);

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {!hideHeader && (
        <div className="flex items-center gap-x-2">
          <PolicyRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className="text-lg font-semibold">Policies</span>
        </div>
      )}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="flex flex-col gap-y-2">
        {sourcesData &&
          checkResult.check_result.policies_found?.map((policy, index) => (
            <WebsitePolicyCard
              key={index}
              match_type={policy.policy_type}
              explanation={policy.explanation}
              url={sourcesData[policy.source_ids[0]]?.doc.document_metadata?.url}
            />
          ))}
      </div>
    </div>
  );
};

export default PoliciesFindings;
