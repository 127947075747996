//@ts-nocheck
import { useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import PEPV2PotentialHitV2 from "./PEPV2PotentialHit";

type PepProfileCollection = {
  showAllPepsDefault: boolean;
  pepProfiles: {
    is_potential_hit: boolean | undefined;
    full_name: string;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (string | { country_name: string })[];
    associated_addresses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    roles: {
      start_date: string;
      end_date: string;
      pep_type: string;
      title: string;
      status: string;
    }[];
    weblinks?:
      | {
          url: string | null;
          date: string | null;
        }[]
      | null;
  }[];
};

const PepV2Collection = (entry: PepProfileCollection) => {
  const [showAllPeps, setShowAllPeps] = useState(entry.showAllPepsDefault);

  return (
    <div className="flex flex-col gap-y-3 text-xs">
      {entry.pepProfiles?.length > 0 && showAllPeps
        ? entry.pepProfiles.map((pepProfile, index) => <PEPV2PotentialHitV2 key={index} pepProfile={pepProfile} />)
        : null}
      {entry?.pepProfiles?.length > 0 && (
        <button
          type="button"
          disabled={!entry?.pepProfiles?.length}
          className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
          onClick={() => setShowAllPeps(!showAllPeps)}
        >
          {showAllPeps ? (
            <>
              {`Hide ${entry?.pepProfiles?.length} PEP${entry?.pepProfiles?.length === 1 ? "" : "s"}`}
              <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          ) : (
            <>
              <span>{`Show ${entry?.pepProfiles?.length ?? 0} PEP${entry?.pepProfiles?.length === 1 ? "" : "s"}`}</span>
              <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default PepV2Collection;
