import { useSession, useUser } from "@descope/react-sdk";
import { useContext, useEffect, useMemo, useState } from "react";
import { Endpoints, EndpointsAndTenant } from "../types";
import { useParchaApi } from "./useParchaApi";
import UserContext from "@/contexts/UserContext";

function useWebAuth() {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { isUserLoading, user } = useUser();
  const [endpoints, setEndpoints] = useState<Endpoints | undefined>(undefined);
  const [tenantName, setTenantName] = useState<string | undefined>(undefined);
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);

  async function getAvailableEndpoints(): Promise<EndpointsAndTenant> {
    const endpointsAndTenant: EndpointsAndTenant = await parchaApi.getCustomerAgents();
    userContext?.saveEndpoints(endpointsAndTenant.endpoints);
    return endpointsAndTenant;
  }

  const isLoading = useMemo(() => {
    return isSessionLoading || isUserLoading;
  }, [isSessionLoading, isUserLoading]);

  useEffect(() => {
    if (user) {
      getAvailableEndpoints().then((result) => {
        setEndpoints(result.endpoints);
        setTenantName(result.tenantName);
      });
    }
  }, [user]);

  return {
    isLoading,
    isAuthenticated,
    user,
    endpoints,
    tenantName,
    // TODO: Use this in production.
    // isLoading: isProd ? isLoading : false,
    // isAuthenticated: isProd ? isAuthenticated : true,
  };
}

export default useWebAuth;
