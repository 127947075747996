const prodAppSubdomain = import.meta.env.VITE_APP_SUBDOMAIN || "https://app.parcha.ai";

export const appUrl = import.meta.env.PROD ? prodAppSubdomain : "http://localhost:5173";

export const DESCOPE_PROJECT_ID = "P2QyhVzKVFe4lLCxndJUbLuyLhLt";

export const BUSINESS_PROFILE_CHECK_ID = "kyb.basic_business_profile_check";
export const WEB_PRESENCE_CHECK_ID = "kyb.web_presence_check";
export const MCC_CHECK_ID = "kyb.mcc_code_check.mcc_code_check_tool";
export const ADDRESS_CHECK_ID = "kyb.addresses_check";
export const POLICY_CHECK_ID = "kyb.policy_check";
export const HIGH_RISK_COUNTRY_CHECK_ID = "kyb.high_risk_country_tool";
export const HIGH_RISK_INDUSTRY_CHECK_ID = "kyb.high_risk_industry_tool";
export const HIGH_RISK_INDUSTRY_V2_CHECK_ID = "kyb.high_risk_industry_tool_v2";
export const ADVERSE_MEDIA_CHECK_ID = "kyb.screening.adverse_media_tool";
export const SANCTIONS_WATCHLIST_CHECK_ID = "kyb.sanctions_watchlist_check";
export const BUSINESS_OWNERS_CHECK_ID = "kyb.business_owners_check";

// Checks with Documents
export const INCORPORATION_DOCUMENT_CHECK_ID = "kyb.incorporation_document_verification";
export const BUSINESS_OWNERSHIP_CHECK_ID = "kyb.business_ownership_verification_tool";
export const SOURCE_OF_FUNDS_CHECK_ID = "kyb.source_of_funds_document_check";
export const PROOF_OF_ADDRESS_CHECK_ID = "kyb.proof_of_address_verification";
export const CANNABIS_LICENSE_CHECK_ID = "kyb.cannabis_license_verification";
export const EIN_DOCUMENT_CHECK_ID = "kyb.ein_document_verification";

export const shimmerEffectClassNames =
  "animate-shimmer bg-gradient-to-r from-brand-purple to-[#FEFB92] bg-clip-text text-transparent font-semibold";
