//@ts-nocheck
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import {
  Circle,
  ExpandLessRounded,
  ExpandMoreRounded,
  PersonSearchRounded,
  CalendarTodayRounded,
  LocationOnRounded,
  AutoAwesomeRounded,
  PersonRounded,
} from "@mui/icons-material";
import StrengthIndicator from "./MiniStrengthIndicator";
import MiniMatchLevelPill from "./MiniMatchLevelPill";
import PEPEventCard from "./PEPEventCard";

const TabularPEP = ({
  verifiedPepHits,
  input,
}: {
  verifiedPepHits;
  input: {
    first_name: string;
    last_name: string;
    middle_name: string | null;
    country_of_residence?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    country_of_nationality?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    address_country?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    date_of_birth: string;
    individual_age: number;
    today_date: string;
  };
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleExpandCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="border border-solid border-slate-200 rounded-lg shadow-xs min-w-full text-xs overflow-x-auto">
      <div className="flex items-center gap-x-5 px-10 py-5 text-sm border-b border-b-slate-200">
        <div className="flex gap-x-2 items-center">
          <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="capitalize font-semibold">
            {input.first_name}
            {input.last_name ? ` ${input.last_name}` : null}
          </span>
        </div>

        <div className="flex gap-x-2 items-center">
          <CalendarTodayRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="font-semibold">
            {input.individual_age ? `${input.individual_age} yrs old today` : "Not Available"}
          </span>
        </div>

        <div className="flex gap-x-2 items-center">
          <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="capitalize font-semibold">
            {input.country_of_residence?.country_name ??
              input.address_country?.country_name ??
              input.country_of_nationality?.country_name ??
              "Unknown"}
          </span>
        </div>
      </div>
      <table className="w-0 min-w-full divide-y divide-slate-200">
        <thead>
          {verifiedPepHits.length > 0 && (
            <tr className="bg-slate-50">
              <th className="px-2 py-3 text-left font-medium text-slate-500">Strength</th>
              <th className="px-2 py-3 text-left font-medium text-slate-500">
                <span>First</span>
              </th>
              <th className="px-2 py-3 text-left font-medium text-slate-500">
                <span>Last</span>
              </th>
              <th className="min-w-[9rem] px-2 py-3 text-left font-medium text-slate-500">
                <span>Age Today</span>
              </th>
              <th className="px-2 py-3 text-left font-medium text-slate-500">
                <span>Location</span>
              </th>
              <th className="px-2 py-3 text-left font-medium text-slate-500">
                <span>Gender</span>
              </th>
            </tr>
          )}
        </thead>
        <tbody className="divide-y divide-slate-200">
          {verifiedPepHits.map((pepHit, index) => {
            const pepProfile = pepHit.pep_profile || pepHit;
            const pepReview = pepProfile?.pep_review || pepHit.pep_review || pepHit.profile_review;
            const matchRating = pepReview?.match_rating?.match;
            const ageMatch = pepReview?.age_match;
            const countryMatch = pepReview?.country_match;
            const firstNameMatch = pepReview?.first_name_match;
            const middleNameMatch = pepReview?.middle_name_match;
            const lastNameMatch = pepReview?.last_name_match;
            const isDiscounted = matchRating === "no_match";

            return (
              <>
                <tr
                  onClick={() => handleExpandCollapse(index)}
                  className={twJoin(
                    "cursor-pointer hover:bg-slate-50",
                    isDiscounted ? "bg-slate-50 hover:bg-slate-100" : "",
                  )}
                >
                  <td className="px-2 py-4">
                    <StrengthIndicator matchRating={matchRating} hideLabel={true} />
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <span
                        className={twJoin("truncate max-w-[8rem]", isDiscounted ? "text-slate-500" : "text-slate-700")}
                      >
                        {firstNameMatch.display_name ? (
                          <>
                            {firstNameMatch.display_name
                              .split(new RegExp(`(${firstNameMatch.name}|${middleNameMatch?.name})`, "i"))
                              .map((part: string, index: number) => (
                                <span
                                  key={index}
                                  className={twJoin(
                                    "capitalize",
                                    part?.toLowerCase() === firstNameMatch?.name?.toLowerCase() ||
                                      part?.toLowerCase() === middleNameMatch?.name?.toLowerCase()
                                      ? "font-semibold"
                                      : "",
                                    isDiscounted
                                      ? "text-slate-500"
                                      : firstNameMatch.exact_match || middleNameMatch?.exact_match
                                        ? "text-slate-900"
                                        : firstNameMatch.partial_match ||
                                            middleNameMatch?.partial_match ||
                                            (!firstNameMatch.name && !middleNameMatch?.name)
                                          ? "text-slate-500"
                                          : "text-slate-700",
                                  )}
                                >
                                  {part ? part.toLowerCase() : ""}
                                </span>
                              ))}
                          </>
                        ) : (
                          <span
                            className={twJoin(
                              "truncate capitalize",
                              isDiscounted
                                ? "text-slate-500"
                                : firstNameMatch?.exact_match || middleNameMatch?.exact_match
                                  ? "text-slate-900"
                                  : firstNameMatch?.partial_match || middleNameMatch?.partial_match
                                    ? "text-slate-500"
                                    : "text-slate-700",
                              !firstNameMatch?.name && !middleNameMatch?.name ? "italic" : "",
                              firstNameMatch?.exact_match ? "font-semibold" : "",
                            )}
                          >
                            {firstNameMatch.name ?? pepProfile?.forename}
                          </span>
                        )}
                      </span>
                      <MiniMatchLevelPill
                        matchRating={
                          firstNameMatch.exact_match
                            ? "strong_match"
                            : firstNameMatch.partial_match
                              ? "partial_match"
                              : firstNameMatch.mismatch
                                ? "mismatch"
                                : "First Name Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <span className="truncate max-w-[8rem]">
                        {lastNameMatch.display_name ? (
                          <>
                            {lastNameMatch.display_name
                              .split(new RegExp(`(${lastNameMatch.name}|${middleNameMatch.name})`, "i"))
                              .map((part, index) => (
                                <span
                                  key={index}
                                  className={twJoin(
                                    part?.toLowerCase() === lastNameMatch?.name?.toLowerCase() ? "font-semibold" : "",
                                    isDiscounted || lastNameMatch.partial_match || !lastNameMatch.name
                                      ? "text-slate-500"
                                      : lastNameMatch.exact_match
                                        ? "text-slate-900"
                                        : "text-slate-700",
                                  )}
                                >
                                  {part}
                                </span>
                              ))}
                          </>
                        ) : (
                          <span
                            className={twJoin(
                              "truncate max-w-[8rem]",
                              isDiscounted || lastNameMatch.partial_match || !lastNameMatch.name
                                ? "text-slate-500"
                                : lastNameMatch.exact_match
                                  ? "text-slate-900"
                                  : "text-slate-700",
                              lastNameMatch.exact_match ? "font-semibold" : "",
                            )}
                          >
                            {lastNameMatch.name ? lastNameMatch.name : pepProfile?.surname}
                          </span>
                        )}
                      </span>
                      <MiniMatchLevelPill
                        matchRating={
                          lastNameMatch.exact_match
                            ? "strong_match"
                            : lastNameMatch.partial_match
                              ? "partial_match"
                              : lastNameMatch.mismatch
                                ? "mismatch"
                                : "Last Name Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex items-center gap-x-1">
                        {(ageMatch.min_age || ageMatch.max_age) && (
                          <AutoAwesomeRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                        )}
                        <span
                          className={twJoin(
                            ageMatch.exact_match ? "font-semibold" : "",
                            isDiscounted || ageMatch.partial_match || !ageMatch.age
                              ? "text-slate-500"
                              : ageMatch.exact_match
                                ? "text-slate-900"
                                : "text-slate-700",
                            !ageMatch.age ? "italic" : "",
                          )}
                        >
                          {ageMatch?.age
                            ? `${ageMatch?.age} yrs`
                            : ageMatch?.min_age && ageMatch?.max_age
                              ? `${ageMatch.min_age} - ${ageMatch.max_age}`
                              : ageMatch?.min_age
                                ? `${ageMatch.min_age} yrs or older`
                                : ageMatch?.max_age
                                  ? `${ageMatch.max_age} yrs or younger`
                                  : null}
                        </span>
                      </div>
                      <MiniMatchLevelPill
                        matchRating={
                          ageMatch.exact_match
                            ? "strong_match"
                            : ageMatch.partial_match
                              ? "partial_match"
                              : ageMatch.mismatch
                                ? "mismatch"
                                : "Age Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="truncate w-fit max-w-[10rem]">
                        {countryMatch?.countries?.length > 0
                          ? countryMatch.countries.map((country, index) => {
                              return (
                                <span
                                  className={twJoin(
                                    "text-left",
                                    [
                                      input.country_of_residence?.country_name ??
                                        input.address_country?.country_name ??
                                        input.country_of_nationality?.country_name,
                                    ].includes(country.country_name ? country.country_name : country)
                                      ? "font-semibold"
                                      : "",
                                    isDiscounted
                                      ? "text-slate-500"
                                      : countryMatch.exact_match
                                        ? "text-slate-900"
                                        : countryMatch.partial_match ||
                                            !countryMatch.countries ||
                                            countryMatch.countries?.length === 0
                                          ? "text-slate-500"
                                          : "text-slate-700",
                                    !countryMatch.countries || countryMatch.countries?.length === 0
                                      ? "italic text-slate-500"
                                      : "",
                                  )}
                                >
                                  {index > 0 ? ", " : ""}
                                  {country.country_name ? country.country_name : country}
                                </span>
                              );
                            })
                          : null}
                      </div>
                      <MiniMatchLevelPill
                        matchRating={
                          countryMatch.exact_match
                            ? "strong_match"
                            : countryMatch.partial_match
                              ? "partial_match"
                              : countryMatch.mismatch
                                ? "mismatch"
                                : "Country Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex items-center">
                      <span className={twJoin("capitalize", isDiscounted ? "text-slate-500" : "text-slate-700")}>
                        {pepHit.gender
                          ? pepHit.gender.toLowerCase()
                          : pepHit.pep_profile?.gender
                            ? pepHit.pep_profile.gender.toLowerCase()
                            : ""}
                      </span>
                    </div>
                  </td>
                </tr>
                {expandedIndex === index ? (
                  <tr>
                    <td colSpan={7} className="w-0 min-w-full p-5">
                      <PEPEventCard pepReview={pepReview} pepProfile={pepProfile} />
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const PEPStatusPill = ({ status, matchRating }) => {
  return (
    <div
      className={twJoin(
        "flex items-center px-3 py-0.5 font-medium rounded-full border border-solid text-xs h-6 w-fit capitalize",
        matchRating === "no_match"
          ? "bg-yellow-50 border-[#CA8A04]/20 text-yellow-700"
          : status === "ACTIVE"
            ? "bg-red-50 border-red-100 text-red-700"
            : status === "INACTIVE"
              ? "bg-green-50 border-green-100 text-green-700"
              : "bg-slate-50 border-slate-100 text-slate-700",
      )}
    >
      {matchRating === "no_match" ? "Discounted" : status?.toLowerCase()}
    </div>
  );
};

export default TabularPEP;
