import { ReactNode } from "react";
import SourceTagCircle from "./SourceTagCircle";

type FindingsSectionProps = {
  title: string;
  content: ReactNode;
  sources: any[];
  activeSource: number | null;
  setActiveSource: (source: number | null) => void;
};

const FindingsSection = ({ title, content, sources, activeSource, setActiveSource }: FindingsSectionProps) => {
  return (
    <div className="flex flex-col gap-y-2 text-slate-700">
      <h2 className="text-md font-semibold">{title}</h2>
      <span className="leading-5">
        {content}
        {sources?.length > 0 && (
          <div className="ml-2 inline-flex gap-1">
            {sources
              ?.sort((a, b) => a.id - b.id)
              ?.map((source) => (
                <SourceTagCircle
                  source={source}
                  isActive={source?.source_id === activeSource}
                  setActiveSource={setActiveSource}
                />
              ))}
          </div>
        )}
      </span>
    </div>
  );
};

export default FindingsSection;
