interface InputWithOverlappingLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  fieldName: string;
  inputClassName?: string;
  label: string;
  labelClassName?: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
}

const InputWithOverlappingLabel = ({
  className,
  fieldName,
  inputClassName,
  labelClassName,
  label,
  value,
  onChangeHandler,
  required = false,
  ...rest
}: InputWithOverlappingLabelProps) => {
  return (
    <div className={`relative ${className ? className : ""}`}>
      <label
        htmlFor={fieldName}
        className={`absolute -top-2 left-2 block bg-white px-1.5 text-xs text-slate-900 w-fit max-w-[calc(80%)] truncate ${
          labelClassName ? labelClassName : ""
        }`}
      >
        {label}
        {required && <span className="text-red-500 ml-0.5">*</span>}
      </label>
      <input
        type="text"
        name={fieldName}
        id={fieldName}
        className={`box-border block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6 disabled:text-slate-700 disabled:bg-slate-100 ${
          inputClassName ? inputClassName : ""
        }`}
        onChange={onChangeHandler}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default InputWithOverlappingLabel;
