import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { TrackChangesRounded } from "@mui/icons-material";

interface EvidenceItem {
  found: string;
  evidence: string;
}

interface SmartSkeletonProps {
  evidenceList: EvidenceItem[];
}

const SmartSkeleton: React.FC<SmartSkeletonProps> = ({ evidenceList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === evidenceList.length - 1 && direction === 1) {
          setDirection(-1);
          return prevIndex - 1;
        } else if (prevIndex === 0 && direction === -1) {
          setDirection(1);
          return prevIndex + 1;
        } else {
          return prevIndex + direction;
        }
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [evidenceList.length, direction]);

  return (
    <div className="p-4 rounded-lg h-full overflow-hidden relative">
      <div className="absolute inset-x-0 top-0 h-12 "></div>
      <div className="absolute inset-x-0 bottom-0 h-12"></div>
      <AnimatePresence initial={false}>
        {evidenceList.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: direction > 0 ? "-100%" : "100%" }}
            animate={{
              opacity: index === currentIndex ? 1.0 : 0.25,
              y: `${110 * (index - currentIndex)}%`,
            }}
            exit={{ opacity: 0, y: direction > 0 ? "100%" : "-100%" }}
            transition={{ duration: 0.5 }}
            className="bg-gradient-to-r from-[#E0E7FF] via-[#E0E7FF]/100 to-[#E0E7FF]/0 p-3 rounded-md absolute w-full left-0 border border-slate-100"
            style={{
              height: "fit-content",
              top: "65px",
            }}
          >
            <div className="flex gap-x-2 h-full relative">
              <TrackChangesRounded sx={{ fontSize: "1rem" }} className="text-brand-purple z-20" />
              <div className="text-xs overflow-hidden z-20">
                <p className="text-slate-700 truncate">
                  <strong>Found:</strong> <span className="font-normal">{item.found}</span>
                </p>
                <p className="text-slate-600 mt-0.5 line-clamp-2">
                  <strong>Evidence:</strong>{" "}
                  <i>
                    <span className="font-normal">"{item.evidence}"</span>
                  </i>
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default SmartSkeleton;
