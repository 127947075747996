// @ts-nocheck
import _ from "lodash";
import PEPCardV2 from "./PEPCardV2";

const PEPV2Container = ({ verifiedPepHits, input }) => {
  return (
    <div className="min-w-full flex flex-col gap-y-7 text-xs overflow-hidden">
      <div className="flex flex-col gap-y-5">
        {verifiedPepHits.map((hit, index) => {
          return (
            <PEPCardV2
              key={index}
              input={input}
              pepReview={hit.pep_review ?? hit}
              pepProfile={hit.pep_profile ?? hit}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PEPV2Container;
