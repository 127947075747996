import { CheckCircleOutlineRounded, CloseRounded, VisibilityRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { green, grey, orange, red } from "@mui/material/colors";
import React from "react";

interface Props {
  recommendation: string;
}

const RecommendationBox: React.FC<Props> = ({ recommendation }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        bgcolor:
          recommendation === "Approve"
            ? green[50]
            : recommendation === "Deny"
              ? red[50]
              : recommendation === "Review"
                ? orange[50]
                : "#eee",
        borderRadius: "5px",
        display: "inline-flex",
        minWidth: "fit-content",
        p: ".5rem",
      }}
    >
      {/* {recommendation === "Approve" && <CheckCircleOutlineRounded style={{ color: green[600] }} />}
      {recommendation === "Review" && <VisibilityRounded style={{ color: orange[600] }} />}
      {recommendation === "Deny" && <CloseRounded style={{ color: red[600] }} />} */}
      <Typography
        variant="h6"
        component="div"
        fontWeight="500"
        fontSize="14px"
        color={
          recommendation === "Approve"
            ? "#38A169"
            : recommendation === "Deny"
              ? "#F56565"
              : recommendation === "Review"
                ? "#ED8936"
                : "#333"
        }
      >
        {recommendation}
      </Typography>
    </Box>
  );
};

export default RecommendationBox;
