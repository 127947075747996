// @ts-nocheck
import { LanguageRounded } from "@mui/icons-material";
import Alert from "./Alert";
import linkedInLogo from "../../assets/linked_in_logo.png";
import parchaLogo from "../../assets/social_media_parcha_logo.png";
import xLogo from "../../assets/x-logo.png";
import crunchBaseLogo from "../../assets/crunchbase_icon.png";
import mediumLogo from "../../assets/medium_logo_1x.png";
import LinkedInCompanyCard from "./LinkedInCompanyCard";
import FacebookProfileCard from "./FacebookProfileCard";
import XTwitterProfileCard from "./XTwitterProfileCard";
import InstagramProfileCard from "./InstagramProfileCard";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const WebPresenceList = ({ checkResult, hideHeader = false }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span className="text-lg font-semibold">Web Presence</span>
    </div>
  );

  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <div className="flex flex-col gap-y-4">
        {Header}
        <div className="place-self-center">
          <FindingsLoadingIndicator
            message={`Running Web Presence check${
              checkResult.metadata?.business_name ? ` on ${checkResult.metadata.business_name} ` : ""
            }...`}
          />
        </div>
      </div>
    );
  }

  if (!checkResult?.verified_data) {
    return null;
  }

  let officialBusinessWebsite =
    checkResult.verified_data?.self_attested_webpages.find(
      (website) =>
        website.webpage_metadata?.webpage_type === "official_business_website" ||
        website.extracted_metadata?.webpage_type === "official_business_website",
    ) || checkResult.verified_data?.self_attested_webpages?.[0];

  //retrofit to old format if necessary
  if (officialBusinessWebsite?.analysis) {
    const newOfficialBusinessWebsite = { ...officialBusinessWebsite.scanned_website };
    newOfficialBusinessWebsite.webpage_metadata = officialBusinessWebsite.analysis.extracted_metadata;
    officialBusinessWebsite = newOfficialBusinessWebsite;
  }

  const webPresences = checkResult.verified_data
    ? [...checkResult.verified_data.self_attested_webpages, ...checkResult.verified_data.google_search_results]
    : [];

  // Retrofit new format to old format if necessary
  const retrofittedWebPresences = webPresences.map((webPresence) => {
    if (webPresence.analysis && webPresence.scanned_website) {
      const oldFormatWebPresence = { ...webPresence.scanned_website };
      oldFormatWebPresence.webpage_metadata = webPresence.analysis.extracted_metadata;
      return oldFormatWebPresence;
    }
    return webPresence;
  });

  // Update webPresences with the retrofitted data
  webPresences.splice(0, webPresences.length, ...retrofittedWebPresences);

  const nonSocialMediaWebsites = webPresences.filter(
    (webPresence) => webPresence.webpage_metadata?.webpage_type !== "social_media",
  );

  const socialMediaWebsites = webPresences
    .filter(
      (webPresence) =>
        webPresence.webpage_metadata?.webpage_type === "social_media" && Boolean(webPresence.webpage_structured_scrape),
    )
    .reduce((unique, webPresence) => {
      let normalizedUrl = webPresence.webpage_url.replace(/^(https?:\/\/)?(www\.)?(.*?)\/?$/, "$3").toLowerCase();
      if (normalizedUrl.startsWith("instagram.com/")) {
        normalizedUrl = normalizedUrl.replace(/^(instagram\.com\/[^/?]+).*$/, "$1");
      }
      if (normalizedUrl.includes("linkedin.com")) {
        normalizedUrl = normalizedUrl.replace(
          /^(?:https?:\/\/)?(?:[a-z]{2}\.)?(?:www\.)?linkedin\.com\/(company\/[^/?]+).*$/,
          "linkedin.com/$1",
        );
      }
      if (
        !unique.some((item) => {
          let itemNormalizedUrl = item.webpage_url.replace(/^(https?:\/\/)?(www\.)?(.*?)\/?$/, "$3").toLowerCase();
          if (itemNormalizedUrl.startsWith("instagram.com/")) {
            itemNormalizedUrl = itemNormalizedUrl.replace(/^(instagram\.com\/[^/?]+).*$/, "$1");
          }
          if (itemNormalizedUrl.includes("linkedin.com")) {
            itemNormalizedUrl = itemNormalizedUrl.replace(
              /^(?:https?:\/\/)?(?:[a-z]{2}\.)?(?:www\.)?linkedin\.com\/(company\/[^/?]+).*$/,
              "linkedin.com/$1",
            );
          }
          return itemNormalizedUrl === normalizedUrl;
        })
      ) {
        unique.push(webPresence);
      }
      return unique;
    }, []);

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-4">
      {!hideHeader && Header}
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="h-fit columns-1 md:columns-2 lg:columns-3 gap-4">
        {officialBusinessWebsite && (
          <WebPresenceCard
            title={officialBusinessWebsite.webpage_title}
            url={officialBusinessWebsite.webpage_url}
            imageUrl={officialBusinessWebsite.screenshot_url}
            avatarUrl={officialBusinessWebsite.webpage_structured_scrape?.avatar}
            domain={officialBusinessWebsite.webpage_metadata?.domain}
            isOfficial={true}
          />
        )}

        {nonSocialMediaWebsites.map((presence, index) => {
          const domain = presence.webpage_metadata?.domain
            ? presence.webpage_metadata?.domain.replace(/^(https?:\/\/)?(www\.)?/, "").split("/")[0]
            : new URL(presence.webpage_url).hostname.replace(/^www\./, "");
          if (domain === "linkedin.com") {
            return (
              <LinkedInCompanyCard
                name={presence.webpage_structured_scrape?.name}
                description={presence.webpage_structured_scrape?.description}
                overview={presence.webpage_structured_scrape?.tagline}
                url={presence.webpage_structured_scrape?.websiteUrl}
                industry={presence.webpage_structured_scrape?.industry}
                linkedInUrl={presence.webpage_structured_scrape?.url}
                employeesCount={presence.webpage_structured_scrape?.employeeCount}
                location={
                  presence.webpage_structured_scrape?.headquater
                    ? `${presence.webpage_structured_scrape?.headquater?.city} ${presence.webpage_structured_scrape?.headquater?.country}`
                    : null
                }
                logoUrl={presence.webpage_structured_scrape?.avatar}
              />
            );
          }
          return (
            <WebPresenceCard
              key={index}
              title={presence.webpage_title}
              url={presence.webpage_url}
              imageUrl={presence.screenshot_url}
              domain={presence.webpage_metadata?.domain}
              avatarUrl={presence.webpage_structured_scrape?.avatar}
            />
          );
        })}
      </div>
      <div className="h-fit columns-1 md:columns-2 lg:columns-3 gap-4">
        {socialMediaWebsites.map((presence, index) => {
          const domain = presence.webpage_metadata?.domain
            ? presence.webpage_metadata?.domain.replace(/^(https?:\/\/)?(www\.)?/, "").split("/")[0]
            : new URL(presence.webpage_url).hostname.replace(/^www\./, "");

          switch (domain) {
            case "linkedin.com":
              return (
                <LinkedInCompanyCard
                  name={presence.webpage_structured_scrape?.name}
                  description={presence.webpage_structured_scrape?.description}
                  overview={presence.webpage_structured_scrape?.tagline}
                  url={presence.webpage_structured_scrape?.websiteUrl}
                  industry={presence.webpage_structured_scrape?.industry}
                  linkedInUrl={presence.webpage_structured_scrape?.url}
                  employeesCount={presence.webpage_structured_scrape?.employeeCount}
                  location={
                    presence.webpage_structured_scrape?.headquater
                      ? `${presence.webpage_structured_scrape?.headquater?.city} ${presence.webpage_structured_scrape?.headquater?.country}`
                      : null
                  }
                  logoUrl={presence.webpage_structured_scrape?.avatar}
                />
              );
            case "facebook.com":
              return (
                <FacebookProfileCard
                  aboutMe={presence.webpage_structured_scrape?.about_me?.text}
                  followersCount={presence.webpage_structured_scrape?.followers}
                  facebookUrl={presence.webpage_structured_scrape?.facebookUrl}
                  name={presence.webpage_structured_scrape?.title}
                  industry={presence.webpage_structured_scrape?.categories?.join(", ")}
                />
              );
            case "x.com":
            case "twitter.com":
              return (
                <XTwitterProfileCard
                  description={presence.webpage_structured_scrape?.description}
                  followers={presence.webpage_structured_scrape?.followers}
                  name={presence.webpage_structured_scrape?.name}
                  profilePicture={presence.webpage_structured_scrape?.profilePicture}
                  twitterUrl={presence.webpage_structured_scrape?.twitterUrl}
                  userName={presence.webpage_structured_scrape?.userName}
                />
              );
            case "instagram.com":
              return (
                <InstagramProfileCard
                  biography={presence.webpage_structured_scrape?.biography}
                  businessCategoryName={presence.webpage_structured_scrape?.businessCategoryName}
                  externalUrl={presence.webpage_structured_scrape?.externalUrl}
                  followers={presence.webpage_structured_scrape?.followersCount}
                  instagramUrl={presence.webpage_structured_scrape?.url}
                  logoUrl={presence.webpage_structured_scrape?.profilePicUrlHD}
                  name={presence.webpage_structured_scrape?.fullName}
                  latestPosts={presence.webpage_structured_scrape?.latestPosts}
                />
              );
          }

          return (
            <WebPresenceCard
              key={index}
              title={presence.webpage_title}
              url={presence.webpage_url}
              imageUrl={presence.screenshot_url}
              domain={presence.webpage_metadata?.domain}
              avatarUrl={presence.webpage_structured_scrape?.avatar}
            />
          );
        })}
      </div>
    </div>
  );
};

const iconsDictionary = {
  "linkedin.com": linkedInLogo,
  "x.com": xLogo,
  "crunchbase.com": crunchBaseLogo,
  "medium.com": mediumLogo,
  "parcha.com": parchaLogo,
  "parcha.ai": parchaLogo,
};

const WebPresenceCard = ({
  title,
  url,
  imageUrl,
  domain,
  avatarUrl,
  isOfficial = false,
  summary,
}: {
  title: string;
  url: string;
  avatarUrl: string;
  imageUrl: string;
  domain: string;
  isOfficial?: boolean;
  summary?: string;
}) => {
  return (
    <div className="bg-slate-50 rounded-lg p-3 text-xs h-fit mb-4 break-inside-avoid">
      <div className="flex flex-wrap gap-x-3">
        <div className="flex flex-col gap-y-2 gap-x-3 truncate">
          {(title || domain) && (
            <span className="font-semibold block truncate">{isOfficial ? "Offical Website" : title || domain}</span>
          )}
          {summary && <span className="text-slate-500">{summary}</span>}
          <div>
            <WebsiteValue url={url} avatarUrl={iconsDictionary[domain]} />
          </div>
        </div>
      </div>
    </div>
  );
};

const WebsiteValue = ({ url, avatarUrl }: { url: string; avatarUrl: string | null }) => {
  if (!url) return null;

  let urlToUse = url;
  if (!url.startsWith("http")) {
    urlToUse = `http://${url}`;
  }

  return (
    <div className="group text-brand-purple text-xs">
      <a href={urlToUse} target="_blank" rel="noopener noreferrer" className="inline-flex gap-x-1 items-center w-full">
        {avatarUrl ? (
          <img src={avatarUrl} className="w-4 h-4 rounded-full" />
        ) : (
          <LanguageRounded sx={{ fontSize: "1rem" }} className="text-slate-700" />
        )}
        <span className="align-self-end block truncate">{urlToUse}</span>
      </a>
    </div>
  );
};

export default WebPresenceList;
