import { ReactNode } from "react";
import AddressValue, { AddressValueProps } from "./AddressValue";
import WebsiteCard from "./WebsiteCard";
import WebsiteValue from "./WebsiteValue";
import { twJoin } from "tailwind-merge";

type BusinessDueDiligenceCheckCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  address: AddressValueProps;
  customers?: string[] | undefined | null;
  description: string | undefined | null;
  industry?: string | undefined | null;
  name: string | undefined | null;
  partners?: string[] | undefined | null;
  websiteScreenshotUrl?: string | undefined | null;
  websiteUrl?: string | undefined;
};
const BusinessDueDiligenceCheckCard = ({
  level,
  address,
  customers,
  description,
  industry,
  name,
  partners,
  websiteScreenshotUrl,
  websiteUrl,
}: BusinessDueDiligenceCheckCardProps) => {
  if (
    (!name || name.toLowerCase() === "unverified") &&
    (!description || description.toLowerCase() === "unverified" || description === "") &&
    (!address ||
      Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified" || v === "")) &&
    !websiteScreenshotUrl &&
    !websiteUrl
  ) {
    return null;
  }

  return (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "flex flex-col justify-center p-4 h-17 rounded-t-lg border-b",
          level === "success"
            ? "bg-green-100 text-green-600 border-green-600"
            : level === "failure"
              ? "bg-red-100 text-red-600 border-red-600"
              : "bg-slate-100 text-slate-900 border-slate-300",
        )}
      >
        <div className="font-semibold uppercase text-md">{name}</div>
        {industry && industry.length && <div>{industry}</div>}
      </div>
      <div className="flex flex-col gap-4 px-4 pb-4 pt-5">
        {websiteUrl && (
          <WebsiteCard
            websiteUrl={websiteUrl}
            websiteLabel={name ?? ""}
            screenshotUrl={websiteScreenshotUrl ?? undefined}
          />
        )}
        <div className="grid grid-cols-2">
          {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
            <div className="flex flex-col">
              <Label>Business Address</Label>
              <AddressValue {...address} />
            </div>
          )}
          {websiteUrl && (
            <div className="flex flex-col">
              <Label>Website:</Label>
              <WebsiteValue url={websiteUrl} />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Label>Description:</Label>
          <p className="line-clamp-5 text-sm">{description}</p>
        </div>
        <div className="grid grid-cols-2">
          {partners && (
            <div className="flex flex-col">
              <Label>Business Partners:</Label>
              <p className="line-clamp-2 text-sm">{partners.join(", ")}</p>
            </div>
          )}
          {customers && (
            <div className="flex flex-col">
              <Label>Business Customers:</Label>
              <p className="line-clamp-2 text-sm">{customers.join(", ")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Label = ({ children }: { children: ReactNode }) => {
  return <span className="font-semibold">{children}</span>;
};

export default BusinessDueDiligenceCheckCard;
