import { HourglassEmpty, ReportProblem } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { Typography, colors } from "@mui/material";
import { Box } from "@mui/system";
import BorderLinearProgress from "../BoderLinearProgress";

interface StatusBoxProps {
  progress?: number;
  status: "error" | "complete" | "in progress" | "queued" | "failed";
}

const StatusBox: React.FC<StatusBoxProps> = ({ progress, status }: StatusBoxProps) => {
  let color, Icon;
  switch (status) {
    case "error":
      color = colors.red[600];
      Icon = ErrorIcon;
      break;
    case "complete":
      color = colors.green[600];
      Icon = DoneIcon;
      break;
    case "in progress":
      color = "#5D5FEF";
      Icon = TimelapseIcon;
      break;
    case "queued":
      color = colors.grey[600];
      Icon = HourglassEmpty;
      break;
    case "failed":
      color = colors.red[600];
      Icon = ReportProblem;
      break;
    default:
      color = colors.blue[600];
      Icon = HourglassEmpty;
  }
  return (
    <Box>
      {progress !== undefined && status == "in progress" && (
        <BorderLinearProgress c={color} variant="determinate" value={progress} sx={{ width: "200px" }} />
      )}
      <Box display="flex" alignItems="center" sx={progress !== undefined ? { paddingTop: "8px" } : {}}>
        <Icon sx={{ color: color, fontSize: "1em" }} />
        <Typography variant="body2" color={color} paddingLeft={"4px"}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusBox;
