import { Box, Button, ButtonProps, Grid, Typography, styled } from "@mui/material";
import { CommandResult, ErrorMsg, MessageProps } from "../../../types";
import BaseMessage from "../BaseMessage";
import ErrorIcon from "@mui/icons-material/Error";

function sendEmailURL(errorMsg: ErrorMsg | undefined): void {
  //TODO: with feedbackData coming here, we can add it to the report as well.
  const subject = `Bug report: ${errorMsg?.error_type} (${errorMsg?.error_display})`;
  const body = errorMsg?.full_error_message;
  const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body || "no content in the error body...",
  )}`;
  window.open(mailtoLink, "_blank");
}

const ErrorMessage = ({ messageContents }: MessageProps) => {
  const errorCommandResult = messageContents as CommandResult;
  const errorMsg = errorCommandResult.output as ErrorMsg;

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText("#5D5FEF"),
    backgroundColor: "#5D5FEF",
    "&:hover": {
      backgroundColor: "#4B4FD9",
    },
  }));

  const customComponent = errorMsg && (
    <Grid container display={"inline-grid"} sx={{ paddingLeft: "48px" }}>
      <Grid item>
        <Typography>
          <strong>{errorMsg?.error_type + ": "}</strong>
          {errorMsg?.error_display}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          If there continues to be an issue please contact support and we’ll help get things back on track.
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ padding: "12px 0" }}>
          <ColorButton onClick={() => sendEmailURL(errorMsg)}>Contact Support</ColorButton>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <BaseMessage
      id={`${errorCommandResult.agent_key}-error`}
      icon={<ErrorIcon style={{ color: "#FFFFFF", width: "1.5rem", height: "1.5rem" }} />}
      message={
        "It seems like there was an issue running this job. See details below. Try running the agent again. If the error persists, press 'Contact Support' to file a ticket with our team."
      }
      customComponent={customComponent}
      mode={"error"}
    />
  );
};

export default ErrorMessage;
