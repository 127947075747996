import { redirect, LoaderFunctionArgs } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { agentKey, caseId } = params;
  const parchaApi = useParchaApi();

  if (agentKey && caseId) {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints } = customerAgents;
    const endpoint = endpoints.find((e: { agentKey: string }) => e.agentKey === agentKey);

    if (endpoint) {
      const response = await parchaApi.getJobsByCaseId(endpoint.endpointUrl, caseId, agentKey);

      if (response && response.jobs && response.jobs.length > 0) {
        return redirect(`/jobs/${agentKey}/${response.jobs[0].id.replace(/-/g, "")}`);
      }
    }
  }

  return redirect("/");
};

const CaseRedirect = () => {
  return null;
};

export default CaseRedirect;
