import { twJoin } from "tailwind-merge";
import PersonIcon from "@mui/icons-material/PersonRounded";
import Alert from "./Alert";
import AddressValue, { AddressValueProps } from "./AddressValue";
import { tryToParseDate } from "@/utils";

type GovermentIDCardProps = {
  address?: AddressValueProps | null | undefined;
  level: "success" | "failure" | "normal" | undefined;
  idImageURL: string | null | undefined;
  idType: string;
  countryOfResidence?: string;
  name: string;
  dateOfBirth: string;
  evidenceMessage: string;
};

export default function GovernmentIDCard({
  address,
  level,
  name,
  idImageURL,
  idType,
  countryOfResidence,
  dateOfBirth,
  evidenceMessage,
}: GovermentIDCardProps) {
  if (!name || !name?.trim().length) return null;

  return (
    <div
      className={twJoin(
        "w-full flex flex-col rounded-lg border text-sm text-slate-900 mt-2",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "flex items-center gap-2 rounded-t-md p-4 border-b border-slate-300 text-sm",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-600"
            : level === "failure"
              ? "bg-red-100 border-b-red-600"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <PersonIcon sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} className="text-slate-500" />
        <span className="font-medium">{name}</span>
      </div>
      <div className="flex flex-col gap-2 py-5 px-4 text-xs">
        {idImageURL && idImageURL.trim().length > 0 && (
          <img
            className="object-cover w-full max-w-[400px] rounded-lg mx-auto"
            src={idImageURL}
            alt={`${name}'s identification`}
            onError={({ currentTarget }) => (currentTarget.style.display = "none")}
          />
        )}
        {idType && (
          <div className="flex gap-x-1">
            <div className="font-medium">ID Type:</div>
            <div>{idType}</div>
          </div>
        )}
        {dateOfBirth && (
          <div className="flex flex-wrap gap-x-1">
            <div className="font-medium">Date of Birth:</div>
            <div>{tryToParseDate(dateOfBirth.replaceAll("-", "/"))}</div>
          </div>
        )}
        {countryOfResidence && (
          <div className="flex items-center gap-x-1 flex-wrap">
            <div className="font-medium">Country of Residence:</div>
            <div>{countryOfResidence}</div>
          </div>
        )}
        {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null) && (
          <AddressValue {...address} />
        )}
        {evidenceMessage && level ? (
          <div className="mt-2">
            <Alert level={level} message={evidenceMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
