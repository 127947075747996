import { twJoin } from "tailwind-merge";
import Alert from "./Alert";
import StrengthIndicator from "./StrengthIndicator";
import {
  BusinessCenterRounded,
  PersonSearchRounded,
  LocationOnRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  CalendarTodayRounded,
  AutoAwesomeRounded,
} from "@mui/icons-material";
import { useState } from "react";
import { MatchPill } from "./AdverseMediaV2";
import PEPEventCard from "./PEPEventCard";

const PEPCardV2 = ({
  input,
  pepReview,
  pepProfile,
}: {
  input: {
    first_name: string;
    last_name: string;
    middle_name: string | null;
    country_of_residence?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    country_of_nationality?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    address_country?: {
      original_country_input: string;
      country_name: string;
      alpha_2_country_code: string;
      alpha_3_country_code: string;
      numeric_country_code: string;
    };
    date_of_birth: string;
    individual_age: number;
    today_date: string;
  };
  pepProfile: {
    full_name: string;
    forename: string;
    middle_name: string | null;
    surname: string;
    gender: string | null;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string
    )[];
    associated_addesses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    roles: {
      start_date: string;
      end_date: string;
      pep_type: string;
      title: string;
      status: string;
    }[];
    reference_id: string;
    aliases: string[] | null;
    identification_info: string;
    biography_info: string;
    known_associates: {
      association_type: string;
      name: string;
      entity_type: "ORGANISATION" | "INDIVIDUAL";
      category: string;
    }[];
    pep_review: {
      pep_level: {
        level: number | null;
        reason: string | null;
      } | null;
      is_foreign: boolean;
      match_rating: {
        match: string;
        reason: string;
      } | null;
      country_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        countries: (string | { country_name: string })[];
        explanation: string;
      };
      first_name_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        name: string;
        explanation: string;
        display_name: string;
      };
      middle_name_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        name: string;
        explanation: string;
        display_name: string;
      };
      last_name_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        name: string;
        explanation: string;
        display_name: string;
      };
      age_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        age: string | number;
        explanation: string;
        min_age?: number | null;
        max_age?: number | null;
      };
      full_name: string;
      risk_rating: string;
      weblinks?:
        | {
            url: string | null;
            date: string | null;
            has_photo: boolean | null;
            scanned_website: {
              webpage_text: string | null;
              webpage_title: string | null;
              is_valid_url: boolean | null;
            };
            pep_metadata?: {
              id: string;
              matching_name_found?: boolean | null;
              matching_name?: string | null;
              summary_of_the_page?: string | null;
              source_url?: string | null;
            };
          }[]
        | null;
    } | null;
  };
  pepReview?: {
    pep_level: {
      level: number | null;
      reason: string | null;
    } | null;
    is_foreign: boolean;
    match_rating: {
      match: string;
      reason: string;
    } | null;
    country_match: {
      exact_match: boolean;
      partial_match: boolean;
      countries: (string | { country_name: string })[];
      explanation: string;
      mismatch?: boolean;
    };
    first_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      name: string;
      explanation: string;
      mismatch?: boolean;
      display_name?: string;
    };
    middle_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      name: string;
      explanation: string;
      display_name?: string;
    };
    last_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      name: string;
      explanation: string;
      mismatch?: boolean;
      display_name?: string;
    };
    age_match: {
      exact_match: boolean;
      partial_match: boolean;
      age: string | number;
      explanation: string;
      min_age?: number | null;
      max_age?: number | null;
      mismatch?: boolean;
    };
    full_name: string;
    risk_rating: string;
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_the_page?: string | null;
            source_url?: string | null;
          };
        }[]
      | null;
  } | null;
}) => {
  const [showMatchInfo, setShowMatchInfo] = useState(true);

  const profilePepReview = pepProfile?.pep_review;
  const matchRating = pepReview?.match_rating?.match || profilePepReview?.match_rating?.match;
  const matchReason = pepReview?.match_rating?.reason || profilePepReview?.match_rating?.reason;
  const ageMatch = pepReview?.age_match || profilePepReview?.age_match;
  const countryMatch = pepReview?.country_match || profilePepReview?.country_match;
  const firstNameMatch = pepReview?.first_name_match || profilePepReview?.first_name_match;
  const lastNameMatch = pepReview?.last_name_match || profilePepReview?.last_name_match;
  const middleNameMatch = pepReview?.middle_name_match || profilePepReview?.middle_name_match;
  const perpetratorFullName = pepReview?.full_name || profilePepReview?.full_name;

  return (
    <div
      className={twJoin("flex flex-col gap-y-2 border rounded-lg border-slate-200 text-xs overflow-hidden flex-wrap")}
    >
      <div className={twJoin("p-4 flex flex-col gap-y-4", showMatchInfo ? "" : "border-b border-slate-300")}>
        <div className="relative flex flex-wrap gap-y-2 gap-x-2 items-center justify-between">
          <div className="w-full flex gap-4 items-center justify-between">
            <div className="flex items-center gap-x-1">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span className="text-sm font-semibold">{pepProfile?.full_name}</span>
            </div>
            {matchRating && (
              <div
                className="w-fit max-w-full flex items-center gap-4"
                role="button"
                onClick={() => setShowMatchInfo(!showMatchInfo)}
              >
                <StrengthIndicator matchRating={matchRating} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-y-4">
        <button
          className={twJoin(
            "z-30 absolute left-[calc(50%-1rem)] rounded-full ring-1 ring-slate-300 h-5 w-5 bg-white",
            showMatchInfo ? "-bottom-2.5" : "-top-4",
          )}
          onClick={() => setShowMatchInfo(!showMatchInfo)}
        >
          {showMatchInfo ? (
            <ExpandLessRounded sx={{ fontSize: "1.25rem" }} />
          ) : (
            <ExpandMoreRounded sx={{ fontSize: "1.25rem" }} />
          )}
        </button>

        {showMatchInfo && (
          <div className={twJoin("flex flex-col gap-y-4 px-2 pb-2", showMatchInfo ? "border-b border-slate-300" : "")}>
            <Alert
              level={
                matchRating === "strong_match" ? "failure" : matchRating === "partial_match" ? "partial" : "caution"
              }
              message={matchReason}
            />
            <div className="w-full overflow-x-auto">
              <table className="min-w-full table-auto leading-normal overflow-auto">
                <thead>
                  <tr className="text-left">
                    <th className="px-1.5"></th>
                    <th className="px-1.5">Found Perpetrator</th>
                    <th className="px-1.5 text-slate-500">Customer Information</th>
                    <th className="px-1.5"></th>
                  </tr>
                </thead>
                <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-300">
                  <tr className="text-left">
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <PersonSearchRounded sx={{ fontSize: "1rem" }} />
                        <span>First Name</span>
                      </div>
                    </td>
                    <td className="truncate px-1.5 py-2">
                      {firstNameMatch?.display_name ? (
                        <>
                          {firstNameMatch?.display_name
                            .split(new RegExp(`(${firstNameMatch?.name}|${middleNameMatch?.name})`, "i"))
                            .map((part: string, index: number) => (
                              <span
                                key={index}
                                className={twJoin(
                                  "capitalize",
                                  part?.toLowerCase() === firstNameMatch?.name?.toLowerCase() ||
                                    part?.toLowerCase() === middleNameMatch?.name?.toLowerCase()
                                    ? "font-semibold"
                                    : "",
                                  matchRating === "no_match" ? "opacity-50" : "",
                                  firstNameMatch?.exact_match || middleNameMatch?.exact_match ? "text-slate-900" : "",
                                  firstNameMatch?.partial_match || middleNameMatch?.partial_match
                                    ? "text-slate-500"
                                    : "",
                                  !firstNameMatch?.name && !middleNameMatch?.name ? "text-slate-500" : "",
                                  "text-slate-700",
                                )}
                              >
                                {part?.toLowerCase()}
                              </span>
                            ))}
                        </>
                      ) : (
                        <span
                          className={twJoin(
                            "capitalize",
                            firstNameMatch?.exact_match || middleNameMatch?.exact_match ? "font-semibold" : "",
                            matchRating === "no_match" ? "opacity-50" : "",
                            firstNameMatch?.exact_match || middleNameMatch?.exact_match ? "text-slate-900" : "",
                            firstNameMatch?.partial_match || middleNameMatch?.partial_match ? "text-slate-500" : "",
                            !firstNameMatch?.name && !middleNameMatch?.name ? "text-slate-500" : "",
                            "text-slate-700",
                          )}
                        >
                          {firstNameMatch?.name ?? pepProfile?.forename}
                        </span>
                      )}
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400"> vs. {input.first_name}</span>
                    </td>
                    <td className="px-1.5 py-2 text-right">
                      <div className="inline-flex">
                        <>
                          {firstNameMatch?.exact_match ? (
                            <MatchPill matchRating="strong_match" />
                          ) : firstNameMatch?.partial_match ? (
                            <MatchPill matchRating="partial_match" />
                          ) : firstNameMatch?.mismatch ? (
                            <MatchPill matchRating="mismatch" />
                          ) : (
                            <MatchPill matchRating="no_match" />
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                  <tr className="text-left">
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <PersonSearchRounded sx={{ fontSize: "1rem" }} />
                        <span>Last Name</span>
                      </div>
                    </td>
                    <td className="truncate px-1.5 py-2">
                      {lastNameMatch?.display_name ? (
                        <>
                          {lastNameMatch?.display_name
                            .split(new RegExp(`(${lastNameMatch?.name}|${middleNameMatch?.name})`, "i"))
                            .map((part, index) => (
                              <span
                                key={index}
                                className={twJoin(
                                  "capitalize",
                                  part?.toLowerCase() === lastNameMatch.name?.toLowerCase() ? "font-semibold" : "",
                                  matchRating === "no_match" ? "opacity-50" : "",
                                  lastNameMatch.exact_match ? "text-slate-900" : "",
                                  lastNameMatch.partial_match ? "text-slate-500" : "",
                                  !lastNameMatch.name ? "text-slate-500" : "",
                                  "text-slate-700",
                                )}
                              >
                                {part?.toLowerCase()}
                              </span>
                            ))}
                        </>
                      ) : (
                        <span
                          className={twJoin(
                            "capitalize",
                            matchRating === "no_match" ? "opacity-50" : "",
                            lastNameMatch?.exact_match ? "font-semibold" : "",
                            lastNameMatch?.exact_match ? "text-slate-900 ml-3" : "",
                            lastNameMatch?.partial_match ? "text-slate-500" : "",
                            !lastNameMatch?.name ? "text-slate-500" : "",
                            "text-slate-700",
                          )}
                        >
                          {lastNameMatch?.name
                            ? lastNameMatch?.name
                                .split(" ")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(" ")
                            : lastNameMatch?.name ?? pepProfile?.surname}
                        </span>
                      )}
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400"> vs. {input.last_name}</span>
                    </td>
                    <td className=" px-1.5 py-2 text-right">
                      <div className="inline-flex">
                        <>
                          {lastNameMatch?.exact_match ? (
                            <MatchPill matchRating="strong_match" />
                          ) : lastNameMatch?.partial_match ? (
                            <MatchPill matchRating="partial_match" />
                          ) : lastNameMatch?.mismatch ? (
                            <MatchPill matchRating="mismatch" />
                          ) : (
                            <MatchPill matchRating="no_match" />
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <CalendarTodayRounded sx={{ fontSize: "1rem" }} />
                        <span>Age Today</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "truncate px-1.5 py-2",
                        ageMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : ageMatch?.partial_match
                            ? "text-orange-600"
                            : ageMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      <div className="flex items-center gap-x-1">
                        {(ageMatch?.min_age || ageMatch?.max_age) && (
                          <AutoAwesomeRounded sx={{ fontSize: "0.75rem" }} />
                        )}
                        {ageMatch?.age
                          ? `${ageMatch?.age} yrs old`
                          : ageMatch?.min_age && ageMatch?.max_age
                            ? `${ageMatch.min_age} - ${ageMatch.max_age}`
                            : ageMatch?.min_age
                              ? `${ageMatch.min_age} yrs or older`
                              : ageMatch?.max_age
                                ? `${ageMatch.max_age} yrs or younger`
                                : "Not Found"}
                      </div>
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400">
                        {" "}
                        vs. {input.individual_age ? `${input.individual_age} years old` : "Not Available"}
                      </span>
                    </td>
                    <td className="px-1.5 py-2 text-right">
                      <div className="inline-flex">
                        <MatchPill
                          matchRating={
                            ageMatch?.exact_match
                              ? "strong_match"
                              : ageMatch?.partial_match
                                ? "partial_match"
                                : ageMatch?.mismatch
                                  ? "mismatch"
                                  : "no_match"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-1.5 py-2">
                      <div className="flex gap-x-2 items-center">
                        <LocationOnRounded sx={{ fontSize: "1rem" }} />
                        <span>Location</span>
                      </div>
                    </td>
                    <td
                      className={twJoin(
                        "text-wrap px-1.5 py-2",
                        countryMatch?.exact_match
                          ? "text-red-700 font-semibold"
                          : countryMatch?.partial_match
                            ? "text-orange-600 font-semibold"
                            : countryMatch?.mismatch
                              ? "text-yellow-800"
                              : "text-slate-600",
                      )}
                    >
                      {countryMatch && countryMatch.countries?.length > 0
                        ? countryMatch.countries
                            .map((country) =>
                              country !== null && typeof country === "object" ? country.country_name : country,
                            )
                            .join(", ")
                        : "Not Found"}
                    </td>
                    <td className="text-slate-400 truncate px-1.5 py-2">
                      <span className="text-slate-400">
                        {" "}
                        vs.{" "}
                        {(typeof input.country_of_residence === "object" && input.country_of_residence?.country_name) ??
                          (typeof input.address_country === "object" && input.address_country?.country_name) ??
                          (typeof input.country_of_nationality === "object" &&
                            input.country_of_nationality?.country_name) ??
                          input.country_of_nationality ??
                          "Unknown"}
                      </span>
                    </td>
                    <td className="px-1.5 py-2 text-right ">
                      <div className="inline-flex">
                        <MatchPill
                          matchRating={
                            countryMatch?.exact_match
                              ? "strong_match"
                              : countryMatch?.partial_match
                                ? "partial_match"
                                : countryMatch?.mismatch
                                  ? "mismatch"
                                  : "no_match"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="px-5 pt-2 pb-5">
        <PEPEventCard pepReview={pepReview || profilePepReview} pepProfile={pepProfile} />
      </div>
    </div>
  );
};

export const PositionEntry = ({
  position,
  startDate,
  endDate,
  duration,
}: {
  position: string;
  startDate: string | null;
  endDate: string | null;
  duration: string | null;
}) => {
  return (
    <div className="flex gap-x-2">
      <BusinessCenterRounded sx={{ fontSize: "1rem" }} className="text-slate-700" />
      <div className={twJoin("flex flex-col gap-y-1")}>
        <span className="text-slate-700">{position}</span>
        <span className="text-xs text-slate-500">
          {startDate}
          {startDate && endDate ? "-" : null}
          {endDate} {duration ? "•" : null} {duration?.includes("second") ? "about 1 year" : duration}
        </span>
      </div>
    </div>
  );
};

export default PEPCardV2;
