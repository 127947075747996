import { useContext, useEffect } from "react";
import useAuth from "@parcha/hooks/useAuth";
import { usePostHog } from "posthog-js/react";
import { Navigate } from "react-router-dom";
import { useKoala } from "@getkoala/react";
import UserContext from "@/contexts/UserContext";

export default function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const posthog = usePostHog();
  const { isLoading, isAuthenticated, user } = useAuth();
  const { koala, ready } = useKoala();
  const userContext = useContext(UserContext);
  const login = userContext?.login;

  useEffect(() => {
    if (user && isAuthenticated && ready) {
      const tenant = user.userTenants && user.userTenants.length > 0 ? user.userTenants[0] : null;
      koala.identify({
        email: user.email,
        name: user.name,
        $account: { name: tenant?.tenantName ?? undefined },
      });
    }
  }, [isAuthenticated, ready, user]);

  useEffect(() => {
    if (user && isAuthenticated) {
      posthog?.identify(user.userId, {
        email: user.email,
      });
    }
  }, [user, isAuthenticated, posthog]);

  useEffect(() => {
    if (isAuthenticated && user) {
      login?.(user);
    }
  }, [login]);

  if (isLoading) return null;
  if (!isAuthenticated || !user) {
    const url = new URL(window.location.href);

    if (url.pathname === "/" || url.pathname.includes("/reports")) {
      return <Navigate to="/create-report" />;
    }

    return <Navigate to={`/signin?redirectTo=${window.location.href}`} />;
  }

  return <>{children}</>;
}
